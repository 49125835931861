// @flow

import {select, call, takeEvery, put, takeLatest} from 'redux-saga/effects'

import translations from 'translations'

import {changeLocale, setLocale} from './actions'
import {CHANGE_LOCALE, SET_LOCALE} from './constants'
import {getLocalesList} from './selectors'

const LS_LOCALE_KEY = 'language'

function* setLocaleHandler({onSetLocale}: Inject, {payload: {locale}}) {
  yield call(storeLocale, locale)
  yield call(onSetLocale, locale)
}

function* changeLocaleHandler({payload: {locale}}) {
  const content = yield call(translations[locale.code].getContent)

  yield put(setLocale({content, locale}))
}

function* restoreLocale() {
  const str = yield call([localStorage, localStorage.getItem], LS_LOCALE_KEY)

  try {
    const locale = str && JSON.parse(str)

    if (locale) {
      yield put(changeLocale(locale))
    } else {
      const locales = yield select(getLocalesList)
      const suitableLocale = locales.find(({code}) => navigator.language.includes(code))

      if (suitableLocale) yield put(changeLocale(suitableLocale))
    }
  } catch {
    // nothing to do
  }
}

function* storeLocale(locale) {
  yield call([localStorage, localStorage.setItem], LS_LOCALE_KEY, JSON.stringify(locale))
}

type Inject = {
  onSetLocale: Function,
}

export default (inj: Inject) =>
  function*(): any {
    yield takeEvery(SET_LOCALE, setLocaleHandler, inj)

    yield takeLatest(CHANGE_LOCALE, changeLocaleHandler)

    yield call(restoreLocale)
  }
