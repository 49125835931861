// @flow

import type {Moment} from 'moment'
import moment from 'moment'

export const dateTimeUtcToLocal = (date: string, time: string): Moment =>
  moment.utc(`${date} ${time}`, 'DD-MM-YYYY HH:mm').local()

export const adaptPredict = (predict: any) => ({
  ...predict,
  predictDate: dateTimeUtcToLocal(predict.predictDate, predict.predictTime),
  predictTime: dateTimeUtcToLocal(predict.predictDate, predict.predictTime),
  matchDate: dateTimeUtcToLocal(predict.matchDate, predict.matchTime),
  matchTime: dateTimeUtcToLocal(predict.matchDate, predict.matchTime),
})
