export const START_LOADING = 'battle-py-frontend/core/events/START_LOADING'
export const FINISH_LOADING = 'battle-py-frontend/core/events/FINISH_LOADING'
export const ADD_DATA = 'battle-py-frontend/core/events/ADD_DATA'
export const SET_CHAMPIONSHIPS = 'battle-py-frontend/core/events/SET_CHAMPIONSHIPS'
export const SET_MATCHES = 'battle-py-frontend/core/events/SET_MATCHES'
export const SET_KOEFS = 'battle-py-frontent/core/events/SET_KOEFS'
export const SET_MY_PREDICTS = 'battle-py-frontend/core/events/SET_MY_PREDICTS'
export const SET_MY_PREDICTS_FOR_MATCH = 'battle-py-frontend/core/events/SET_MY_PREDICTS_FOR_MATCH'
export const PUT_MOCK_PREDICT = 'battle-py-frontend/core/events/PUT_MOCK_PREDICT'
export const REMOVE_PREDICT = 'battle-py-frontend/core/events/REMOVE_PREDICT'
export const REMOVE_PREDICTS = 'battle-py-frontend/core/events/REMOVE_PREDICTS'
export const LOAD_UPCOMING = 'battle-py-frontend/core/events/LOAD_UPCOMING'
export const LOAD_ACTUAL = 'battle-py-frontend/core/events/LOAD_ACTUAL'
export const LOAD_TEAM_MEMBER = 'battle-py-frontend/core/events/LOAD_TEAM_MEMBER'
export const TOGGLE_BET = 'battle-py-frontend/core/events/TOGGLE_BET'
export const PLACE_BET_ERROR = 'battle-py-frontend/core/events/PLACE_BET_ERROR'
export const RESET = 'battle-py-frontend/core/events/RESET'
export const SET_COUNT = 'battle-py-frontend/core/events/SET_COUNT'
export const LOAD_USER_DATA = 'battle-py-frontend/core/events/LOAD_USER_DATA'
