// @flow

import keys from 'lodash/keys'

import type {MockPredict} from 'store/lib/events'

import type {MyPredictsState, State} from './types'
import {
  FINISH_LOADING,
  START_LOADING,
  SET_CHAMPIONSHIPS,
  SET_MATCHES,
  SET_MY_PREDICTS,
  SET_MY_PREDICTS_FOR_MATCH,
  PUT_MOCK_PREDICT,
  REMOVE_PREDICT,
  REMOVE_PREDICTS,
  SET_KOEFS,
  ADD_DATA,
  RESET,
  SET_COUNT,
} from './constants'

const initialState: State = {
  matches: {
    pages: [],
    paths: {},
  },
  myPredicts: {},
  koefsTypes: {},
  koefs: {},
  results: {},
  loading: false,
  count: 0,
}

const myPredicts = (state: MyPredictsState, {payload, type}: any) => {
  switch (type) {
    case SET_MY_PREDICTS:
      return payload

    case SET_MY_PREDICTS_FOR_MATCH: {
      const {matchId, predicts} = payload

      return {...state, [matchId]: predicts}
    }

    case PUT_MOCK_PREDICT: {
      const {matchId, type, koefId} = payload
      const mock: MockPredict = {koefs: koefId}

      return {...state, [matchId]: {...state[matchId], [type]: mock}}
    }

    case REMOVE_PREDICT: {
      const {matchId, type} = payload
      const {[matchId]: match} = state
      // $FlowFixMe
      const {[type]: omit, ...nextMatch} = match

      return {...state, [matchId]: nextMatch}
    }

    case REMOVE_PREDICTS: {
      const {matchId, types} = payload
      const next = types.reduce((next, type) => {
        const {[type]: omit, ...rest} = next

        return rest
      }, state[matchId])

      return {...state, [matchId]: next}
    }

    default:
      return state
  }
}

export default (state: State = initialState, action: any) => {
  const {payload, type} = action

  switch (type) {
    case START_LOADING:
      return {...state, loading: true}

    case FINISH_LOADING:
      return {...state, loading: false}

    case SET_CHAMPIONSHIPS:
      return {...state, championships: payload}

    case SET_MATCHES:
      return {...state, matches: payload}

    case SET_KOEFS:
      return {...state, koefs: payload}

    case ADD_DATA: {
      const {matches, ...otherStuff} = payload

      return {
        ...keys(otherStuff).reduce((acc, key) => ({...acc, [key]: {...state[key], ...otherStuff[key]}}), {}),
        matches: {pages: [...state.matches.pages, ...matches.pages], paths: {...state.matches.paths, ...matches.paths}},
      }
    }

    case SET_COUNT:
      return {...state, count: payload}

    case RESET:
      return initialState

    default: {
      const myPredictsNext = myPredicts(state.myPredicts, action)

      if (myPredictsNext !== state.myPredicts) return {...state, myPredicts: myPredictsNext}

      return state
    }
  }
}
