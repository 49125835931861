// @flow

import {ACTION_META_MODULE, createConstants, createReducer, createActions, createSelectors} from 'store/lib/pagination'

import type {State} from './types'
import {RAISE_MODULE, SHUTDOWN_MODULE} from './constants'

const initialState: State = {}

export const constants = createConstants('battle-py-frontend/pagination')
export const actions = createActions(constants)
export const selectors = createSelectors('pagination')
const reducer = createReducer(constants)

export default (state: State = initialState, action: any) => {
  const {meta, type, payload} = action

  switch (type) {
    case SHUTDOWN_MODULE: {
      const {module} = payload
      const {[module]: omit, ...next} = state

      return next
    }

    case RAISE_MODULE: {
      const {module} = payload

      return {...state, [module]: reducer(void 0, action)}
    }

    default: {
      const module = meta && meta[ACTION_META_MODULE]

      if (module) {
        const currentModuleState = state[module]
        const nextModuleState = reducer(currentModuleState, action)

        if (currentModuleState !== nextModuleState) return {...state, [module]: nextModuleState}
      }

      return state
    }
  }
}
