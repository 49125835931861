// @flow

import api from 'api'

import {START_FETCH_USER, FINISH_FETCH_USER} from './constants'
import type {User} from './types'
import {collection} from './index'

function startFetchUser(userId: number) {
  return {type: START_FETCH_USER, payload: {userId}}
}

function finishFetchUser(userId: number, data?: User) {
  return {type: FINISH_FETCH_USER, payload: {userId, data}}
}

export function fetchUser(userId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchUser(userId))

    const {data, status} = await api.get(`/admin/users/${userId}/`)

    if (status === 200) {
      dispatch(finishFetchUser(userId, data))
    } else {
      dispatch(finishFetchUser(userId))

      return data
    }
  }
}

export function editUser(userId: number, values: Object) {
  return async (dispatch: Function) => {
    if(values.userOlimpLogin === null || values.userOlimpLogin.length === 0){
      values.userOlimpLogin = null
    }

    const {data, status} = await api.put(`/admin/users/${userId}/`, values)

    if (status === 200) {
      dispatch(fetchUsers())
    } else {
      return data
    }
  }
}

export function fetchUsers(params?: {team?: number} = {}) {
  return collection.actions.load(params)
}
