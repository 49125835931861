// @flow

import {combineReducers} from 'redux'

import {START_FETCH_PLAYOFFS, FINISH_FETCH_PLAYOFFS} from './constants'
import type {State, CollectionState} from './types'

const initialState: State = {
  collection: {
    data: [],
    loading: false,
  },
}

const collection = (state: CollectionState = initialState.collection, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_PLAYOFFS: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_PLAYOFFS: {
      return {...state, data: payload, loading: false}
    }

    default:
      return state
  }
}

export default combineReducers({collection})
