// @flow

import {call} from 'redux-saga/effects'

const handlerEnhancer = (handler: Function, ...args: Array<*>) =>
  function*(action: any): any {
    let errors

    try {
      errors = yield call(handler, ...args, action)
    } finally {
      const {meta} = action

      if (meta && meta.resolve && meta.reject) {
        errors ? meta.reject(errors) : meta.resolve()
      }
    }
  }

export default handlerEnhancer
