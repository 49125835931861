// @flow

import {takeEvery, select} from 'redux-saga/effects'
import {putLoading} from 'store/router/utils'
import {SET_ACTIVE_STAGE} from 'store/admin/stages/constants'
import {getChamp} from 'store/admin/ui/selectors'
import {fetchGroupReport} from './actions'

function* setStageHandler({payload}) {
  const currentChamp = yield select(getChamp)
  yield putLoading(fetchGroupReport({championship: currentChamp, stage: payload}))
}

export default () =>
  function*() {
    yield takeEvery(SET_ACTIVE_STAGE, setStageHandler)
  }
