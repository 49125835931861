// @flow

import api from 'api'
import moment from 'moment'
import type {Moment} from 'moment'

import {START_FETCH_MATCHES, FINISH_FETCH_MATCHES, START_FETCH_MATCH, FINISH_FETCH_MATCH} from './constants'
import type {Match} from './types'

const utcToLocal = (value: string): Moment => moment.utc(value).local()
const localToUtc = (date: Moment): string =>
  moment(date)
    .utc()
    .format('YYYY-MM-DD HH:mm')

export function startFetchMatches() {
  return {type: START_FETCH_MATCHES}
}

export function finishFetchMatches(payload?: Array<Match>) {
  return {type: FINISH_FETCH_MATCHES, payload}
}

let lastFetchMatchesParams = void 0
export function fetchMatches(params?: {championship: number, state: number, status: 0 | 1 | 2}) {
  return async (dispatch: Function) => {
    lastFetchMatchesParams = params

    dispatch(startFetchMatches())

    const {data, status} = await api.get('/admin/matches/', {params})

    if (status === 200) {
      const adaptedMatches = data.map(match => ({...match, date: utcToLocal(match.date)}))

      dispatch(finishFetchMatches(adaptedMatches))
    } else {
      dispatch(finishFetchMatches())

      return data
    }
  }
}

export function startFetchMatch(matchId: number) {
  return {type: START_FETCH_MATCH, payload: {matchId}}
}

export function finishFetchMatch(matchId: number, match?: Match) {
  return {type: FINISH_FETCH_MATCH, payload: {matchId, match}}
}

export function fetchMatch(matchId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchMatch(matchId))

    const {data, status} = await api.get(`/admin/matches/${matchId}/`)

    if (status === 200) {
      dispatch(finishFetchMatch(matchId, {...data, date: utcToLocal(data.date)}))
    } else {
      dispatch(finishFetchMatch(matchId))

      return data
    }
  }
}

export function deleteMatch(matchId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/matches/${matchId}/`)

    if (status === 204) {
      dispatch(fetchMatches(lastFetchMatchesParams))
    } else {
      return data
    }
  }
}

export function reopenMatch(matchId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.get(`/admin/reopen/match/${matchId}/`)

    if (status === 200) {
      dispatch(fetchMatches(lastFetchMatchesParams))
    } else {
      return data
    }
  }
}

export function closeMatch(matchId: number, values: Object) {
  const {goalsFrom75To90, ...other} = values

  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/matches/close/${matchId}/`, {
      ...other,
      goals_from_75_to_90: goalsFrom75To90,
    })

    if (status === 200) {
      dispatch(fetchMatches(lastFetchMatchesParams))
    } else {
      return data
    }
  }
}

export function editMatch(matchId: number, values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/matches/${matchId}/`, {...values, date: localToUtc(values.date)})

    if (status === 200) {
      dispatch(fetchMatches(lastFetchMatchesParams))
    } else {
      return data
    }
  }
}

export function createMatch(values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/matches/', {...values, date: localToUtc(values.date)})

    if (status === 201) {
      dispatch(fetchMatches(lastFetchMatchesParams))
    } else {
      return data
    }
  }
}
