// @flow

import translations from 'translations'
import en from 'translations/en'

import {SET_LOCALE, CHANGE_LOCALE} from './constants'
import hoc from './hoc'
import type {State} from './types'

const {code, name} = translations.en
const initialState: State = {
  current: {code, name},
  content: en,
  loading: false,
}

export const withLocale = hoc

export default (state: State = initialState, {payload, type}: any) => {
  switch (type) {
    case CHANGE_LOCALE:
      return {...state, loading: true}

    case SET_LOCALE: {
      const {locale, content} = payload

      return {...state, content, current: locale, loading: false}
    }

    default:
      return state
  }
}
