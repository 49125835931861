import {takeEvery, call, put} from 'redux-saga/effects'

import {SET_CHAMP} from './constants'
import {setChamp} from './actions'

const CURRENT_CHAMP_LS_KEY = 'admin/ui/currentChamp'

const getCurrentChamp = () => localStorage.getItem(CURRENT_CHAMP_LS_KEY)
const saveCurrentChamp = champ =>
  champ ? localStorage.setItem(CURRENT_CHAMP_LS_KEY, champ) : localStorage.removeItem(CURRENT_CHAMP_LS_KEY)

function* setChampHandler({payload}) {
  yield call(saveCurrentChamp, payload)
}

export default () =>
  function*() {
    const champ = yield call(getCurrentChamp)

    yield put(setChamp(champ))

    yield takeEvery(SET_CHAMP, setChampHandler)
  }
