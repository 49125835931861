// @flow

import {createSelector} from 'reselect'

import type {
  State,
  TournamentState,
  TournamentData,
  TeamsState,
  TeamsData,
  Team,
  TeamsById,
  TeamDetails,
  TeamModerators,
  TeamStagesData,
  TeamStagesState,
  TeamStage,
  TeamStagesById,
  StageMatch,
  TeamModerator,
  UsersState,
  UsersData,
  TeamUser,
  StageMatchesState,
  StageMatchesData,
  UserState,
} from './types'

const local = (state: any): State => state.core.teams

const getTournament = (state: any): TournamentState => local(state).tournament

const getTournamentData = (state: any): TournamentData | null => getTournament(state).data

const getTeams = (state: any): TeamsState => local(state).teams

const getTeamsData = (state: any): TeamsData => getTeams(state).data

const getTeamsIds = (state: any): Array<number> => getTeamsData(state).ids

const getTeamsById = (state: any) => getTeamsData(state).byId

const getTeamStages = (state: any): TeamStagesState => local(state).teamStages

const getTeamStagesData = (state: any): TeamStagesData => getTeamStages(state).data

const getUsersState = (state: any): UsersState => local(state).users

const getUsersCount = (state: any): number => getUsersState(state).count

const getUser = (state: any): UserState => local(state).user

export const getUsersData = (state: any): UsersData => getUsersState(state).data

export const getUsersList = createSelector(
  getUsersData,
  (usersData: UsersData): Array<TeamUser> => usersData.pages.reduce((acc, users) => acc.concat(users), []),
)

export const isUsersLoading = (state: any): boolean => getUsersState(state).loading

export const hasMoreUsers = createSelector(
  getUsersData,
  getUsersCount,
  (usersData: UsersData, count: number): boolean =>
    count > usersData.pages.reduce((sum, users) => sum + users.length, 0),
)

export const hasUserInUsers = (state: any, params: {userId: number}): boolean =>
  !!getUsersData(state).paths[params.userId]

const getStageMatches = (state: any): StageMatchesState => local(state).stageMatches

const getStageMatchesCount = (state: any): number => getStageMatches(state).count

export const getTeamDetails = (state: any): TeamDetails | null => local(state).teamDetails.data

export const getTeamDetailsId = (state: any): number | null => {
  const details = getTeamDetails(state)

  return details ? details.id : null
}

export const getTeamDetailsRequestsList = createSelector(
  getTeamDetails,
  (details: TeamDetails | null) => (details ? details.requests : []),
)

export const getTeamDetailsModeratorsList = createSelector(
  getTeamDetails,
  (details: TeamDetails | null) => (details ? details.moderators : []),
)

export const getTeamDetailsModerators = createSelector(
  getTeamDetails,
  (teamDetails: TeamDetails | null): TeamModerators => {
    if (!teamDetails) return {}

    return teamDetails.moderators.reduce((acc, moderator) => ({...acc, [moderator.user]: moderator}), {})
  },
)

export const getTeamDetailsModerator = (state: any, {userId}: {userId: number}): ?TeamModerator =>
  getTeamDetailsModerators(state)[userId]

export const isTeamDetailsLoading = (state: any): boolean => local(state).teamDetails.loading

export const isTeamsLoading = (state: any): boolean => getTeams(state).loading

export const getTeamsList = createSelector(
  getTeamsIds,
  getTeamsById,
  (ids: Array<number>, teams: TeamsById): Array<Team> => ids.map(id => teams[id]),
)

export const getTeam = (state: any, {teamId}: {teamId: number}) => getTeamsData(state).byId[teamId]

export const isTournamentLoading = (state: any): boolean => getTournament(state).loading

export const getTournamentTable = (state: any): TournamentData | null => getTournamentData(state)

export const isTeamStagesLoading = (state: any): boolean => getTeamStages(state).loading

export const getTeamStagesIds = (state: any): Array<number> => getTeamStagesData(state).ids

export const getTeamStagesList = createSelector(
  getTeamStagesIds,
  (state: any): TeamStagesById => getTeamStagesData(state).byId,
  (ids, teamStages: TeamStagesById) => ids.map(id => teamStages[id]),
)

export const getTeamStage = (state: any, {stageId}: {stageId: number}): TeamStage =>
  getTeamStagesData(state).byId[stageId]

export const getStageMatchesData = (state: any): StageMatchesData => getStageMatches(state).data

export const isStageMatchesLoading = (state: any): boolean => getStageMatches(state).loading

export const hasMoreStageMatches = createSelector(
  getStageMatchesData,
  getStageMatchesCount,
  (data: StageMatchesData, count: number): boolean => count > data.reduce((sum, matches) => sum + matches.length, 0),
)

export const getStageMatchesList = createSelector(
  getStageMatchesData,
  (data: StageMatchesData): Array<StageMatch> => data.reduce((acc, matches) => acc.concat(matches), []),
)

export const getSingleUser = (state: any): TeamUser | null => getUser(state).data

export const isSingleUserLoading = (state: any): boolean => getUser(state).loading
