export const LOCATION_CHANGE = 'battle-py-frontend/router/LOCATION_CHANGE'

export const INCREMENT_LOADINGS = 'battle-py-frontend/router/INCREMENT_LOADINGS'
export const DECREMENT_LOADINGS = 'battle-py-frontend/router/DECREMENT_LOADINGS'
export const RESET_LOADINGS = 'battle-py-frontend/router/RESET_LOADINGS'

export const RELOAD_ROUTE = 'battle-py-frontend/router/RELOAD_ROUTER'

export const REMEMBER_SCROLL = 'battle-py-frontend/router/REMEMBER_SCROLL'
export const SET_SCROLL = 'battle-py-frontend/router/SET_SCROLL'
export const REMOVE_SCROLL = 'battle-py-frontend/router/REMOVE_SCROLL'
