export default {
  en: {
    name: 'English',
    code: 'en',
    getContent: () => import('./en').then(({default: content}) => content),
    enabled: true,
  },
  ru: {
    name: 'Русский',
    code: 'ru',
    getContent: () => import('./ru').then(({default: content}) => content),
    enabled: true,
  },
  fr: {
    name: 'Français',
    code: 'fr',
    getContent: () => import('./fr').then(({default: content}) => content),
    enabled: true,
  },
  kk: {
    name: 'Қазақша',
    code: 'kk',
    getContent: () => import('./kk').then(({default: content}) => content),
    enabled: true,
  },
  uk: {
    name: 'Українська',
    code: 'uk',
    getContent: () => import('./uk').then(({default: content}) => content),
    enabled: true,
  },
  de: {
    name: 'Deutsch',
    code: 'de',
    getContent: () => import('./de').then(({default: content}) => content),
    enabled: true,
  },
  es: {
    name: 'Español',
    code: 'es',
    // getContent: () => import('./es').then(({default: content}) => content),
    enabled: false,
  },
  it: {
    name: 'Italiano',
    code: 'it',
    getContent: () => import('./it').then(({default: content}) => content),
    enabled: true,
  },
}
