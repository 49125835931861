// @flow

import {createSelector} from 'reselect'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import first from 'lodash/first'

import {spreadHandicapListByKoefType} from 'store/lib/events'
import type {Koef, PredictsByKoefType, KoefsById, HandicapListsByKoefType} from 'store/lib/events'

import type {
  State,
  Match,
  MatchesState,
  MyPredictsState,
  KoefsTypesState,
  KoefsIdsByKoefType,
  ResultsState,
  MatchesPages,
} from './types'

const local = (state): State => state.core.events

const getMatchesState = (state): MatchesState => local(state).matches

const getKoefsTypes = (state): KoefsTypesState => local(state).koefsTypes

const getResults = (state): ResultsState => local(state).results

export const getKoefs = (state: any): KoefsById => local(state).koefs

export const getKoef = (state: any, {koefId}: {koefId: number}): Koef => getKoefs(state)[koefId]

export const getMyPredicts = (state: any): MyPredictsState => local(state).myPredicts

export const getMatch = (state: any, {matchId}: {matchId: number}): Match => {
  const matchesState = getMatchesState(state)

  return get(matchesState.pages, matchesState.paths[matchId])
}

export const isMatchEnded = (state: any, {matchId}: {matchId: number}): boolean => {
  const results = getResultsForMatch(state, {matchId})

  return results && !!results.endScore
}

export const getKoefsTypesForMatch = (state: any, {matchId}: {matchId: number}): KoefsIdsByKoefType =>
  getKoefsTypes(state)[matchId]

export const getKoefsListByTypesForMatch = createSelector(
  getKoefs,
  getKoefsTypesForMatch,
  (koefs: KoefsById, koefsIds: KoefsIdsByKoefType) =>
    mapValues(koefsIds, koefIds => koefIds.map(koefId => koefs[koefId])),
)

export const getMatchesPages = (state: any): MatchesPages => getMatchesState(state).pages

export const getMatchesCount = (state: any): number => local(state).count

export const hasMoreMatches = createSelector(
  getMatchesPages,
  getMatchesCount,
  (pages: MatchesPages, count: number): boolean => count > pages.reduce((sum, matches) => sum + matches.length, 0),
)

export const isMatchOnLastLoadedPage = (state: any, {matchId}: {matchId: number}) => {
  const {pages, paths} = getMatchesState(state)
  const page = Number(first(paths[matchId].split('.')))

  return page === pages.length - 1
}

export const getMatchesList = createSelector(
  getMatchesState,
  (matches: MatchesState): Array<Match> => matches.pages.reduce((acc, matches) => acc.concat(matches), []),
)

export const getMyPredictsForMatch = (state: any, {matchId}: {matchId: number}): PredictsByKoefType =>
  getMyPredicts(state)[matchId]

export const getMyPredict = (state: any, {matchId, koefType}: {[argName: string]: number}) =>
  get(getMyPredicts(state), `${matchId}.${koefType}`)

export const isLoading = (state: any) => local(state).loading

export const getHandicapListByMainKoefTypeForMatch = (createSelector(
  getKoefsTypesForMatch,
  getKoefs,
  spreadHandicapListByKoefType,
): (state: any, {matchId: number}) => HandicapListsByKoefType)

export const getResultsForMatch = (state: any, {matchId}: {matchId: number}) => getResults(state)[matchId]
