// @flow

import {
  LOGIN,
  LOGIN_FB,
  FINISH_LOGIN,
  LOGOUT,
  FINISH_USER_LOGOUT,
  USER_LOGOUT,
  START_LOGIN,
  SET_USER,
  LOAD_INFO,
  SET_INFO,
  START_LOAD_INFO,
  FINISH_LOAD_INFO,
  START_LOAD_SCORES,
  FINISH_LOAD_SCORES,
  SET_SCORES_DATA,
  LOAD_SCORES,
  UPDATE_OLIMP_LOGIN,
  UPDATE_OLIMP_LOGIN_ERROR,
  SET_USER_OLIMP_LOGIN, RESET_AUTH_ERROR,
} from './constants'

export const logout = () => ({type: LOGOUT})

export const userLogout = () => ({type: USER_LOGOUT})

export const finishUserLogout = () => ({type: FINISH_USER_LOGOUT})

export const startLogin = () => ({type: START_LOGIN})

export const finishLogin = (user, error) => ({type: FINISH_LOGIN, payload: {user, error}})

export const resetAuthError = () => ({type: RESET_AUTH_ERROR})

export const login = ({username, password}) => ({type: LOGIN, payload: {username, password}})

export const loginFB = () => ({type: LOGIN_FB})

export const setUser = user => ({type: SET_USER, payload: user})

export const setUserOlimpLogin = (payload: string) => ({type: SET_USER_OLIMP_LOGIN, payload})

export const loadInfo = () => ({type: LOAD_INFO})

export const setInfo = payload => ({type: SET_INFO, payload})

export const startLoadInfo = () => ({type: START_LOAD_INFO})

export const finishLoadInfo = () => ({type: FINISH_LOAD_INFO})

export const startLoadScores = () => ({type: START_LOAD_SCORES})

export const finishLoadScores = () => ({type: FINISH_LOAD_SCORES})

export const setScoresData = payload => ({type: SET_SCORES_DATA, payload})

export const loadScores = () => ({type: LOAD_SCORES})

export const updateOlimpLogin = userOlimpLogin => ({type: UPDATE_OLIMP_LOGIN, payload: userOlimpLogin})

export const updateOlimpLoginError = (message: string) => ({type: UPDATE_OLIMP_LOGIN_ERROR, payload: {message}})
