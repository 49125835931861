// @flow

import {combineReducers} from 'redux'
import {Stack} from 'immutable'

import type {State, MessagesState, ConnectionState} from './types'
import {ADD_MESSAGE, CONNECT_FAILURE, CONNECT, CONNECT_SUCCESS, SET_MESSAGES, DISCONNECT} from './constants'

const MAX_MESSAGES_COUNT = 100

const initialState: State = {
  messages: {
    data: Stack([]),
  },
  connection: {
    established: false,
    processing: false,
  },
}

const messages = (state: MessagesState = initialState.messages, {type, payload}) => {
  switch (type) {
    case SET_MESSAGES:
      return {
        ...state,
        data: state.data
          .clear()
          .concat(payload.messages)
          .slice(-MAX_MESSAGES_COUNT),
      }

    case ADD_MESSAGE:
      return {...state, data: state.data.concat(payload.message).slice(-MAX_MESSAGES_COUNT)}

    default:
      return state
  }
}

const connection = (state: ConnectionState = initialState.connection, {type}) => {
  switch (type) {
    case CONNECT:
      return {...state, processing: true}

    case CONNECT_SUCCESS:
      return {...state, established: true, processing: false}

    case CONNECT_FAILURE:
    case DISCONNECT:
      return {...state, established: false, processing: false}

    default:
      return state
  }
}

export default combineReducers({messages, connection})
