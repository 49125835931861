// @flow

import {takeLatest, call, put, select} from 'redux-saga/effects'
import type {Moment} from 'moment'
import moment from 'moment'

import api from 'api'

import {LOAD_TEAM_REQUESTS, MESSAGES_READ} from './constants'
import {startLoadingTeamRequests, finishLoadingTeamRequests, setTeamRequests} from './actions'
import type {_TeamRequestsData, TeamRequestsData} from './types'
import {getTeamRequestsList} from './selectors'

const adaptDateToMoment = (date: string, offset: number): Moment => moment.utc(date, 'DD-MM-YYYY').utcOffset(offset)

type Inject = {
  handlerEnhancer: Function,
  selectTimezoneOffset: Function,
  selectChampId: Function,
}

export default (inj: Inject) => {
  return function*(): any {
    yield takeLatest(LOAD_TEAM_REQUESTS, inj.handlerEnhancer(loadTeamRequestsHandler, inj))

    yield takeLatest(MESSAGES_READ, inj.handlerEnhancer(messagesReadHandler, inj))
  }
}

function* handleTeamRequestsResponse({selectTimezoneOffset}: Inject, data) {
  const timezoneOffset = yield select(selectTimezoneOffset)

  const teamRequests: TeamRequestsData = data.map(req => ({
    ...req,
    requestDate: adaptDateToMoment(req.requestDate, timezoneOffset),
  }))

  yield put(setTeamRequests(teamRequests))
}

function* loadTeamRequestsHandler(inj: Inject): any {
  yield put(startLoadingTeamRequests())

  const champId = yield select(inj.selectChampId)
  const {data}: {data: _TeamRequestsData} = yield call(api.get, `/championship/${champId}/teams/requests/`)

  if (data) {
    yield call(handleTeamRequestsResponse, inj, data)
  }

  yield put(finishLoadingTeamRequests())
}

function* messagesReadHandler(inj: Inject) {
  const champId = yield select(inj.selectChampId)
  const requests = yield select(getTeamRequestsList)
  const newIds = requests.filter(({isNew}) => isNew).map(({id}) => ({id}))

  if (newIds.length > 0) yield call(api.get, `/championship/${champId}/teams/check/requests/`)
}
