// @flow

import {reduxForm, SubmissionError} from 'redux-form'
import mapValues from 'lodash/mapValues'
import first from 'lodash/first'

const adaptErrors = errors => {
  if (Array.isArray(errors)) return first(errors)

  const {nonFieldErrors, ...fieldErrors} = errors

  return {
    ...(nonFieldErrors ? {_error: first(nonFieldErrors)} : {}),
    ...mapValues(fieldErrors, adaptErrors),
  }
}

export default ({onSubmit, ...rest}: any) =>
  reduxForm({
    ...rest,
    onSubmit: (values, dispatch, props) => {
      const enhancedDispatch = action => {
        return new Promise((resolve, reject) => {
          const submissionReject = errors => reject(new SubmissionError(adaptErrors(errors)))

          if (typeof action === 'function') {
            dispatch(async (...args) => {
              const errors = await action(...args)

              if (errors) submissionReject(errors)
              else resolve()
            })
          } else {
            dispatch({...action, meta: {...action.meta, resolve, reject: submissionReject}})
          }
        })
      }

      return (onSubmit || props.submitHandler)(values, enhancedDispatch, props)
    },
  })
