// @flow

import {spawn, takeEvery, put} from 'redux-saga/effects'
import values from 'lodash/values'

import handlerEnhancer from 'store/lib/handler-enhancer'

import predicts from './predicts/saga'
import users from './users/saga'
import teams from './teams/saga'
import reports from './reports/saga'
import requests from './requests/saga'
import ui from './ui/saga'
import * as predictsErrors from './predicts/errors'
import {getKoef} from './koefs/selectors'

type Inject = {
  putError: Function,
  selectChampId: Function,
}

export default ({putError, selectChampId}: Inject) =>
  function*(): any {
    const errorsList = [predictsErrors].reduce((acc, errors) => [...acc, ...values(errors)], [])
    yield takeEvery(errorsList, function*({type, payload: {message}}) {
      yield put(putError(type, message))
    })

    yield spawn(predicts({getKoef, putError, handlerEnhancer}))
    yield spawn(ui())
    yield spawn(users({handlerEnhancer}))
    yield spawn(reports({handlerEnhancer, selectChampId}))
    yield spawn(teams({handlerEnhancer}))
    yield spawn(requests({handlerEnhancer}))
  }
