import {connectRouter, LOCATION_CHANGE} from 'connected-react-router'

import routes from 'store/routes'
import values from 'lodash/values'
import {matchPath} from 'react-router-dom'

import {INCREMENT_LOADINGS, DECREMENT_LOADINGS, RESET_LOADINGS, SET_SCROLL, REMOVE_SCROLL} from './constants'
import {adaptParams} from './utils'

const initialState = {
  prevLocation: null,
  location: null,
  loadings: 1,
  scroll: {},
}

export default function createRouterReducer(history) {
  const routerReducer = connectRouter(history)

  return (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
      case LOCATION_CHANGE: {
        const {location} = routerReducer(state, action)
        const nextLocation = {
          ...location,
          matchedRoutes: values(routes).reduce((acc, path) => {
            const match = matchPath(location.pathname, {path})
            return {
              ...acc,
              [path]: match && {
                ...match,
                params: adaptParams(match.params),
              },
            }
          }, {}),
        }
        return {...state, location: nextLocation, prevLocation: state.location}
      }

      case INCREMENT_LOADINGS: {
        return {...state, loadings: state.loadings + 1}
      }

      case DECREMENT_LOADINGS: {
        return {...state, loadings: state.loadings - 1}
      }

      case RESET_LOADINGS: {
        return {...state, loadings: initialState.loadings}
      }

      case SET_SCROLL:
        return {...state, scroll: {...state.scroll, [payload.pathname]: payload.scroll}}

      case REMOVE_SCROLL: {
        const {[payload.pathname]: omit, ...next} = state.scroll

        return {...state, scroll: next}
      }

      default:
        return routerReducer(state, action)
    }
  }
}
