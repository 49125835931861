// @flow

import {combineReducers} from 'redux'

import type {State} from './types'
import {SET_CHAMP} from './constants'

const initialState: State = {
  champ: null,
}

const champ = (state = initialState.champ, {payload, type}) => {
  switch (type) {
    case SET_CHAMP:
      return payload

    default:
      return state
  }
}

export default combineReducers({champ})
