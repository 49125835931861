// @flow

import {combineReducers} from 'redux'

import type {State} from './types'
import {SET_TEAM_REQUESTS, START_LOADING_TEAM_REQUESTS, FINISH_LOADING_TEAM_REQUESTS} from './constants'

const initialState: State = {
  teamRequests: {
    data: {},
    loading: false,
  },
}

const teamRequests = (state = initialState.teamRequests, {payload, type}) => {
  switch (type) {
    case SET_TEAM_REQUESTS:
      return {...state, data: payload}

    case START_LOADING_TEAM_REQUESTS:
      return {...state, loading: true}

    case FINISH_LOADING_TEAM_REQUESTS:
      return {...state, loading: false}

    default:
      return state
  }
}

export default combineReducers({teamRequests})
