// @flow

import {takeLatest, takeEvery, put, call} from 'redux-saga/effects'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import deCamelize from 'decamelize-keys-deep'
import camelize from 'camelcase-keys-deep'

import api, {setToken} from 'api'
import * as fb from 'helpers/fb'

import {LOGIN, LOGIN_FB, LOGOUT, USER_LOGOUT, LOAD_INFO, LOAD_SCORES, UPDATE_OLIMP_LOGIN} from './constants'
import {
  finishLogin,
  finishUserLogout,
  startLogin,
  setUser,
  setInfo,
  startLoadInfo,
  finishLoadInfo,
  loadInfo,
  startLoadScores,
  finishLoadScores,
  setScoresData,
  updateOlimpLoginError,
  setUserOlimpLogin,
} from './actions'
import type {_InfoData, InfoData, ScoresData} from './types'
import * as errors from './errors'

type Inject = {
  putError: Function,
  handlerEnhancer: Function,
}

const LS_USER_KEY = 'user'

function* updateUser(user) {
  if (user) {
    // FIXME: remove deCamelize in the future
    yield call([localStorage, localStorage.setItem], LS_USER_KEY, JSON.stringify(deCamelize(user)))
    yield call(setToken, user.authToken)
    yield put(setUser(user))
    yield put(loadInfo())
  } else {
    yield call([localStorage, localStorage.removeItem], LS_USER_KEY)
    yield call(setToken, null)
    yield put(setUser(null))
  }
}

function* restoreUser() {
  const userString = yield call([localStorage, localStorage.getItem], LS_USER_KEY)
  const user = userString && JSON.parse(userString)
  if (user && !isEmpty(user)) {
    // FIXME: remove camelize in the future
    yield call(updateUser, camelize(JSON.parse(userString)))
  }
}

function* loginHandler({payload: {username, password}}) {
  yield put(startLogin())

  const {data} = yield call(api.post, '/login/', {username, password})
  const {errors, ...user} = data

  if (!errors) yield call(updateUser, user)
  yield put(finishLogin(user, errors))

  return errors
}

function* loginFBHandler() {
  yield put(startLogin())

  const fbUser = yield call(fb.login, {scope: 'public_profile,email'})

  if (fbUser.status === 'connected') {
    const {accessToken} = fbUser.authResponse
    const {data, status} = yield call(api.post, '/login/fb/', {accessToken})

    if (status !== 200) {
      return yield put(finishLogin(null, data.error))
    }

    yield call(updateUser, data)
    yield put(finishLogin(data))
  }
}

function* logoutHandler() {
  yield call([localStorage, localStorage.removeItem], LS_USER_KEY)
  yield call(setToken, null)
}

function* userLogoutHandler() {
  const {status} = yield call(api.get, '/fb/logout/')

  if (status === 200) {
    yield call(updateUser, null)
  }

  yield put(finishUserLogout())
}

function* loadInfoHandler() {
  yield put(startLoadInfo())

  const {data: rawData}: {data: _InfoData} = yield call(api.get, '/get/user/info/')

  // $FlowFixMe
  const data: InfoData = {
    ...rawData,
    getChampionships: rawData.getChampionships.reduce((acc, champ) => ({...acc, [champ.id]: champ}), {}),
  }

  yield put(setInfo(data))
  yield put(finishLoadInfo())
}

function* loadScoresHandler() {
  yield put(startLoadScores())

  const {data}: {data: ScoresData} = yield call(api.get, '/profile/')

  yield put(setScoresData(data))

  yield put(finishLoadScores())
}

function* updateOlimpLoginHandler({payload: userOlimpLogin}) {
  const {data, status} = yield call(api.put, '/login/olimp/', {userOlimpLogin})

  if (status === 200) {
    yield put(setUserOlimpLogin(data.userOlimpLogin))
  } else if (status === 400) {
    yield put(updateOlimpLoginError(data.userOlimpLogin[0]))

    return data
  }
}

function* eventHandler({putError}: Inject, {payload: {message}, type}) {
  yield put(putError(type, message))
}

export default (inj: Inject) =>
  function*(): Generator<*, *, *> {
    yield takeLatest(LOGIN, inj.handlerEnhancer(loginHandler))

    yield takeLatest(LOGIN_FB, loginFBHandler)

    yield takeLatest(LOGOUT, logoutHandler)

    yield takeLatest(USER_LOGOUT, userLogoutHandler)

    yield takeLatest(LOAD_INFO, inj.handlerEnhancer(loadInfoHandler))

    yield takeLatest(LOAD_SCORES, loadScoresHandler)

    yield takeLatest(UPDATE_OLIMP_LOGIN, inj.handlerEnhancer(updateOlimpLoginHandler))

    yield takeEvery(values(errors), eventHandler, inj)

    yield call(restoreUser)
  }
