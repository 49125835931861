// @flow

import {takeLatest, put, call, select} from 'redux-saga/effects'
import last from 'lodash/last'
import first from 'lodash/first'
import get from 'lodash/get'

import api from 'api'

import {LOAD_USERS, LOAD_USER, LOAD_MY_USER} from './constants'
import {
  startLoadingUsers,
  finishLoadingUsers,
  addUsers,
  resetUsers,
  setUsersCount,
  startLoadingUser,
  finishLoadingUser,
  setUser,
  setMyUser,
} from './actions'
import type {User} from './types'
import {getUsersPagesList} from './selectors'

const searchToParams = (search?: {team?: string, name?: string}) => {
  if (!search) return {}

  return {full_name: get(search, 'name', '').trim(), group: get(search, 'team', undefined)}
}

function* loadUsersHandler({selectChampId}: Inject, {payload: {fromStart, search}}) {
  if (fromStart) yield put(resetUsers())
  // else if (!(yield select(hasMoreUsers))) return

  try {
    yield put(startLoadingUsers())

    const champId = yield select(selectChampId)
    const lastPage = yield select(getUsersPagesList)
    const params = {
      ...searchToParams(search),
      page: fromStart ? 1 : lastPage && !!lastPage.length ? last(lastPage) + 2 : 1,
    }
    const {data, status} = yield call(api.get, `/championship/${champId}/ratings/`, {params})

    if (status === 200) {
      const users: Array<User> = data.results

      yield put(setUsersCount(data.count))
      yield put(addUsers(users))
    }
  } finally {
    yield put(finishLoadingUsers())
  }
}

function* loadUserHandler({selectChampId, loadRewards}: Inject, {payload: {userId, search}}) {
  try {
    yield put(startLoadingUser())
    yield put(setUser(null))

    const champId = yield select(selectChampId)
    const params = {...searchToParams(search), id: userId}
    const {data, status} = yield call(api.get, `/championship/${champId}/ratings/`, {params})
    yield put(loadRewards({id: userId}))

    if (status === 200) {
      yield put(setUser(first(data.results)))
    }
  } finally {
    yield put(finishLoadingUser())
  }
}

function* loadMyUserHandler({selectChampId, selectMyId}: Inject, {payload: {search}}) {
  const champId = yield select(selectChampId)
  const id = yield select(selectMyId)
  const params = {...searchToParams(search), id}
  const {data, status} = yield call(api.get, `/championship/${champId}/ratings/`, {params})

  yield put(setMyUser(status === 200 ? first(data.results) : null))
}

type Inject = {
  handlerEnhancer: Function,
  selectChampId: Function,
  loadRewards: Function,
  selectMyId: Function,
}

export default (inj: Inject) =>
  function*(): Generator<*, *, *> {
    yield takeLatest(LOAD_USERS, inj.handlerEnhancer(loadUsersHandler, inj))

    yield takeLatest(LOAD_USER, inj.handlerEnhancer(loadUserHandler, inj))

    yield takeLatest(LOAD_MY_USER, inj.handlerEnhancer(loadMyUserHandler, inj))
  }
