// @flow

import {spawn, all, call, put} from 'redux-saga/effects'

import {setLang} from 'api'

import core from './core/saga'
import admin from './admin/saga'
import router from './router/saga'
import ui from './ui/saga'
import settings from './settings/saga'
import locale from './locale/saga'
import form from './form/saga'
import pagination from './pagination/saga'
import {getTimezoneOffset} from './settings/selectors'
import {putError} from './ui/actions'
import {getChampId} from './ui/selectors'
import {SET_CHAMP_ID} from './ui/constants'
import {l, getCurrentLocale} from './locale/selectors'
import {reloadRoute} from './router/actions'
import {loadRewards} from './core/rewards/actions'

function* onSetLocale(locale) {
  yield call(setLang, locale.code)
  yield put(reloadRoute())
}

export default function*(): any {
  yield all([
    spawn(settings),
    spawn(locale({onSetLocale})),
    spawn(
      core({
        selectTimezoneOffset: getTimezoneOffset,
        putError,
        loadRewards,
        selectChampId: getChampId,
        selectLocale: getCurrentLocale,
      }),
    ),
    spawn(admin({putError, selectChampId: getChampId})),
    spawn(router({selectChampId: getChampId, SET_CHAMP_ID, l})),
    spawn(ui),
    spawn(form()),
    spawn(pagination()),
  ])
}
