// @flow

import {combineReducers} from 'redux'

import {
  START_FETCH_CHAMPIONSHIP_LIST,
  FINISH_FETCH_CHAMPIONSHIP_LIST,
  START_FETCH_CHAMPIONSHIP,
  FINISH_FETCH_CHAMPIONSHIP,
} from './constants'
import type {State, ListState, DetailsState} from './types'

const initialState: State = {
  list: {
    data: [],
    loading: false,
  },
  details: {
    data: null,
    loading: false,
  },
}

const list = (state: ListState = initialState.list, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_CHAMPIONSHIP_LIST: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_CHAMPIONSHIP_LIST: {
      return {...state, data: payload, loading: false}
    }

    default:
      return state
  }
}

const details = (state: DetailsState = initialState.details, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_CHAMPIONSHIP: {
      return {...state, loading: true, data: initialState.details.data}
    }

    case FINISH_FETCH_CHAMPIONSHIP: {
      return {...state, data: payload, loading: false}
    }

    default:
      return state
  }
}

export default combineReducers({list, details})
