// @flow

import keys from 'lodash/keys'
import first from 'lodash/first'
import {createSelector} from 'reselect'

import type {State, User, UsersState, UsersData, UserState} from './types'

const EMPTY_ARRAY = []

const local = (state: any): State => state.core.rating

const getUsers = (state: any): UsersState => local(state).users

const getUsersData = (state: any): UsersData => getUsers(state).data

const getUser = (state: any): UserState => local(state).user

const getMyUserState = (state: any): UserState => local(state).myUser

export const getUsersPagesList = createSelector(
  getUsersData,
  (data: UsersData): Array<number> => keys(data.pages).map(Number),
)

export const getUsersListForPage = (state: any, {page}: {page: number}): Array<User> =>
  getUsersData(state).pages[page] || EMPTY_ARRAY

export const getUsersList = createSelector(
  getUsersData,
  (data: UsersData): Array<User> => data.pages.reduce((acc, users) => [...acc, ...users], []),
)

export const hasUserInUsers = (state: any, params: {userId: number}): boolean =>
  !!getUsersData(state).paths[params.userId]

export const getUsersCount = (state: any): number | null => getUsers(state).count

export const hasMoreUsers = (state: any): boolean => {
  const count = getUsersCount(state)
  const pages = getUsersPagesList(state)

  if (!count || !pages.length) return false

  const pageSize = getUsersListForPage(state, {page: first(pages)}).length

  return count > pageSize * pages.length
}

export const isUsersLoading = (state: any): boolean => getUsers(state).loading

export const getSingleUser = (state: any): User | null => getUser(state).data

export const isSingleUserLoading = (state: any): boolean => getUser(state).loading

export const isMyUserLoading = (state: any): boolean => getMyUserState(state).loading

export const getMyUser = (state: any): ?User => getMyUserState(state).data
