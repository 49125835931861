// @flow

import type {State, TeamRequestsData, TeamRequestsState} from './types'

const local = (state: any): State => state.core.incoming

const getTeamRequests = (state): TeamRequestsState => local(state).teamRequests

export const getTeamRequestsList = (state: any): TeamRequestsData => getTeamRequests(state).data

export const isTeamRequestsLoading = (state: any): boolean => getTeamRequests(state).loading
