import pathToRegexp from 'path-to-regexp'
import isString from 'lodash/isString'
import isNil from 'lodash/isNil'

const ADMIN_HIDDEN_URL = '/qazwsx15243'

export const CHAMP_PARAM = 'champ'
export const PARAM_VAL_NONE = 'none'

const adminBaseURL = path => `${ADMIN_HIDDEN_URL}${path ? '/' + path : ''}`
const adminChampURL = path => adminBaseURL(`:${CHAMP_PARAM}(${PARAM_VAL_NONE}|\\d+)${path ? '/' + path : ''}`)

export default {
  ROOT: '/',
  PRIVACY: '/privacy',
  LOGIN: '/login',
  PROFILE: '/profile',
  UPCOMING: '/upcoming',
  UPCOMING_MATCH: '/upcoming/:id',
  COMPLETED: '/completed',
  USER_RATING: '/user-rating',
  USER_RATING_USER: '/user-rating/:id',
  TOURNAMENT: '/tournament',
  TOURNAMENT_TEAM: '/tournament/:id',
  TOURNAMENT_TEAM_USER: '/tournament/:id/:userId',
  TOP: '/top',
  TOP_TEAM: '/top/:team',
  TOP_TEAM_STAGE: '/top/:team/:stage',
  NOTIFICATIONS: '/notifications',
  TEAMS: '/teams',
  TEAMS_MY: '/teams/my',
  TEAMS_MY_MEMBER: '/teams/my/:id',
  RELOAD: '/reload',
  RULES: '/rules',

  ADMIN: adminBaseURL(),
  ADMIN_LOGIN: adminBaseURL('login'),
  ADMIN_CHAMPIONSHIPS: adminBaseURL('champs'),
  ADMIN_CHAMPS_ADD: adminBaseURL(`champs/add`),
  ADMIN_CHAMPS_CHAMP_EDIT: adminBaseURL(`champs/:id/edit`),
  ADMIN_REWARDS: adminBaseURL('rewards'),
  ADMIN_REWARDS_ADD: adminBaseURL('rewards/add'),
  ADMIN_REWARDS_EDIT: adminBaseURL('rewards/:id/edit'),
  ADMIN_CHAMP: adminChampURL(),
  ADMIN_CHAMP_PLAYOFFS: adminChampURL('playoffs'),
  ADMIN_CHAMP_PLAYOFFS_ADD: adminChampURL('playoffs/add'),
  ADMIN_CHAMP_STAGES: adminChampURL('stages'),
  ADMIN_CHAMP_STAGES_STAGE_MATCHES: adminChampURL('stages/:id/matches'),
  ADMIN_CHAMP_STAGES_ADD: adminChampURL('stages/add'),
  ADMIN_CHAMP_STAGES_STAGE_EDIT: adminChampURL('stages/:id/edit'),
  ADMIN_CHAMP_MATCHES: adminChampURL('matches'),
  ADMIN_CHAMP_MATCHES_ADD: adminChampURL('matches/add'),
  ADMIN_CHAMP_MATCHES_MATCH_EDIT: adminChampURL('matches/:id/edit'),
  ADMIN_CHAMP_MATCHES_MATCH_DETAIL: adminChampURL('matches/:id/detail'),
  ADMIN_CHAMP_MATCHES_MATCH_PREDICTS: adminChampURL('matches/:id/predicts'),
  ADMIN_CHAMP_MATCHES_MATCH_CLOSE: adminChampURL('matches/:id/close'),
  ADMIN_CHAMP_MATCHES_MATCH_KOEFS: adminChampURL('matches/:id/koefs'),
  ADMIN_CHAMP_MATCHES_MATCH_KOEFS_ADD_TYPE: adminChampURL('matches/:id/koefs/add/:type'),
  ADMIN_KOEFS_KOEF_EDIT: adminBaseURL('koefs/:id/edit'),
  ADMIN_CHAMP_GROUPS: adminChampURL('groups'),
  ADMIN_CHAMP_GROUPS_GROUP_TEAMS: adminChampURL('groups/:id/teams'),
  ADMIN_CHAMP_GROUPS_GROUP_EDIT: adminChampURL('groups/:id/edit'),
  ADMIN_CHAMP_GROUPS_ADD: adminChampURL('groups/add'),
  ADMIN_CHAMP_TEAMS: adminChampURL('teams'),
  ADMIN_CHAMP_TEAMS_TEAM: adminChampURL('teams/:id'),
  ADMIN_CHAMP_TEAMS_TEAM_REQUESTS: adminChampURL('teams/:id/requests'),
  ADMIN_CHAMP_TEAMS_TEAM_REQUESTS_ADD: adminChampURL('teams/:id/requests/new'),
  ADMIN_CHAMP_TEAMS_ADD: adminChampURL('teams/add'),
  ADMIN_CHAMP_TEAMS_TEAM_EDIT: adminChampURL('teams/:id/edit'),
  ADMIN_CHAMP_TEAMS_TEAM_MODERATORS: adminChampURL('teams/:id/moderators'),
  ADMIN_CHAMP_TEAMS_TEAM_MEMBERS: adminChampURL('teams/:id/members'),
  ADMIN_CHAMP_TEAMS_TEAM_MODERATORS_ADD: adminChampURL('teams/:id/moderators/add'),
  ADMIN_CHAMP_USERS: adminChampURL(`users`),
  ADMIN_CHAMP_USERS_ADD: adminChampURL(`users/add`),
  ADMIN_CHAMP_USERS_USER_EDIT: adminChampURL(`users/:id/edit`),
  ADMIN_CHAMP_USERS_USER_PREDICTS: adminChampURL(`users/:id/predicts`),
  ADMIN_CHAMP_USERS_USER_PREDICTS_MATCH: adminChampURL(`users/:user/predicts/:match`),
  ADMIN_CHAMP_USERS_USER_TEAMS: adminChampURL(`users/:user/teams`),
  ADMIN_CHAMP_USERS_USER_REWARDS: adminChampURL(`users/:user/rewards`),
  ADMIN_CHAMP_USERS_USER_REWARDS_ADD: adminChampURL(`users/:user/rewards/add`),
  ADMIN_CHAMP_PREDICTS_PREDICT_EDIT: adminChampURL('predicts/:id/edit'),
  ADMIN_CHAMP_PREDICTS_ADD: adminChampURL('predicts/add'),
  ADMIN_CHAMP_NOTIFICATIONS_LIST: adminChampURL('notifications'),
  ADMIN_CHAMP_NOTIFICATIONS_ADD: adminChampURL('notifications/add'),
  ADMIN_CHAMP_NOTIFICATIONS_EDIT: adminChampURL('notifications/:id/edit'),
  ADMIN_REPORTS_USERS_RATING: adminBaseURL('reports/users-rating'),
  ADMIN_REPORTS_STARS: adminBaseURL('reports/stars'),
  ADMIN_REPORTS_STARS_STAR_CHAMP: adminBaseURL('reports/stars/:id/:champId'),
  ADMIN_REPORTS_GROUPS_RATING: adminChampURL('reports/groups-rating'),
  ADMIN_REPORTS_MATCHES_LIST: adminBaseURL('reports/matches-list'),
  ADMIN_REPORTS_MATCH_TEAMS_RATING: adminBaseURL('reports/:id/teams-rating'),
  ADMIN_REPORTS_TEAM_CONTRIBUTION: adminChampURL('reports/contribution-to-team'),
  ADMIN_REPORTS_WINNERS_TOP250: adminBaseURL('reports/top250-winners'),
  ADMIN_CHAMP_RULES_LANG: adminChampURL('rules/:lang?'),
}

const fillToken = ({prefix, name, pattern, optional}, params) => {
  let line = prefix

  if (isNil(params[name])) {
    line = line.concat(`:${name}`)

    if (optional) line = line.concat('?')
    if (pattern) line = line.concat(`(${pattern})`)
  } else {
    line = line.concat(params[name])
  }

  return line
}

export const fillRoute = (route, params) =>
  pathToRegexp.parse(route).reduce((line, token) => line + (isString(token) ? token : fillToken(token, params)), '')
