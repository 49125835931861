// @flow

import {combineReducers} from 'redux'

import Pagination from 'store/lib/pagination'

import type {State, SingleState} from './types'
import {START_FETCH_USER, FINISH_FETCH_USER} from './constants'

const initialState: State = {
  single: {
    data: null,
    loading: false,
  },
}

const single = (state: SingleState = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_USER: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_USER: {
      return {...state, loading: false, data: payload.data || initialState.single.data}
    }

    default:
      return state
  }
}

export const collection = new Pagination('battle-py-frontend/admin/users', 'admin.users.collection')

export default combineReducers({collection: collection.reducer, single})
