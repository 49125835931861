// @flow

import {take, cancel, put, select, call, fork} from 'redux-saga/effects'
import get from 'lodash/get'
import first from 'lodash/first'
import decamelize from 'decamelize'
import mapValues from 'lodash/mapValues'

import api from 'api'

type Saga = Inject => Generator<*, *, *>

type Reducer = Function

type Constants = {
  LOAD: string,
  START_LOADING: string,
  FINISH_LOADING: string,
  SET_COUNT: string,
  SET_DATA: string,
  SET_PAGE_SIZE: string,
  RESET: string,
}

type Actions = {
  load: Function,
  setData: Function,
  setCount: Function,
  setPageSize: Function,
  startLoading: Function,
  finishLoading: Function,
  reset: Function,
}

type Selectors = {
  getItems: Function,
  getPagesCount: Function,
  getPageSize: Function,
  isLoading: Function,
}

type Data = Array<*>

export type State = {
  data: Data,
  count: number,
  loading: boolean,
  pageSize: number,
}

type MakeReq = (Object, Inject) => {url: string, params?: Object}
type AdaptItem = (item: any, index: number, items: Array<any>) => any
type Inject = {
  handlerEnhancer: Function,
  makeReq?: MakeReq,
  adaptItem?: AdaptItem,
}

export const ACTION_META_MODULE = '@@pagination/module'
export const ACTION_META_MAKE_REQ = '@@pagination/makeReq'
export const ACTION_META_ADAPT_ITEM = '@@pagination/adaptItem'

const initialState: State = {
  data: [],
  count: 0,
  pageSize: 10,
  loading: false,
}

export const createConstants = (module: string): Constants => ({
  LOAD: `${module}/LOAD`,
  START_LOADING: `${module}/START_LOADING`,
  FINISH_LOADING: `${module}/FINISH_LOADING`,
  SET_COUNT: `${module}/SET_COUNT`,
  RESET: `${module}/RESET`,
  SET_DATA: `${module}/SET_DATA`,
  SET_PAGE_SIZE: `${module}/SET_PAGE_SIZE`,
})

export const createReducer = (constants: Constants): Reducer => (
  state: State = initialState,
  {payload, type},
): State => {
  switch (type) {
    case constants.SET_DATA:
      return {...state, data: payload}

    case constants.START_LOADING:
      return {...state, loading: true}

    case constants.FINISH_LOADING:
      return {...state, loading: false}

    case constants.SET_COUNT:
      return {...state, count: payload}

    case constants.SET_PAGE_SIZE:
      return {...state, pageSize: payload}

    case constants.RESET:
      return initialState

    default:
      return state
  }
}

export const createActions = (constants: Constants): Actions => ({
  load: (
    payload: {page?: number, pageSize?: number, sorted?: any, filtered?: any},
    module?: string,
    {makeReq, adaptItem}?: {makeReq?: MakeReq, adaptItem?: AdaptItem} = {},
  ) => ({
    type: constants.LOAD,
    payload,
    meta: {[ACTION_META_MODULE]: module, [ACTION_META_MAKE_REQ]: makeReq, [ACTION_META_ADAPT_ITEM]: adaptItem},
  }),
  setData: (payload: Array<*>, module?: string) => ({
    type: constants.SET_DATA,
    payload,
    meta: {[ACTION_META_MODULE]: module},
  }),
  setCount: (payload: number, module?: string) => ({
    type: constants.SET_COUNT,
    payload,
    meta: {[ACTION_META_MODULE]: module},
  }),
  setPageSize: (payload: number, module?: string) => ({
    type: constants.SET_PAGE_SIZE,
    payload,
    meta: {[ACTION_META_MODULE]: module},
  }),
  startLoading: (module?: string) => ({type: constants.START_LOADING, meta: {[ACTION_META_MODULE]: module}}),
  finishLoading: (module?: string) => ({type: constants.FINISH_LOADING, meta: {[ACTION_META_MODULE]: module}}),
  reset: (module?: string) => ({type: constants.RESET, meta: {[ACTION_META_MODULE]: module}}),
})

export const createSelectors = (root: string): Selectors => {
  const local = (state: any, module?: string): State => get(state, `${root}${module ? '.' + module : ''}`, {})

  const getItems = (module?: string) => (state: any): Data => local(state, module).data || []

  const getPageSize = (module?: string) => (state: any): number => local(state, module).pageSize

  const getCount = (module?: string) => (state: any): number => local(state, module).count

  const getPagesCount = (module?: string) => (state: any): number => {
    const pageSize = getPageSize(module)(state)
    const count = getCount(module)(state)

    if (pageSize === Infinity && count > 0) return 1

    return Math.ceil(count / pageSize)
  }

  const isLoading = (module?: string) => (state: any): boolean => local(state, module).loading

  return {
    getPageSize,
    getItems,
    getPagesCount,
    isLoading,
  }
}

const adaptSorted = sorted => {
  const sortedItem = Array.isArray(sorted) ? first(sorted) : sorted

  if (!sortedItem) return void 0

  const {desc, id} = sortedItem

  return (desc ? '-' : '') + decamelize(id)
}

const adaptFiltered = filtered => {
  const filteredItems = Array.isArray(filtered) ? filtered : [filtered]

  return filteredItems.reduce((acc, {id, value}) => ({...acc, [decamelize(id)]: `${value}`.trim()}), {})
}

const PSEUDO_INFINITY_LIMIT = 9999

export const createSaga = (constants: Constants, actions: Actions, selectors: Selectors): Saga => (
  inj: Inject,
): Function => {
  function* loadHandler(inj: Inject, {payload, meta}) {
    const module = meta[ACTION_META_MODULE]
    const currentPageSize = yield select(selectors.getPageSize(module))
    const {page = 0, pageSize = currentPageSize, sorted, filtered, ...rest} = payload

    if (pageSize !== currentPageSize) yield put(actions.setPageSize(pageSize, module))

    try {
      yield put(actions.startLoading(module))

      const {url, params} = yield call(inj.makeReq || meta[ACTION_META_MAKE_REQ], payload, inj)

      const limit = pageSize === Infinity ? PSEUDO_INFINITY_LIMIT : pageSize
      const {data, status} = yield call(api.get, url, {
        params: {
          limit,
          offset: limit * page,
          page: page + 1,
          ordering: sorted && adaptSorted(sorted),
          ...(filtered && adaptFiltered(filtered)),
          ...params,
          ...rest,
        },
      })

      if (status === 200) {
        const adaptItem = inj.adaptItem || meta[ACTION_META_ADAPT_ITEM]

        yield put(actions.setCount(data.count, module))
        yield put(actions.setData(adaptItem ? data.results.map(adaptItem) : data.results, module))
      }
    } finally {
      yield put(actions.finishLoading(module))
    }
  }

  return function*() {
    const tasks = {}

    while (true) {
      const action = yield take(constants.LOAD)
      const module = action.meta[ACTION_META_MODULE]

      if (tasks[module]) yield cancel(tasks[module])

      tasks[module] = yield fork(inj.handlerEnhancer(loadHandler, inj), action)
    }
  }
}

class Pagination {
  saga: Saga
  reducer: Reducer
  constants: Constants
  actions: Actions
  selectors: Selectors

  constructor(module: string, root: string) {
    this.constants = createConstants(module)

    this.reducer = createReducer(this.constants)

    this.actions = createActions(this.constants)

    const rawSelectors = createSelectors(root)

    this.selectors = mapValues(rawSelectors, selector => selector())

    this.saga = createSaga(this.constants, this.actions, rawSelectors)
  }
}

export default Pagination
