// @flow

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import api from 'api'

import {fetchTeams} from 'store/admin/teams/actions'
import {START_FETCH_PLAYOFFS, FINISH_FETCH_PLAYOFFS} from './constants'
import type {Playoff} from './types'
import {getPlayoff} from './selectors'

export function startFetchChampionshipPlayOffData() {
  return {
    type: START_FETCH_PLAYOFFS,
  }
}

export function finishFetchPlayoffs(payload: Array<Playoff>) {
  return {
    type: FINISH_FETCH_PLAYOFFS,
    payload,
  }
}

export function fetchPlayoffs(championship: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchChampionshipPlayOffData())

    const {data, status} = await api.get('/admin/play-off/', {params: {championship}})

    if (status === 200) dispatch(finishFetchPlayoffs(data))
  }
}

export function deletePlayoff(playoffId: number) {
  return async (dispatch: Function, getState: Function) => {
    const {data, status} = await api.delete(`admin/play-off/${playoffId}/`)

    if (status !== 204) return data

    const championship = get(getPlayoff(getState(), {playoffId}), 'championship')
    championship && dispatch(fetchPlayoffs(championship))
  }
}

export function finishPlayoff(playoffId: number) {
  return async (dispatch: Function, getState: Function) => {
    const playoff = getPlayoff(getState(), {playoffId})

    if (playoff) {
      const {data, status} = await api.put(`/admin/play-off/${playoffId}/`, {
        team_1: playoff.team1,
        team_2: playoff.team2,
        isCompleted: true,
      })

      if (status !== 200) return data

      dispatch(fetchPlayoffs(playoff.championship))
    }
  }
}

export function lostPlayoffTeam(championship: number, teamId: number) {
  return async (dispatch: Function) => {
    const {status} = await api.get(`admin/team/lost/${teamId}/`)

    if (status === 200) {
      dispatch(fetchTeams({championship}))
    }
  }
}

export function createPlayoffPair(playoff: Object) {
  return async () => {
    // FIXME: there is backend validating emulation, need proper backend validation response
    const localErrors = ['team_1', 'team_2', 'playOffStage'].reduce(
      (errors, f) => (playoff[f] ? errors : {...errors, [f]: ['Обязательное поле']}),
      {},
    )

    if (!isEmpty(localErrors)) return localErrors

    const {data, status} = await api.post('admin/play-off/', playoff)

    if (status !== 201) return data
  }
}
