// @flow

import {takeEvery, takeLatest, put, call, select} from 'redux-saga/effects'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import values from 'lodash/values'
import first from 'lodash/first'
import type {Moment} from 'moment'
import moment from 'moment'

import api from 'api'
import {predictsToggleHandlerFactory} from 'store/lib/events'
import type {Koef, Predict, KoefsById} from 'store/lib/events'

import {openTeamUserDetailsError} from 'store/core/teams/actions'
import {LOAD_UPCOMING, TOGGLE_BET, LOAD_ACTUAL, LOAD_TEAM_MEMBER, LOAD_USER_DATA} from './constants'
import {
  startLoading,
  finishLoading,
  setMyPredictsForMatch,
  putMockPredict,
  removePredict,
  addData,
  removePredicts,
  placeBetError,
  reset,
  setCount,
} from './actions'
import type {
  _Match,
  _MyPredict,
  _UserMatch,
  _UserItem,
  MatchesState,
  MyPredictsState,
  Match,
  KoefsTypesState,
  ResultsState,
} from './types'
import {getMyPredict, getKoef, hasMoreMatches, getMatchesPages} from './selectors'
import * as errors from './errors'

const dateTimeToMomentDate = (date, time, offset): Moment =>
  moment.utc(`${date} ${time}`, 'DD-MM-YYYY HH:mm').utcOffset(offset)

const adaptMatch = offset => (rawMatch: _Match | _UserMatch): Match => {
  const momentDate = dateTimeToMomentDate(rawMatch.matcheDate, rawMatch.matcheTime, offset)
  // $FlowFixMe
  const {myPredicts, userPredicts, koefsList, ...match} = rawMatch

  return {
    ...match,
    matcheDate: momentDate,
    matcheTime: momentDate,
  }
}

const adaptPredict = offset => (rawMyPredict: _MyPredict): Predict => {
  const momentDate = dateTimeToMomentDate(rawMyPredict.predictDate, rawMyPredict.predictTime, offset)

  return {...rawMyPredict, predictDate: momentDate, predictTime: momentDate}
}

type Inject = {
  selectTimezoneOffset: Function,
  putError: Function,
  handlerEnhancer: Function,
  selectChampId: Function,
}

function* loadData(timezoneOffset, loadEffect, {fromStart}) {
  if (fromStart) yield put(reset())
  else if (!(yield select(hasMoreMatches))) return

  yield put(startLoading())

  const pages = yield select(getMatchesPages)
  const page = pages.length + 1
  const {
    data: {results: data, count},
  } = yield loadEffect(page)

  const rawMatchesDic: {[id: number]: _Match} = data.reduce((acc, match: _Match) => ({...acc, [match.id]: match}), {})

  const matches: MatchesState = {
    pages: [data.map(adaptMatch(timezoneOffset))],
    paths: data.reduce((acc, {id}, i) => ({...acc, [id]: `${page - 1}.${i}`}), {}),
  }

  const results: ResultsState = mapValues(rawMatchesDic, (match: _Match) => match.results)

  const koefsTypes: KoefsTypesState = mapValues(rawMatchesDic, (match: _Match) => {
    const groupedByType = groupBy(match.koefsList, 'koefTypes')

    return mapValues(groupedByType, (koefs: Array<Koef>) => koefs.map(({id}) => id))
  })

  const koefs: KoefsById = values(rawMatchesDic).reduce((acc, match: _Match) => {
    return {...acc, ...match.koefsList.reduce((acc, koef) => ({...acc, [koef.id]: koef}), {})}
  }, {})

  const myPredicts: MyPredictsState = mapValues(rawMatchesDic, (match: _Match) =>
    match.userPredicts.reduce(
      (acc, myPredict: _MyPredict) => ({...acc, [myPredict.koefsTypeId]: adaptPredict(timezoneOffset)(myPredict)}),
      {},
    ),
  )

  yield put(addData({results, matches, koefs, koefsTypes, myPredicts}))
  yield put(setCount(count))

  yield put(finishLoading())
}

function* loadUserData(timezoneOffset, loadEffect) {
  yield put(reset())
  yield put(startLoading())

  const {data}: {data: _UserItem} = yield loadEffect

  const rawMatchesDic: {[id: number]: _Match} = data.matchesList.reduce(
    (acc, match: _UserMatch) => ({...acc, [match.id]: match}),
    {},
  )

  const matches: MatchesState = {
    pages: [data.matchesList.map(adaptMatch(timezoneOffset))],
    paths: data.matchesList.reduce((acc, {id}, i) => ({...acc, [id]: `${0}.${i}`}), {}),
  }

  const results: ResultsState = mapValues(rawMatchesDic, (match: _UserMatch) => match.results)

  const koefsTypes: KoefsTypesState = mapValues(rawMatchesDic, (match: _UserMatch) => {
    const groupedByType = groupBy(match.koefsList, 'koefTypes')

    return mapValues(groupedByType, (koefs: Array<Koef>) => koefs.map(({id}) => id))
  })

  const koefs: KoefsById = values(rawMatchesDic).reduce((acc, match: _UserMatch) => {
    return {...acc, ...match.koefsList.reduce((acc, koef) => ({...acc, [koef.id]: koef}), {})}
  }, {})

  const myPredicts: MyPredictsState = mapValues(rawMatchesDic, (match: _UserMatch) =>
    match.userPredicts.reduce(
      (acc, myPredict: _MyPredict) => ({...acc, [myPredict.koefsTypeId]: adaptPredict(timezoneOffset)(myPredict)}),
      {},
    ),
  )

  yield put(addData({matches, koefsTypes, koefs, myPredicts, results}))
  yield put(setCount(data.matchesList.length))

  yield put(finishLoading())
}

function* loadMatchPredictions({selectTimezoneOffset, selectChampId}: Inject, {payload}) {
  const offset = yield select(selectTimezoneOffset)
  const champId = yield select(selectChampId)
  const {matchId, userId} = payload

  yield call(
    loadData,
    offset,
    () => call(api.get, `/championship/${champId}/matches/`, {params: {id: matchId, user: userId}}),
    {fromStart: true},
  )
}

function* loadUpcomingHandler({selectTimezoneOffset, selectChampId}: Inject, {payload}) {
  const offset = yield select(selectTimezoneOffset)
  const champId = yield select(selectChampId)

  yield call(
    loadData,
    offset,
    page => call(api.get, `/championship/${champId}/matches/`, {params: {status: 1, page}}),
    payload,
  )
}

function* loadActualHandler({selectTimezoneOffset, selectChampId}: Inject, {payload}) {
  const offset = yield select(selectTimezoneOffset)
  const champId = yield select(selectChampId)
  const params = {
    user: payload.userId,
    played_matches: payload.onlyPlayed || undefined,
    status: payload.includeStarted ? 4 : 2,
  }

  yield call(
    loadData,
    offset,
    page => call(api.get, `/championship/${champId}/matches/`, {params: {...params, page}}),
    payload,
  )
}

function* loadTeamMemberHandler({selectTimezoneOffset}: Inject, {payload: {teamId, userId}}) {
  const offset = yield select(selectTimezoneOffset)

  const userPredicts = yield call(api.get, `/teams/${teamId}/user/${userId}/predicts/`)

  if (userPredicts.status === 200) {
    yield call(loadUserData, offset, userPredicts)
  } else if (userPredicts.status === 400) {
    yield put(openTeamUserDetailsError(teamId, userId, first(userPredicts.data)))
  }
}

function* errorHandler({putError}: Inject, {type, payload: {message}}) {
  yield put(putError(type, message))
}

export default (inj: Inject) =>
  function*(): Generator<*, *, *> {
    yield takeEvery(values(errors), errorHandler, inj)

    yield takeLatest([LOAD_UPCOMING, LOAD_ACTUAL, LOAD_TEAM_MEMBER, LOAD_USER_DATA], function*(action) {
      const handlers = {
        [LOAD_UPCOMING]: loadUpcomingHandler,
        [LOAD_ACTUAL]: loadActualHandler,
        [LOAD_TEAM_MEMBER]: loadTeamMemberHandler,
        [LOAD_USER_DATA]: loadMatchPredictions,
      }

      yield call(inj.handlerEnhancer(handlers[action.type], inj), action)
    })

    yield takeEvery(
      TOGGLE_BET,
      inj.handlerEnhancer(
        predictsToggleHandlerFactory({
          putMockPredict,
          placeBetError,
          removePredict,
          setMyPredictsForMatch,
          getMyPredict,
          removePredicts,
          getKoef,
          placeRequest: function*({matchId, koefId}) {
            return yield call(api.post, '/place/bet/', {
              match: matchId,
              koefs: koefId,
            })
          },
          removeRequest: function*({predictId}) {
            return yield call(api.delete, `/remove/bet/${predictId}/`)
          },
          adaptPredict: function*(predict) {
            const timezoneOffset = yield select(inj.selectTimezoneOffset)

            return adaptPredict(timezoneOffset)(predict)
          },
        }),
      ),
    )
  }
