import {combineReducers} from 'redux'

import champs from './champs'
import stages from './stages'
import matches from './matches'
import koefs from './koefs'
import groups from './groups'
import teams from './teams'
import users from './users'
import moderators from './moderators'
import playoff from './playoff'
import predicts from './predicts'
import requests from './requests'
import notifications from './notifications'
import reports from './reports'
import rules from './rules'
import ui from './ui'
import rewards from './rewards'

export default combineReducers({
  champs,
  stages,
  matches,
  koefs,
  groups,
  teams,
  users,
  moderators,
  playoff,
  predicts,
  notifications,
  reports,
  requests,
  rules,
  ui,
  rewards,
})
