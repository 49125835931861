// @flow

import api from 'api'
import moment from 'moment'
import orderBy from 'lodash/orderBy'

import {
  START_FETCH_CHAMPIONSHIP_LIST,
  FINISH_FETCH_CHAMPIONSHIP_LIST,
  START_FETCH_CHAMPIONSHIP,
  FINISH_FETCH_CHAMPIONSHIP,
  START_SUBMIT_CHAMPIONSHIP,
  FINISH_SUBMIT_CHAMPIONSHIP,
} from './constants'
import type {Champ} from './types'

export function startFetchChampionshipList() {
  return {
    type: START_FETCH_CHAMPIONSHIP_LIST,
  }
}

export function finishFetchChampionshipList(payload: Array<Champ>) {
  return {
    type: FINISH_FETCH_CHAMPIONSHIP_LIST,
    payload,
  }
}

export function fetchChampionshipList(params?: {status: 0 | 1 | 2}) {
  return async (dispatch: Function) => {
    dispatch(startFetchChampionshipList())

    const {data} = await api.get('/admin/championships/', {params})
    const sortedDate = orderBy(data, o => new moment(o.date), ['desc'])

    dispatch(finishFetchChampionshipList(sortedDate))
  }
}

export function startFetchChampionship() {
  return {
    type: START_FETCH_CHAMPIONSHIP,
  }
}

export function finishFetchChampionship(payload: Champ) {
  return {
    type: FINISH_FETCH_CHAMPIONSHIP,
    payload,
  }
}

export function fetchChampionship(champId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchChampionship())

    const {data} = await api.get(`/admin/championships/${champId}/`)

    dispatch(finishFetchChampionship(data))
  }
}

export function startSubmitChampionship() {
  return {
    type: START_SUBMIT_CHAMPIONSHIP,
  }
}

export function finishSubmitChampionship(payload: Champ) {
  return {
    type: FINISH_SUBMIT_CHAMPIONSHIP,
    payload,
  }
}

export function createChampionship(champ: Champ) {
  return async (dispatch: Function) => {
    dispatch(startSubmitChampionship())

    const {data, status} = await api.post('/admin/championships/', champ)

    if (status === 200) {
      dispatch(finishSubmitChampionship(data))
      dispatch(fetchChampionshipList())
    } else {
      dispatch(finishSubmitChampionship(data))

      return data
    }
  }
}

export function editChampionship(champId: number, values: Object) {
  return async (dispatch: Function) => {
    dispatch(startSubmitChampionship())

    const {data, status} = await api.put(`/admin/championships/${champId}/`, values)

    if (status === 200) {
      dispatch(finishSubmitChampionship(data))
      dispatch(fetchChampionshipList())
    } else {
      dispatch(finishSubmitChampionship(data))

      return data
    }
  }
}

export function deleteChampionship(champId: number) {
  return async (dispatch: Function) => {
    const {status} = await api.delete(`/admin/championships/${champId}/`)

    if (status === 204) dispatch(fetchChampionshipList())
  }
}

export function closeChampionship(champId: number) {
  return async (dispatch: Function) => {
    await api.get(`/admin/championships/close/${champId}/`)

    dispatch(fetchChampionshipList())
  }
}

export function startPlayoff(champId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.get(`/admin/play-off/start/${champId}/`)

    if (status !== 200) return data

    dispatch(fetchChampionshipList())
  }
}
