// @flow

import {createSelector} from 'reselect'
import keys from 'lodash/keys'

const local = state => state.ui

const getChamp = state => local(state).champ

const getErrors = state => local(state).errors

export const getChampId = state => getChamp(state).id

export const getErrorsList = createSelector(
  getErrors,
  errors => keys(errors).map(k => errors[k]),
)

export const getViewport = state => local(state).viewport

export const isChatOpened = state => local(state).chat.opened

export const getFilter = (state: any, key: string = 'default') => local(state).filter[key]
