// @flow

import {takeLatest, select, call, put} from 'redux-saga/effects'
import last from 'lodash/last'

import api from 'api'

import {LOAD_RULES} from './constants'
import {finishLoadingRules, setRules, startLoadingRules} from './actions'

type Inject = {
  selectChampId: Function,
  handlerEnhancer: Function,
}

function* loadRulesHandler({selectChampId}: Inject) {
  try {
    const champ = yield select(selectChampId)

    yield put(startLoadingRules())

    const {data, status} = yield call(api.get, `/championship/${champ}/rule/`)

    if (status === 200) {
      const rule = last(data)

      yield put(setRules(rule && JSON.parse(rule.text)))
    }
  } finally {
    yield put(finishLoadingRules())
  }
}

export default (inj: Inject) =>
  function*(): any {
    yield takeLatest(LOAD_RULES, inj.handlerEnhancer(loadRulesHandler, inj))
  }
