// @flow

import {combineReducers} from 'redux'

import type {State, CollectionState} from './types'
import {START_FETCH_MODERATORS, FINISH_FETCH_MODERATORS} from './constants'

const initialState: State = {
  collection: {
    data: [],
    loading: false,
  },
}

const collection = (state: CollectionState = initialState.collection, {payload, type}) => {
  switch (type) {
    case START_FETCH_MODERATORS: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_MODERATORS: {
      return {...state, loading: false, data: payload.data || initialState.collection.data}
    }

    default:
      return state
  }
}

export default combineReducers({collection})
