export const LOGIN = 'batlle-py-frontend/core/profile/LOGIN'
export const LOGIN_FB = 'batlle-py-frontend/core/profile/LOGIN_FB'
export const START_LOGIN = 'batlle-py-frontend/core/profile/START_LOGIN'
export const FINISH_LOGIN = 'batlle-py-frontend/core/profile/FINISH_LOGIN'
export const LOGOUT = 'battle-py-frontend/core/profile/LOGOUT'
export const FINISH_USER_LOGOUT = 'battle-py-frontend/core/profile/FINISH_USER_LOGOUT'
export const USER_LOGOUT = 'battle-py-frontend/core/profile/USER_LOGOUT'
export const SET_USER = 'battle-py-frontend/core/profile/SET_USER'
export const SET_USER_OLIMP_LOGIN = 'battle-py-frontend/core/profile/SET_USER_OLIMP_LOGIN'
export const LOAD_INFO = 'battle-py-frontend/core/profile/LOAD_INFO'
export const START_LOAD_INFO = 'battle-py-frontend/core/profile/START_LOAD_INFO'
export const FINISH_LOAD_INFO = 'battle-py-frontend/core/profile/FINISH_LOAD_INFO'
export const RESET_AUTH_ERROR = 'battle-py-frontend/core/profile/RESET_AUTH_ERROR'
export const SET_INFO = 'battle-py-frontend/core/profile/SET_INFO'
export const LOAD_SCORES = 'battle-py-frontend/core/profile/LOAD_SCORES'
export const START_LOAD_SCORES = 'battle-py-frontend/core/profile/START_LOAD_SCORES'
export const FINISH_LOAD_SCORES = 'battle-py-frontend/core/profile/FINISH_LOAD_SCORES'
export const SET_SCORES_DATA = 'battle-py-frontend/core/profile/SET_SCORES_DATA'
export const UPDATE_OLIMP_LOGIN = 'battle-py-frontend/core/profile/UPDATE_OLIMP_LOGIN'
export const UPDATE_OLIMP_LOGIN_ERROR = 'battle-py-frontend/core/profile/UPDATE_OLIMP_LOGIN_ERROR'
