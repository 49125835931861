// @flow

import api from 'api'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import setWith from 'lodash/setWith'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import last from 'lodash/last'
import first from 'lodash/first'

import {getAllDeepValues} from 'helpers/object'

import {START_FETCH_KOEF, FINISH_FETCH_KOEF, START_FETCH_KOEFS, FINISH_FETCH_KOEFS} from './constants'
import type {Koef, KoefsForm, KoefListByType} from './types'
import {getKoef} from './selectors'

function startFetchKoef(koefId: number) {
  return {type: START_FETCH_KOEF, payload: {koefId}}
}

function finishFetchKoef(koefId: number, data?: Koef) {
  return {type: FINISH_FETCH_KOEF, payload: {koefId, data}}
}

export function fetchKoef(koefId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchKoef(koefId))

    const {data, status} = await api.get(`/admin/coefficients/${koefId}/`)

    if (status === 200) {
      dispatch(finishFetchKoef(koefId, data))
    } else {
      dispatch(finishFetchKoef(koefId))

      return data
    }
  }
}

function startFetchKoefs(matchId: number) {
  return {type: START_FETCH_KOEFS, payload: {matchId}}
}

function finishFetchKoefs(matchId: number, data?: KoefListByType) {
  return {type: FINISH_FETCH_KOEFS, payload: {matchId, data}}
}

export function fetchKoefs(matchId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchKoefs(matchId))

    const {data, status} = await api.get(`/admin/matches/${matchId}/coefficients/`)

    if (status === 200) {
      dispatch(finishFetchKoefs(matchId, groupBy(data, 'koefTypes')))
    } else {
      dispatch(finishFetchKoefs(matchId))

      return data
    }
  }
}

export function deleteKoef(matchId: number, koefId: number) {
  return async (dispatch: Function, getState: Function) => {
    const koef = getKoef(getState(), {koefId})
    const {data, status} = await api.delete(`/admin/coefficients/${koefId}/`)

    if (status === 204) {
      koef && dispatch(fetchKoefs(koef.match))
    } else {
      return data
    }
  }
}

export function editKoef(koefId: number, values: Koef) {
  return async () => {
    const {data, status} = await api.put(`/admin/coefficients/${koefId}/`, values)

    if (status !== 200) {
      return data
    }
  }
}

export function createKoef(matchId: number, values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post(`/admin/matches/${matchId}/coefficients/`, {match: matchId, ...values})

    if (status === 201) {
      dispatch(fetchKoefs(matchId))
    } else {
      return data
    }
  }
}

const adaptKoefs = (match, {basic, scores, yesNo, lessMore, handicaps}: KoefsForm, idsForValues?: Object) => {
  const mainList =
    basic &&
    keys(basic).map(k => {
      const _valueKey = `basic.${k}`

      return {match, _valueKey, id: get(idsForValues, _valueKey), koefTypes: k, value: basic[k]}
    })

  const scoresList =
    scores &&
    keys(scores).reduce(
      (acc, k) => [
        ...acc,
        ...keys(scores[k]).map(s => {
          const _valueKey = `scores.${k}.${s}`

          return {
            match,
            _valueKey,
            id: get(idsForValues, _valueKey),
            koefTypes: k,
            value: scores[k][s],
            score: s,
          }
        }),
      ],
      [],
    )

  const handicapsList =
    handicaps &&
    keys(handicaps).reduce(
      (acc, koefTypes) => [
        ...acc,
        ...keys(handicaps[koefTypes]).reduce(
          (acc, handicapType) => [
            ...acc,
            ...keys(handicaps[koefTypes][handicapType]).map(handicapCount => ({
              match,
              koefTypes,
              handicapType,
              handicapCount,
              _valueKey: `handicaps.${koefTypes}.${handicapType}.${handicapCount}`,
              id: get(idsForValues, `handicaps.${koefTypes}.${handicapType}`, {})[handicapCount],
              value: handicaps[koefTypes][handicapType][handicapCount],
            })),
          ],
          [],
        ),
      ],
      [],
    )

  const yesNoList =
    yesNo &&
    keys(yesNo)
      .map(k =>
        ['totalYes', 'totalNo'].map(field => {
          const _valueKey = `yesNo.${k}.${field}`

          return {
            match,
            _valueKey,
            id: get(idsForValues, _valueKey),
            koefTypes: k,
            [field]: true,
            value: yesNo[k][field],
          }
        }),
      )
      .reduce((acc, list) => [...acc, ...list], [])

  const lessMoreList =
    lessMore &&
    keys(lessMore)
      .map(k =>
        ['totalUnderKoef', 'totalEquallyKoef', 'totalOverKoef'].map(field => {
          const _valueKey = `lessMore.${k}.${field}`

          return {
            match,
            _valueKey,
            id: get(idsForValues, _valueKey),
            total: lessMore[k]['total'],
            koefTypes: k,
            [field]: true,
            value: lessMore[k][field],
            _totalKey: `lessMore.${k}.total`,
          }
        }),
      )
      .reduce((acc, list) => [...acc, ...list], [])

  return [mainList, scoresList, yesNoList, lessMoreList, handicapsList].reduce(
    (acc, list) => (list ? acc.concat(list) : acc),
    [],
  )
}

const adaptKoefsErrors = (adaptedKoefs, errors) =>
  errors.reduce((acc, error, i) => {
    if (!isEmpty(error)) {
      const koef = adaptedKoefs[i]

      error.value && setWith(acc, koef._valueKey, error.value, Object)
      error.total && setWith(acc, koef._totalKey, error.total, Object)
      error.nonFieldErrors && setWith(acc, 'nonFieldErrors', error.nonFieldErrors, Object)
    }

    return acc
  }, {})

export function syncKoefsForm(matchId: number, values: KoefsForm, idsForValues?: Object) {
  const isFieldsFilledDiff = (aKoef, bKoef, fields) =>
    fields.some(field => Boolean(aKoef[field]) !== Boolean(bKoef[field]))

  return async (dispatch: Function) => {
    const adaptedKoefs = adaptKoefs(matchId, values, idsForValues)
    const koefsPackages = adaptedKoefs.reduce(
      (acc: Array<*>, koef) => {
        const lastPackage = last(acc)
        const lastKoef = last(lastPackage)

        if (lastKoef && isFieldsFilledDiff(lastKoef, koef, ['id', 'value'])) return [...acc, [koef]]
        return [...acc.slice(0, acc.length - 1), [...lastPackage, koef]]
      },
      [[]],
    )

    const responses = (await Promise.all(
      koefsPackages.map(koefs => {
        const firstKoef = first(koefs)

        if (isEmpty(firstKoef.value) && firstKoef.id) {
          return Promise.all(koefs.map(({id}) => api.delete(`/admin/coefficients/${id}/`))).then(() => null)
        }

        const method = first(koefs).id ? api.put : api.post
        return method(`/admin/matches/${matchId}/list/coefficients/`, koefs)
      }),
    )).filter(Boolean)

    if (responses.some(({status}) => ![200, 201].includes(status))) {
      const errors = koefsPackages.reduce((acc, koefs, i) => {
        const data = responses[i].data

        return [...acc, ...(data.error ? data.errors : Array(koefs.length).fill({}))]
      }, [])

      return adaptKoefsErrors(adaptedKoefs, errors)
    } else {
      await fetchKoefs(matchId)(dispatch)
    }
  }
}

export function deleteKoefsForm(matchId: number, idsForValues: Object) {
  return async (dispatch: Function) => {
    const koefs = getAllDeepValues(idsForValues)

    await Promise.all(koefs.map(id => api.delete(`/admin/coefficients/${id}/`)))

    await fetchKoefs(matchId)(dispatch)
  }
}
