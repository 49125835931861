// @flow

import {
  SET_CHAMP_ID,
  PUT_ERROR,
  DEL_ERROR,
  RESET_ERRORS,
  DEL_ERRORS,
  SET_VIEWPORT,
  TOGGLE_CHAT,
  SET_FILTER,
  CLEAR_FILTER,
} from './constants'

export const setChampId = id => ({type: SET_CHAMP_ID, payload: id})

export const putError = (code, msg) => ({type: PUT_ERROR, payload: {code, msg}})

export const delError = code => ({type: DEL_ERROR, payload: code})

export const delErrors = codes => ({type: DEL_ERRORS, payload: codes})

export const resetErrors = () => ({type: RESET_ERRORS})

export const setViewport = payload => ({type: SET_VIEWPORT, payload})

export const toggleChat = opened => ({type: TOGGLE_CHAT, payload: {opened}})

export const setFilter = (payload: {key: string, filter: string}) => ({type: SET_FILTER, payload})

export const clearFilter = (payload: {key: string} = {key: 'default'}) => ({type: CLEAR_FILTER, payload})
