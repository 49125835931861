// @flow

import {fork} from 'redux-saga/effects'

import {createSaga} from 'store/lib/pagination'
import handlerEnhancer from 'store/lib/handler-enhancer'

import {constants, actions, selectors} from './index'

export default () => {
  return function*(): any {
    yield fork(createSaga(constants, actions, selectors)({handlerEnhancer}))
  }
}
