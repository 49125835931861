// @flow

import values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import map from 'lodash/map'
import {createSelector} from 'reselect'

import {spreadHandicapListByKoefType} from 'store/lib/events'

import type {State, CollectionState, SingleState, Koef, KoefListByType} from './types'

const local = (state: any): State => state.admin.koefs

const getCollection = (state: any): CollectionState => local(state).collection

const getSingle = (state: any): SingleState => local(state).single

export const getKoefs = (state: any): KoefListByType => getCollection(state).data

export const getKoefsList = (state: any): Array<Koef> =>
  values(getCollection(state).data).reduce((acc, items) => [...acc, ...items], [])

export const getKoefListsByKoefType = (state: any): KoefListByType => getCollection(state).data

export const getKoefIdsByKoefType = createSelector(
  getKoefListsByKoefType,
  (koefs: KoefListByType) => mapValues(koefs, koefList => map(koefList, 'id')),
)

export const getKoefsById = createSelector(
  getKoefListsByKoefType,
  (koefListsByKoefType): {[koefId: number]: Koef} =>
    values(koefListsByKoefType).reduce(
      (acc, koefsList) => ({...acc, ...koefsList.reduce((acc, koef) => ({...acc, [koef.id]: koef}), {})}),
      {},
    ),
)

export const isKoefsLoading = (state: any): boolean => getCollection(state).loading

export const getKoef = (state: any, {koefId}: {koefId: number}): ?Koef => getKoefsById(state)[koefId]

export const getSingleKoef = (state: any): Koef | null => getSingle(state).data

export const isSingleKoefLoading = (state: any): boolean => getSingle(state).loading

export const getHandicapListsByKoefType = createSelector(
  getKoefIdsByKoefType,
  getKoefsById,
  spreadHandicapListByKoefType,
)
