// @flow

import type {State} from './types'
import {SET_RULES, START_LOADING_RULES, FINISH_LOADING_RULES} from './constants'

const initalState = {
  data: null,
  loading: false,
}

export default (state: State = initalState, {payload, type}: any) => {
  switch (type) {
    case SET_RULES:
      return {...state, data: payload}

    case START_LOADING_RULES:
      return {...state, loading: true}

    case FINISH_LOADING_RULES:
      return {...state, loading: false}

    default:
      return state
  }
}
