export const START_FETCH_PREDICT = 'battle-py-frontend/admin/predicts/START_FETCH_PREDICT'
export const FINISH_FETCH_PREDICT = 'battle-py-frontend/admin/predicts/FINISH_FETCH_PREDICT'

export const START_FETCH_TOGGLE_PREDICTS = 'battle-py-frontend/admin/predicts/START_FETCH_TOGGLE_PREDICTS'
export const FINISH_FETCH_TOGGLE_PREDICTS = 'battle-py-frontend/admin/predicts/FINISH_FETCH_TOGGLE_PREDICTS'
export const TOGGLE_PREDICT = 'battle-py-frontend/admin/predicts/TOGGLE_PREDICT'
export const REMOVE_PREDICT = 'battle-py-frontend/admin/predicts/REMOVE_PREDICT'
export const PUT_MOCK_PREDICT = 'battle-py-frontend/admin/predicts/PUT_MOCK_PREDICT'
export const REMOVE_PREDICTS = 'battle-py-frontend/admin/predicts/REMOVE_PREDICTS'
export const SET_PREDICTS = 'battle-py-frontend/admin/predicts/SET_PREDICTS'
export const PLACE_PREDICT_ERROR = 'battle-py-frontend/admin/predicts/PLACE_PREDICT_ERROR'
