import {createStore, applyMiddleware, combineReducers} from 'redux'
import {createLogger} from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {createHashHistory} from 'history'
import {routerMiddleware as createRouterMiddleware} from 'connected-react-router'
import {composeWithDevTools} from 'redux-devtools-extension'

import locale from './locale'
import core from './core'
import router from './router'
import saga from './saga'
import ui from './ui'
import settings from './settings'
import form from './form'
import admin from './admin'
import pagination from './pagination'

export const history = createHashHistory()

const sagaMiddleware = createSagaMiddleware()
const routerMiddleware = createRouterMiddleware(history)
const store = createStore(
  combineReducers({locale, core, router: router(history), ui, settings, form, admin, pagination}),
  composeWithDevTools(applyMiddleware(sagaMiddleware, thunkMiddleware, createLogger({collapsed: true}), routerMiddleware)),
)

sagaMiddleware.run(saga)

export default store
