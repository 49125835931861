// @flow

import api from 'api'
import isNil from 'lodash/isNil'

import {
  START_FETCH_NOTIFICATIONS_LIST,
  FINISH_FETCH_NOTIFICATIONS_LIST,
  START_FETCH_NOTIFICATION,
  FINISH_FETCH_NOTIFICATION,
  START_SUBMIT_NOTIFICATIONS,
  FINISH_SUBMIT_NOTIFICATIONS,
} from './constants'
import type {Notification} from './types'

export function startSubmitNotification() {
  return {
    type: START_SUBMIT_NOTIFICATIONS,
  }
}

export function finishSubmitNotification(payload: Notification) {
  return {
    type: FINISH_SUBMIT_NOTIFICATIONS,
    payload,
  }
}

export function startFetchSingleNotification() {
  return {
    type: START_FETCH_NOTIFICATION,
  }
}

export function finishFetchSingleNotification(payload?: Notification) {
  return {
    type: FINISH_FETCH_NOTIFICATION,
    payload,
  }
}

export function startFetchNotifications() {
  return {
    type: START_FETCH_NOTIFICATIONS_LIST,
  }
}

export function finishFetchNotifications(payload?: Array<Notification>) {
  return {
    type: FINISH_FETCH_NOTIFICATIONS_LIST,
    payload,
  }
}

let lastFetchNotificationsParams = void 0
export function fetchNotifications(params?: {championship: number}) {
  return async (dispatch: Function) => {
    lastFetchNotificationsParams = params

    dispatch(startFetchNotifications())

    const {data} = await api.get(`/admin/notifications/`, {params})

    dispatch(finishFetchNotifications(data))
  }
}

export function fetchSingleNotification(notificationId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchSingleNotification())

    const {data} = await api.get(`admin/notifications/${notificationId}/`)

    dispatch(finishFetchSingleNotification(data))
  }
}

export function deleteNotification(notificationId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/notifications/${notificationId}/`)

    if (status === 204) {
      dispatch(fetchNotifications(lastFetchNotificationsParams))
    } else {
      return data
    }
  }
}

export function createNotification(values: Object) {
  return async (dispatch: Function) => {
    dispatch(startSubmitNotification())

    const {dateStart, dateEnd, ...other} = values

    const {data, status} = await api.post('/admin/notifications/', {
      ...other,
      dateStart: dateStart && dateStart.format('YYYY-MM-DD'),
      dateEnd: dateEnd && dateEnd.format('YYYY-MM-DD'),
    })

    if (status === 201) {
      dispatch(finishSubmitNotification(data))
      dispatch(fetchNotifications(lastFetchNotificationsParams))
    } else {
      return data
    }
  }
}

export function editNotification(notificationId: number, values: Object) {
  return async (dispatch: Function) => {
    dispatch(startSubmitNotification())
    const {dateStart, dateEnd, ...other} = values

    const {data, status} = await api.put(`/admin/notifications/${notificationId}/`, {
      ...other,
      dateStart: !isNil(dateStart)
        ? typeof dateStart === 'string'
          ? dateStart
          : dateStart.format('YYYY-MM-DD')
        : null,
      dateEnd: !isNil(dateEnd) ? (typeof dateEnd === 'string' ? dateEnd : dateEnd.format('YYYY-MM-DD')) : null,
    })

    if (status === 200) {
      dispatch(finishSubmitNotification(data))
      dispatch(fetchNotifications(lastFetchNotificationsParams))
    } else {
      return data
    }
  }
}
