// @flow

import {spawn, takeLatest, put, all} from 'redux-saga/effects'

import handlerEnhancer from 'store/lib/handler-enhancer'

import profile from './profile/saga'
import events from './events/saga'
import rating from './rating/saga'
import rewards from './rewards/saga'
import incoming from './incoming/saga'
import teams from './teams/saga'
import rules from './rules/saga'
import chat from './chat/saga'
import {JOIN_TEAM_SUCCESS, JOIN_TEAM_ERROR, CANCEL_TEAM_REQUEST_SUCCESS, LEAVE_TEAM_SUCCESS} from './teams/constants'
import {loadInfo} from './profile/actions'
import {getAuthToken, getMyId} from './profile/selectors'

function* mediator() {
  yield takeLatest([JOIN_TEAM_SUCCESS, JOIN_TEAM_ERROR, CANCEL_TEAM_REQUEST_SUCCESS, LEAVE_TEAM_SUCCESS], function*() {
    yield put(loadInfo())
  })
}

type Inject = {
  selectTimezoneOffset: Function,
  putError: Function,
  loadRewards: Function,
  selectChampId: Function,
  selectLocale: Function,
}

export default ({putError, loadRewards, selectTimezoneOffset, selectChampId, selectLocale}: Inject) =>
  function*(): any {
    yield all([
      spawn(profile({putError, handlerEnhancer})),
      spawn(events({selectTimezoneOffset, putError, handlerEnhancer, selectChampId})),
      spawn(rating({handlerEnhancer, selectChampId, selectMyId: getMyId, loadRewards})),
      spawn(incoming({selectTimezoneOffset, selectChampId, handlerEnhancer})),
      spawn(teams({putError, loadRewards, selectTimezoneOffset, handlerEnhancer, selectChampId, selectLocale})),
      spawn(rules({selectChampId, handlerEnhancer})),
      spawn(chat({selectToken: getAuthToken})),
      spawn(rewards),
      spawn(mediator),
    ])
  }
