// @flow

import {combineReducers} from 'redux'

import type {State} from './types'
import {START_FETCH_MATCHES, FINISH_FETCH_MATCHES, START_FETCH_MATCH, FINISH_FETCH_MATCH} from './constants'

const initialState: State = {
  collection: {
    data: [],
    loading: false,
  },
  single: {
    data: null,
    loading: false,
  },
}

const collection = (state = initialState.collection, {type, payload}: any) => {
  switch (type) {
    case START_FETCH_MATCHES: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_MATCHES: {
      return {...state, loading: false, data: payload}
    }

    default:
      return state
  }
}

const single = (state = initialState.single, {type, payload}: any) => {
  switch (type) {
    case START_FETCH_MATCH: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_MATCH: {
      return {...state, loading: false, data: payload.match}
    }

    default:
      return state
  }
}

export default combineReducers({collection, single})
