// @flow

import {combineReducers} from 'redux'

import {
  START_FETCH_USERS_RATING_REPORT,
  FINISH_FETCH_USERS_RATING_REPORT,
  START_FETCH_GROUPS_REPORT,
  FINISH_FETCH_GROUPS_REPORT,
  START_FETCH_STARS_REPORT,
  FINISH_FETCH_STARS_REPORT,
  START_FETCH_MATCHES_GROUPS_REPORT,
  FINISH_FETCH_MATCHES_GROUPS_REPORT,
  START_FETCH_MATCH_TEAMS_REPORT,
  FINISH_FETCH_MATCH_TEAMS_REPORT,
  START_FETCH_TOP250_WINNERS_REPORT,
  FINISH_FETCH_TOP250_WINNERS_REPORT,
  START_FETCH_TEAM_CONTRIBUTION_REPORT,
  FINISH_FETCH_TEAM_CONTRIBUTION_REPORT,
} from './constants'
import type {
  State,
  CollectionState,
  StarsCollectionState,
  MatchesTeamsCollectionState,
  MatchRatingCollectionState,
  WinnersTop250ReportState,
  TeamContributionReportState,
} from './types'

const initialState: State = {
  collection: {
    data: [],
    loading: false,
  },
  starsCollection: {
    data: {
      byId: {},
    },
    loading: false,
  },
  matchesTeamsCollection: {
    data: {
      champsById: {},
    },
    loading: false,
  },
  matchRatingCollection: {
    data: null,
    loading: false,
  },
  winnersTop250Collection: {
    data: {
      byId: {},
    },
    loading: false,
  },
  teamContributionCollection: {
    data: {
      byId: {},
    },
    loading: false,
  },
}

const collection = (state: CollectionState = initialState.collection, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_USERS_RATING_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_USERS_RATING_REPORT: {
      return {...state, data: payload || initialState.collection, loading: false}
    }

    case START_FETCH_GROUPS_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_GROUPS_REPORT: {
      return {...state, data: payload || initialState.collection, loading: false}
    }

    default:
      return state
  }
}

const starsCollection = (state: StarsCollectionState = initialState.starsCollection, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_STARS_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_STARS_REPORT: {
      return {...state, data: payload || initialState.starsCollection.data, loading: false}
    }

    default:
      return state
  }
}

const matchesTeamsCollection = (
  state: MatchesTeamsCollectionState = initialState.matchesTeamsCollection,
  {payload, type}: any,
) => {
  switch (type) {
    case START_FETCH_MATCHES_GROUPS_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_MATCHES_GROUPS_REPORT: {
      return {...state, data: payload || initialState.matchesTeamsCollection.data, loading: false}
    }

    default:
      return state
  }
}

const matchRatingCollection = (
  state: MatchRatingCollectionState = initialState.matchRatingCollection,
  {payload, type}: any,
) => {
  switch (type) {
    case START_FETCH_MATCH_TEAMS_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_MATCH_TEAMS_REPORT: {
      return {...state, data: payload || initialState.matchRatingCollection, loading: false}
    }

    default:
      return state
  }
}

const winnersTop250Collection = (
  state: WinnersTop250ReportState = initialState.winnersTop250Collection,
  {payload, type}: any,
) => {
  switch (type) {
    case START_FETCH_TOP250_WINNERS_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_TOP250_WINNERS_REPORT: {
      return {...state, data: payload || initialState.winnersTop250Collection.data, loading: false}
    }

    default:
      return state
  }
}

const teamContributionCollection = (
  state: TeamContributionReportState = initialState.teamContributionCollection,
  {payload, type}: any,
) => {
  switch (type) {
    case START_FETCH_TEAM_CONTRIBUTION_REPORT: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_TEAM_CONTRIBUTION_REPORT: {
      return {...state, data: payload || initialState.teamContributionCollection.data, loading: false}
    }

    default:
      return state
  }
}

export default combineReducers({
  collection,
  starsCollection,
  matchesTeamsCollection,
  matchRatingCollection,
  winnersTop250Collection,
  teamContributionCollection,
})
