import {takeLatest, takeEvery, select, put, all, fork, take} from 'redux-saga/effects'
import {replace} from 'connected-react-router'
import {matchPath} from 'react-router-dom'
import isNil from 'lodash/isNil'
import _last from 'lodash/last'

import {getUser} from 'store/core/profile/selectors'
import routes, {CHAMP_PARAM, PARAM_VAL_NONE, fillRoute} from 'store/routes'
import {fetchChampionshipList, fetchChampionship} from 'store/admin/champs/actions'
import {fetchPlayoffs} from 'store/admin/playoff/actions'
import {fetchStage, fetchStages} from 'store/admin/stages/actions'
import {fetchMatches, fetchMatch} from 'store/admin/matches/actions'
import {fetchKoefs, fetchKoef} from 'store/admin/koefs/actions'
import {fetchGroups, fetchGroup} from 'store/admin/groups/actions'
import {fetchTeam, fetchTeams, fetchTeamsWithRate} from 'store/admin/teams/actions'
import {fetchUsers, fetchUser} from 'store/admin/users/actions'
import {fetchModerators} from 'store/admin/moderators/actions'
import {fetchPredict, fetchTogglePredicts} from 'store/admin/predicts/actions'
import {FINISH_FETCH_PREDICT} from 'store/admin/predicts/constants'
import {getSinglePredict} from 'store/admin/predicts/selectors'
import {fetchNotifications, fetchSingleNotification} from 'store/admin/notifications/actions'
import {
  fetchUserRatingReport,
  fetchGroupReport,
  fetchStarsReport,
  fetchMatchesGroupsReport,
  fetchMatchTeamsReport,
  fetchTop250WinnersReport,
} from 'store/admin/reports/actions'
import {getChamp} from 'store/admin/ui/selectors'
import {setChamp} from 'store/admin/ui/actions'
import {SET_CHAMP} from 'store/admin/ui/constants'
import {fetchRule} from 'store/admin/rules/actions'
import {fetchReward, fetchRewards} from 'store/admin/rewards/actions'
import {FINISH_FETCH_STAGES} from 'store/admin/stages/constants'
import {getStagesListForChamp} from 'store/admin/stages/selectors'

import {LOCATION_CHANGE} from './constants'
import {checkOnceHitRoute, putLoading} from './utils'
import {resetLoadings, decrementLoadings, reloadRoute} from './actions'
import {getLocation} from './selectors'

function* reportsHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_REPORTS_USERS_RATING]) {
    yield putLoading(fetchUserRatingReport())
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_GROUPS_RATING]) {
    const championship = location.matchedRoutes[routes.ADMIN_REPORTS_GROUPS_RATING].params.champ
    yield putLoading(fetchStages(championship))
    yield take(FINISH_FETCH_STAGES)

    const stages = yield select(getStagesListForChamp, {champId: championship})
    const stage = _last(stages).id

    yield putLoading(fetchGroupReport({championship, stage}))
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_STARS]) {
    yield putLoading(fetchStarsReport())
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_STARS_STAR_CHAMP]) {
    yield putLoading(fetchStarsReport())
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_MATCHES_LIST]) {
    yield putLoading(fetchMatchesGroupsReport())
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_MATCH_TEAMS_RATING]) {
    const matchId = location.matchedRoutes[routes.ADMIN_REPORTS_MATCH_TEAMS_RATING].params.id
    yield putLoading(fetchMatchTeamsReport(matchId))
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_WINNERS_TOP250]) {
    yield putLoading(fetchTop250WinnersReport())
  }

  if (location.matchedRoutes[routes.ADMIN_REPORTS_TEAM_CONTRIBUTION]) {
    const championship = location.matchedRoutes[routes.ADMIN_REPORTS_TEAM_CONTRIBUTION].params.champ

    yield putLoading(fetchTeamsWithRate(championship))
  }
}

function* playoffHandler({payload}) {
  const {location} = payload

  if (
    location.matchedRoutes[routes.ADMIN_CHAMP_PLAYOFFS] &&
    location.matchedRoutes[routes.ADMIN_CHAMP_PLAYOFFS].isExact
  ) {
    const championship = location.matchedRoutes[routes.ADMIN_CHAMP_PLAYOFFS].params[CHAMP_PARAM]

    yield putLoading(fetchPlayoffs(championship))
    yield putLoading(fetchTeams({pageSize: Infinity, championship}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_PLAYOFFS_ADD]) {
    const championship = location.matchedRoutes[routes.ADMIN_CHAMP_PLAYOFFS_ADD].params[CHAMP_PARAM]

    yield putLoading(fetchTeams({pageSize: Infinity, championship, lost: false}))
    yield putLoading(fetchStages(championship))
  }
}

function* adminRootHandler({payload}) {
  if (checkOnceHitRoute(payload, routes.ADMIN)) {
    yield putLoading(fetchChampionshipList())
  }
}

function* championshipsHandler({payload}) {
  const {location} = payload

  if (checkOnceHitRoute(payload, routes.ADMIN_CHAMPIONSHIPS)) {
    yield putLoading(fetchChampionshipList())
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMPS_CHAMP_EDIT]) {
    yield putLoading(fetchChampionship(location.matchedRoutes[routes.ADMIN_CHAMPS_CHAMP_EDIT].params.id))
  }
}

function* notificationsHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_CHAMP_NOTIFICATIONS_LIST]) {
    const champ = location.matchedRoutes[routes.ADMIN_CHAMP_NOTIFICATIONS_LIST].params.champ

    yield putLoading(fetchNotifications({championship: champ}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_NOTIFICATIONS_ADD]) {
    const champ = location.matchedRoutes[routes.ADMIN_CHAMP_NOTIFICATIONS_ADD].params.champ

    yield putLoading(fetchNotifications({championship: champ}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_NOTIFICATIONS_EDIT]) {
    const notificationId = location.matchedRoutes[routes.ADMIN_CHAMP_NOTIFICATIONS_EDIT].params.id

    yield putLoading(fetchSingleNotification(notificationId))
  }
}

function* stagesHandler({payload}) {
  const {location} = payload

  if (checkOnceHitRoute(payload, routes.ADMIN_CHAMP_STAGES)) {
    yield putLoading(fetchChampionshipList())
    yield putLoading(fetchStages(location.matchedRoutes[routes.ADMIN_CHAMP_STAGES].params.champ))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_STAGES_STAGE_EDIT]) {
    yield putLoading(fetchChampionshipList({status: 1}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_STAGES_ADD]) {
    yield putLoading(fetchChampionshipList({status: 1}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_STAGES_STAGE_MATCHES]) {
    const stageId = location.matchedRoutes[routes.ADMIN_CHAMP_STAGES_STAGE_MATCHES].params.id

    yield all([putLoading(fetchMatches({stage: stageId})), putLoading(fetchStage(stageId))])
  }
}

function* matchesHandler({payload}) {
  const {location} = payload

  if (checkOnceHitRoute(payload, routes.ADMIN_CHAMP_MATCHES)) {
    const championship = location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES].params.champ

    yield all([
      putLoading(fetchChampionshipList()),
      putLoading(fetchStages(championship)),
      putLoading(fetchMatches({championship})),
    ])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_EDIT]) {
    yield putLoading(fetchMatch(location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_EDIT].params.id))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_KOEFS]) {
    const matchId = location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_KOEFS].params.id

    yield all([putLoading(fetchKoefs(matchId)), putLoading(fetchMatch(matchId))])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_CLOSE]) {
    yield putLoading(fetchMatch(location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_CLOSE].params.id))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_PREDICTS]) {
    const matchId = location.matchedRoutes[routes.ADMIN_CHAMP_MATCHES_MATCH_PREDICTS].params.id

    yield putLoading(fetchMatch(matchId))
  }
}

function* koefsHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_KOEFS_KOEF_EDIT]) {
    yield putLoading(fetchKoef(location.matchedRoutes[routes.ADMIN_KOEFS_KOEF_EDIT].params.id))
  }
}

function* groupsHandler({payload}) {
  const {location} = payload

  if (checkOnceHitRoute(payload, routes.ADMIN_CHAMP_GROUPS)) {
    const championship = location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS].params.champ

    yield putLoading(fetchGroups({championship}))
    yield putLoading(fetchChampionshipList())
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_GROUP_TEAMS]) {
    const groupId = location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_GROUP_TEAMS].params.id
    yield putLoading(fetchTeams({pageSize: Infinity, groupId}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_GROUP_EDIT]) {
    const championship = location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_GROUP_EDIT].params.champ

    yield all([
      putLoading(fetchStages(championship)),
      putLoading(fetchGroup(location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_GROUP_EDIT].params.id)),
      putLoading(fetchChampionshipList({status: 1})),
      putLoading(fetchTeams({pageSize: Infinity, championship})),
    ])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_ADD]) {
    const championship = location.matchedRoutes[routes.ADMIN_CHAMP_GROUPS_ADD].params.champ

    yield all([
      putLoading(fetchStages(championship)),
      putLoading(fetchChampionshipList({status: 1})),
      putLoading(fetchTeams({pageSize: Infinity, championship})),
    ])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_MODERATORS_ADD]) {
    yield putLoading(
      fetchUsers({
        team: location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_MODERATORS_ADD].params.id,
        pageSize: Infinity,
      }),
    )
  }
}

function* teamsHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_ADD]) {
    yield all([putLoading(fetchChampionshipList({status: 1}))])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_EDIT]) {
    const teamId = location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_EDIT].params.id

    yield all([
      putLoading(fetchChampionshipList({status: 1})),
      putLoading(fetchTeam(teamId)),
      putLoading(fetchUsers({pageSize: Infinity, team: teamId})),
    ])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_MODERATORS]) {
    const teamId = location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_MODERATORS].params.id

    yield all([putLoading(fetchModerators(teamId)), putLoading(fetchTeam(teamId))])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_REQUESTS]) {
    const teamId = location.matchedRoutes[routes.ADMIN_CHAMP_TEAMS_TEAM_REQUESTS].params.id

    yield putLoading(fetchTeam(teamId))
  }
}

function* usersHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_EDIT]) {
    const userId = location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_EDIT].params.id

    yield putLoading(fetchUser(userId))
  }

  if (
    location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_PREDICTS] &&
    location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_PREDICTS].isExact
  ) {
    const userId = location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_PREDICTS].params.id

    yield putLoading(fetchUser(userId))
  }

  if (checkOnceHitRoute(payload, routes.ADMIN_CHAMP_USERS_USER_PREDICTS_MATCH)) {
    yield putLoading(fetchMatches({status: 1}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_PREDICTS_MATCH]) {
    const {match, user} = location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_PREDICTS_MATCH].params

    yield putLoading(fetchUser(user))

    if (!isNil(match)) yield all([putLoading(fetchTogglePredicts({user, match})), putLoading(fetchKoefs(match))])
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_TEAMS]) {
    const {user} = location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_TEAMS].params

    yield putLoading(fetchUser(user))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_REWARDS]) {
    const {user} = location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_REWARDS].params

    yield putLoading(fetchUser(user))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_USERS_USER_REWARDS_ADD]) {
    yield putLoading(fetchRewards())
  }
}

function* predictsHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_CHAMP_PREDICTS_ADD]) {
    const {champ} = location.matchedRoutes[routes.ADMIN_CHAMP_PREDICTS_ADD].params

    yield putLoading(fetchMatches({championship: champ}))
  }

  if (location.matchedRoutes[routes.ADMIN_CHAMP_PREDICTS_PREDICT_EDIT]) {
    const {id: predictId, champ} = location.matchedRoutes[routes.ADMIN_CHAMP_PREDICTS_PREDICT_EDIT].params

    yield all([putLoading(fetchPredict(predictId)), putLoading(fetchMatches({championship: champ}))])

    yield takeLatest(FINISH_FETCH_PREDICT, function*() {
      const predict = yield select(getSinglePredict)

      if (predict) yield putLoading(fetchKoefs(predict.match))
    })
  }
}

function* rulesHandler({payload}) {
  const {location} = payload

  if (location.matchedRoutes[routes.ADMIN_CHAMP_RULES_LANG]) {
    const {champ, lang} = location.matchedRoutes[routes.ADMIN_CHAMP_RULES_LANG].params

    yield putLoading(fetchRule(champ, lang))
  }
}

function* rewardsHandler({payload}) {
  const {location} = payload

  if (checkOnceHitRoute(payload, routes.ADMIN_REWARDS)) {
    yield putLoading(fetchRewards())
  }

  if (location.matchedRoutes[routes.ADMIN_REWARDS_EDIT]) {
    const rewardId = location.matchedRoutes[routes.ADMIN_REWARDS_EDIT].params.id
    yield putLoading(fetchReward(rewardId))
  }
}

function* restrictRoutesHandler(action) {
  yield put(resetLoadings())

  const user = yield select(getUser)
  const {
    payload: {location},
  } = action
  const {pathname} = location

  if (user) {
    if (user.userIsSuperuser) {
      const currentChamp = yield select(getChamp)
      if (location.matchedRoutes[routes.ADMIN_CHAMP]) {
        const champ = location.matchedRoutes[routes.ADMIN_CHAMP].params[CHAMP_PARAM]

        if (champ !== currentChamp) yield put(setChamp(champ))
      } else if (pathname.startsWith(routes.ADMIN_CHAMP)) {
        return yield put(replace(fillRoute(pathname, {[CHAMP_PARAM]: currentChamp || PARAM_VAL_NONE})))
      }

      yield all(
        [
          adminRootHandler,
          rewardsHandler,
          championshipsHandler,
          notificationsHandler,
          reportsHandler,
          playoffHandler,
          stagesHandler,
          matchesHandler,
          koefsHandler,
          groupsHandler,
          teamsHandler,
          usersHandler,
          predictsHandler,
          rulesHandler,
        ].map(handler => fork(handler, action)),
      )
    } else {
      return yield put(replace(routes.ROOT))
    }
  } else if (!location.matchedRoutes[routes.ADMIN_LOGIN]) {
    return yield put(replace(routes.ADMIN_LOGIN))
  }

  yield put(decrementLoadings())
}

function* setChampHandler({payload: champ}) {
  const location = yield select(getLocation)

  if (location.matchedRoutes[routes.ADMIN_CHAMP]) {
    const newPrefix = fillRoute(routes.ADMIN_CHAMP, {champ: champ || PARAM_VAL_NONE})
    const match = matchPath(location.pathname, {path: routes.ADMIN_CHAMP})

    yield put(reloadRoute(location.pathname.replace(match.url, newPrefix)))
  }
}

export default function*() {
  yield takeLatest(LOCATION_CHANGE, restrictRoutesHandler)

  yield takeEvery(SET_CHAMP, setChampHandler)
}
