// @flow

import {combineReducers} from 'redux'

import type {State, CollectionState, SingleState} from './types'
import {START_FETCH_REWARD, FINISH_FETCH_REWARD, START_FETCH_REWARDS, FINISH_FETCH_REWARDS} from './constants'

const initialState: State = {
  collection: {
    data: [],
    loading: false,
  },
  single: {
    data: null,
    loading: false,
  },
}

const collection = (state: CollectionState = initialState.collection, {payload, type}) => {
  switch (type) {
    case START_FETCH_REWARDS: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_REWARDS: {
      return {...state, loading: false, data: payload.data || initialState.collection.data}
    }

    default:
      return state
  }
}

const single = (state: SingleState = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_REWARD: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_REWARD: {
      return {...state, loading: false, data: payload.data || initialState.single.data}
    }

    default:
      return state
  }
}

export default combineReducers({single, collection})
