// @flow

import {combineReducers} from 'redux'

import Pagination from 'store/lib/pagination'

import type {State, SingleState, ToggleState} from './types'
import {
  START_FETCH_PREDICT,
  FINISH_FETCH_PREDICT,
  REMOVE_PREDICTS,
  REMOVE_PREDICT,
  SET_PREDICTS,
  PUT_MOCK_PREDICT,
  START_FETCH_TOGGLE_PREDICTS,
  FINISH_FETCH_TOGGLE_PREDICTS,
} from './constants'

const initialState: State = {
  single: {
    data: null,
    loading: false,
  },
  toggle: {
    data: {},
    loading: false,
  },
}

const single = (state: SingleState = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_PREDICT: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_PREDICT: {
      return {...state, loading: false, data: payload.data || initialState.single.data}
    }

    default:
      return state
  }
}

const toggle = (state: ToggleState = initialState.toggle, {payload, type}) => {
  switch (type) {
    case START_FETCH_TOGGLE_PREDICTS: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_TOGGLE_PREDICTS: {
      return {...state, loading: false, data: payload.data || initialState.toggle.data}
    }

    case REMOVE_PREDICT: {
      const {[payload]: omitPredict, ...nextData} = state.data

      return {...state, data: nextData}
    }

    case REMOVE_PREDICTS: {
      return {
        ...state,
        data: payload.reduce((acc, koefType) => {
          const {[koefType]: omitPredict, ...nextAcc} = acc

          return nextAcc
        }, state.data),
      }
    }

    case SET_PREDICTS: {
      return {...state, data: payload}
    }

    case PUT_MOCK_PREDICT: {
      const {koefType, koefId} = payload

      return {...state, data: {...state.data, [koefType]: {koefs: koefId}}}
    }

    default:
      return state
  }
}

export const collection = new Pagination('battle-py-frontend/admin/predicts', 'admin.predicts.collection')

export default combineReducers({collection: collection.reducer, single, toggle})
