// @flow

import {createSelector} from 'reselect'
import moment from 'moment'

import type {State, Timezone} from './types'

const local = (state: any): State => state.settings

export const getTimezone = (state: any): Timezone | null => local(state).timezone

export const getTimezoneOffset = createSelector(
  getTimezone,
  (timezone: Timezone | null): number => (timezone ? timezone.offset : moment().utcOffset()),
)
