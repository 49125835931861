// @flow

import type {State} from './types'
import {ADD_REWARDS, FINISH_LOADING_REWARDS, START_LOADING_REWARDS} from './constants'

const initialState: State = {
  data: [],
  loading: false,
}

export default (state: State = initialState, {type, payload}) => {
  switch (type) {
    case START_LOADING_REWARDS: {
      return {...state, loading: true, data: []}
    }

    case FINISH_LOADING_REWARDS: {
      return {...state, loading: false}
    }

    case ADD_REWARDS: {
      return {...state, data: payload}
    }

    default:
      return state
  }
}
