// @flow

import {
  LOCATION_CHANGE,
  INCREMENT_LOADINGS,
  DECREMENT_LOADINGS,
  RESET_LOADINGS,
  RELOAD_ROUTE,
  REMEMBER_SCROLL,
  SET_SCROLL,
  REMOVE_SCROLL,
} from './constants'

export const locationChange = (state: Object) => ({type: LOCATION_CHANGE, payload: state})

export const incrementLoadings = () => ({type: INCREMENT_LOADINGS})
export const decrementLoadings = () => ({type: DECREMENT_LOADINGS})
export const resetLoadings = () => ({type: RESET_LOADINGS})

export const reloadRoute = (pathname: ?string) => ({type: RELOAD_ROUTE, payload: pathname})

export const rememberScroll = () => ({type: REMEMBER_SCROLL})
export const setScroll = (pathname: string, scroll: number) => ({
  type: SET_SCROLL,
  payload: {pathname, scroll},
})
export const removeScroll = (pathname: string) => ({type: REMOVE_SCROLL, payload: {pathname}})
