const askJoinTeam = {
  header: 'Would you like to join this team?',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
}

export default {
  menu: {
    rules: 'Rules',
    profile: 'Profile',
    myTeam: 'My team',
    completedMatches: 'Completed matches',
    upcoming: 'Upcoming matches',
    tournamentTable: 'Tournament table',
    rating: 'Rating',
    top10: 'TOP 10',
    old: 'Old version',
    chat: 'Team chat',
  },
  header: {
    rules: 'Rules',
    policy: 'Privacy Policy',
    terms: 'Terms & Conditions',
    incoming: 'Notifications',
    changeLangTitle: 'Please select your language',
    changeLangLabel: 'Change the language',
    chat: 'Team chat',
  },
  login: {
    header: 'Login',
    loginBtnText: 'Login with Facebook account',
  },
  userInfo: {
    welcome: 'Hello, {{name}}, your score is',
    team: 'Contribution to {{team}} team',
    rating: 'Your rank <strong>{{place}}</strong> of <strong>{{total}}</strong>',
  },
  bets: {
    additionalBetsTip: 'To view additional betting options, please make a bet on the main event',
    koefTypes: {
      1: 'Score',
      2: 'Score',
      3: 'Score',
      4: 'Score',
      5: 'Yellow cards',
      6: 'Corners',
      7: 'Total (Regular time)',
      8: 'Total (First half)',
      9: 'Both teams to score',
      10: '1',
      11: 'X',
      12: '2',
      14: 'First Goal in 1st Half',
      15: 'Goals - 1st Half vs 2nd',
      16: 'Last Goal',
      17: 'Total Match',
      18: 'Handicap',
      19: 'Goals in 2nd half',
      20: 'Winner of the 1st half',
      21: 'Winner of the 2nd half',
      22: 'Fouls',
      23: 'Offsides',
      24: 'Goals before Yellow Card?',
      25: 'Goal between 75 to 90 min?',
      26: 'Shots on goal',
      27: 'Goals in both halves',
      28: 'Team to score 1st goal',
      29: '1st goal in 2nd half',
    },
    unavailableToBet: 'The match has started!\nPredictions are no longer accepted.',
  },
  results: {
    myPredictionLabel: 'Your prediction',
    predictionLabel: 'Prediction',
    predictionNone: 'none',
    actualLabel: 'Actual',
  },
  handicap: {
    title: 'Add handicap?',
    noHandicap: 'No handicap',
  },
  profileCard: {
    matchesPlayed: 'Matches played',
    guessedExactScore: 'Predicted exact score',
    guessedMatchOutcome: 'Predicted match outcome',
  },
  tournamentSelect: {
    title: 'Please, choose\nfootball championship',
  },
  teams: {
    card: {
      memberCountLabel: 'Participants',
    },
  },
  screen: {
    back: 'Back',
    upcoming: {
      header: 'Upcoming matches',
      backToList: 'Back to upcoming matches list',
      toNextMatch: 'Next match',
      empty: 'There is no upcoming matches in this championship.\nPlease come back a little bit later.',
    },
    completed: {
      header: 'Completed matches',
      matchesTypeSelectTitle: 'Show all matches, or yours only?',
      onlyMyMatches: 'My matches only',
      allMatches: 'All matches',
      empty: 'There are no completed matches yet.\nPlease come back later.',
    },
    profile: {
      header: 'Profile',
      timezone: {
        selectLabel: 'Timezone',
        selectTitle: 'Select your local timezone',
      },
      language: {
        selectLabel: 'Language',
        selectTitle: 'Please select your language',
      },
      olimpId: {
        inputLabel: 'Olimp account ID',
        hint:
          '“Olimp ID Login” - is an ID number that is assigned to a user during registration on {{link}}. The use of the same Olimp ID Login by different participants is strictly prohibited!',
      },
      champs: {
        sectionLabel: 'Championships',
        totalLabel: 'Total',
      },
      timezones: {
        UM12: 'Baker/Howland Island',
        UM11: 'Niue',
        UM10: 'Hawaii-Aleutian Standard Time, Cook Islands, Tahiti',
        UM95: 'Marquesas Islands',
        UM9: 'Alaska Standard Time, Gambier Islands',
        UM8: 'Pacific Standard Time, Clipperton Island',
        UM7: 'Mountain Standard Time',
        UM6: 'Central Standard Time',
        UM5: 'Eastern Standard Time, Western Caribbean Standard Time',
        UM45: 'Venezuelan Standard Time',
        UM4: 'Atlantic Standard Time, Eastern Caribbean Standard Time',
        UM35: 'Newfoundland Standard Time',
        UM3: 'Argentina, Brazil, French Guiana, Uruguay',
        UM2: 'South Georgia/South Sandwich Islands',
        UM1: 'Azores, Cape Verde Islands',
        UTC: 'Greenwich Mean Time, Western European Time',
        UP1: 'Central European Time, West Africa Time',
        UP2: 'Central Africa Time, Eastern European Time, Kaliningrad Time',
        UP3: 'Moscow Time, East Africa Time, Arabia Standard Time',
        UP35: 'Iran Standard Time',
        UP4: 'Azerbaijan Standard Time, Samara Time',
        UP45: 'Afghanistan',
        UP5: 'Pakistan Standard Time, Yekaterinburg Time',
        UP55: 'Indian Standard Time, Sri Lanka Time',
        UP575: 'Nepal Time',
        UP6: 'Bangladesh Standard Time, Bhutan Time, Omsk Time',
        UP65: 'Cocos Islands, Myanmar',
        UP7: 'Krasnoyarsk Time, Cambodia, Laos, Thailand, Vietnam',
        UP8: 'Australian Western Standard Time, Beijing Time, Irkutsk Time',
        UP875: 'Australian Central Western Standard Time',
        UP9: 'Japan Standard Time, Korea Standard Time, Yakutsk Time',
        UP95: 'Australian Central Standard Time',
        UP10: 'Australian Eastern Standard Time, Vladivostok Time',
        UP105: 'Lord Howe Island',
        UP11: 'Srednekolymsk Time, Solomon Islands, Vanuatu',
        UP115: 'Norfolk Island',
        UP12: 'Fiji, Gilbert Islands, Kamchatka Time, New Zealand Standard Time',
        UP1275: 'Chatham Islands Standard Time',
        UP13: 'Samoa Time Zone, Phoenix Islands Time, Tonga',
        UP14: 'Line Islands',
      },
      olimpIdWarning:
        'Attention! \nMake sure the login you are trying to save is correct.  \n The login cannot be edited.',
      save: 'Save',
    },
    rating: {
      header: 'Rating',
      search: 'Search for users',
      teamSelect: 'Search by a team',
      teamReset: 'All users',
      backToList: 'Back to rating list',
    },
    tournament: {
      header: 'Tournament table',
      joinTeam: 'JOIN',
      joinTeamSuccess: 'Request sent',
      group: 'GROUP',
      stage: '',
      backToList: 'Back to Tournament table list',
      backToTeam: 'Back to Team details',
      askJoinTeam,
    },
    myTeam: {
      header: 'Your team',
      empty: 'Please select a team to join',
      askJoinTeam,
      joinTeamSuccess: 'Request sent',
      search: 'Search for teams',
      backToList: 'Back to Teams list',
      backToTeam: 'Back to Team details',
      approveUserTeamRequest: 'You have submitted your request to join {{groupName}}. Please await the approval!',
      askRequest: {
        header: 'Do you want to him/her to your team?',
        add: 'Add',
        deny: 'Deny',
        cancel: 'Cancel',
      },
      askUser: {
        removeHeader: 'Do you want to remove him/her from the team?',
        remove: 'Remove from the team',
        giveViceCaptain: 'Give Vice-Captain badge',
        giveViceCaptainHeader: 'Do you want to give him/her Vice-Captain badge?',
        removeViceCaptain: 'Remove Vice-Captain badge',
        removeViceCaptainHeader: 'Do you want to remove him/her Vice-Captain badge?',
        cancel: 'Cancel',
      },
      leave: 'Leave the team',
      askConfirmation: {
        yes: 'Yes',
        no: 'No',
        header: 'Are you sure you want to leave the team?',
      },
      askCancelRequest: {
        header: 'Are you sure you want to cancel the request?',
        yes: 'Yes',
        no: 'No',
      },
      leaveTeamSuccess: 'You left the team',
      cancelTeamRequest: 'Cancel the request',
      denyLeaveTeam: 'The tournament has started! Moving from one team to another during the tournament is not allowed!',
    },
    top: {
      header: 'Top 10',
      group: 'GROUP',
      stageSelectHeader: 'Please, choose \nchampionship tour',
      backToList: 'Back to List of Teams',
      empty: 'There are no completed matches yet.\nPlease come back a little bit later.',
      matchTop10UsersTitle: 'TOP-10 based on the match outcomes:',
      matchTop10PrevUsersTitle: 'TOP-10 points towards the team score:',
    },
    privacy: {
      header: 'Privacy Policy',
    },
    rules: {
      header: 'Rules',
    },
  },
  users: {
    card: {
      contribution: 'Percentage',
      topCount: 'In top',
      predicts: 'Predictions',
    },
  },
  notifications: {
    statusNew: 'new',
    incomingMsg: 'Incoming messages',
    request: 'has applied to join the team.',
    empty: 'No messages',
  },
  teamCommon: {
    captain: 'Captain',
    viceCaptain: 'Vice-captain',
    capitansSectionLabel: 'Captains',
    membersSectionLabel: 'Members',
    requestsSectionLabel: 'Requests',
    search: 'Search among teammates',
  },
  match: {
    matchCardHeader: 'Match №{{number}} - ',
  },
  chat: {
    typeMessage: 'Type message...',
    closeChat: 'Close the chat',
    logo: 'Logo',
    send: 'Send',
    membersCount: 'Participants',
  },
}
