// @flow

import {combineReducers} from 'redux'

import {START_FETCH_KOEF, FINISH_FETCH_KOEF, START_FETCH_KOEFS, FINISH_FETCH_KOEFS} from './constants'
import type {State} from './types'

const initialState: State = {
  collection: {
    data: {},
    loading: false,
  },
  single: {
    data: null,
    loading: false,
  },
}

const collection = (state = initialState.collection, {payload, type}) => {
  switch (type) {
    case START_FETCH_KOEFS: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_KOEFS: {
      return {...state, loading: false, data: payload.data || initialState.collection.data}
    }

    default:
      return state
  }
}

const single = (state = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_KOEF: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_KOEF: {
      return {...state, loading: false, data: payload.data || initialState.single.data}
    }

    default:
      return state
  }
}

export default combineReducers({collection, single})
