// @flow

import {ADD_REWARDS, FINISH_LOADING_REWARDS, LOAD_REWARDS, START_LOADING_REWARDS} from './constants'
import type {Reward} from './types'

export const loadRewards = (params?: Object) => ({type: LOAD_REWARDS, payload: params})

export const startLoadingRewards = () => ({type: START_LOADING_REWARDS})

export const finishLoadingRewards = () => ({type: FINISH_LOADING_REWARDS})

export const addRewards = (rewards: Array<Reward>) => ({type: ADD_REWARDS, payload: rewards})
