import {connect} from 'react-redux'
import memoize from 'fast-memoize'

import {getCurrentLocaleContent, getLocalesList, getCurrentLocale} from './selectors'
import {localeProvider} from './utils'

export default preKey => {
  const memoizedLocaleProvider = memoize(localeProvider)

  const mapStateToProps = state => ({
    l: memoizedLocaleProvider(getCurrentLocaleContent(state), preKey),
    locales: getLocalesList(state),
    locale: getCurrentLocale(state),
  })

  return Component => connect(mapStateToProps)(Component)
}
