// @flow

import {call, put, takeLatest} from 'redux-saga/effects'
import api from 'api'
import first from 'lodash/first'

import {LOAD_REWARDS} from './constants'
import {addRewards, finishLoadingRewards, startLoadingRewards} from './actions'

function* loadRewardsHandler({payload: params}) {
  yield put(startLoadingRewards())
  const {data, status} = yield call(api.get, '/rewards/', {params})

  if (status === 200) {
    yield put(addRewards(first(data.results).rewards))
  }
  yield put(finishLoadingRewards())
}

export default function* rewardsSaga() {
  yield takeLatest(LOAD_REWARDS, loadRewardsHandler)
}
