// @flow

import {combineReducers} from 'redux'

import {
  START_FETCH_NOTIFICATIONS_LIST,
  FINISH_FETCH_NOTIFICATIONS_LIST,
  START_FETCH_NOTIFICATION,
  FINISH_FETCH_NOTIFICATION,
} from './constants'
import type {State, SingleState, CollectionState} from './types'

const initialState: State = {
  collection: {
    data: [],
    loading: false,
  },
  single: {
    data: null,
    loading: false,
  },
}

const collection = (state: CollectionState = initialState.collection, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_NOTIFICATIONS_LIST: {
      return {...state, loading: true, data: initialState.collection.data}
    }

    case FINISH_FETCH_NOTIFICATIONS_LIST: {
      return {...state, data: payload, loading: false}
    }

    default:
      return state
  }
}

const single = (state: SingleState = initialState.single, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_NOTIFICATION: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_NOTIFICATION: {
      return {...state, data: payload, loading: false}
    }

    default:
      return state
  }
}

export default combineReducers({collection, single})
