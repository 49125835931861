// @flow

import {createSelector} from 'reselect'
import map from 'lodash/map'

import translations from 'translations'

import type {State, Locale} from './types'
import {localeProvider} from './utils'

const local = (state: any): State => state.locale

export const getCurrentLocale = (state: any): Locale => local(state).current

const getAvailableLocales = () => translations

export const getLocalesList = createSelector(getAvailableLocales, available =>
  map(available, ({code, name, enabled}) => ({code, name, enabled})).filter(lang => lang.enabled == true),
)

export const getCurrentLocaleContent = (state: any) => local(state).content

export const l = (state: any, {key, replacer}: {key: string, replacer: Object}) => {
  const content = getCurrentLocaleContent(state)

  return localeProvider(content)(key, replacer)
}
