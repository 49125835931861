// @flow

import api from 'api'

import {START_FETCH_MODERATORS, FINISH_FETCH_MODERATORS} from './constants'
import type {Moderator} from './types'

function startFetchModerators(teamId: number) {
  return {type: START_FETCH_MODERATORS, payload: {teamId}}
}

function finishFetchModerators(teamId: number, data?: Array<Moderator>) {
  return {type: FINISH_FETCH_MODERATORS, payload: {teamId, data}}
}

export function fetchModerators(teamId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchModerators(teamId))

    const {data, status} = await api.get(`/admin/teams/${teamId}/moderators/`)

    if (status === 200) {
      dispatch(finishFetchModerators(teamId, data))
    } else {
      dispatch(finishFetchModerators(teamId))

      return data
    }
  }
}

export function createModerator(teamId: number, values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post(`/admin/teams/${teamId}/moderators/`, {group: teamId, ...values})

    if (status === 201) {
      dispatch(fetchModerators(teamId))
    } else {
      return data
    }
  }
}
