// @flow

import {takeLatest, takeEvery, put, call, select} from 'redux-saga/effects'
import values from 'lodash/values'
import get from 'lodash/get'
import first from 'lodash/first'
import moment from 'moment'

import api from 'api'
import {putLoading} from 'store/router/utils'

import {
  LOAD_TOURNAMENT_DATA,
  LOAD_TEAM_DETAILS,
  JOIN_TEAM,
  LEAVE_TEAM,
  CANCEL_TEAM_REQUEST,
  LOAD_TEAMS,
  LOAD_TEAM_STAGES,
  APPROVE_TO_TEAM,
  DENY_TO_TEAM,
  REMOVE_FROM_TEAM,
  GIVE_VICE_CAPTAIN,
  REMOVE_VICE_CAPTAIN,
  APPROVE_TO_TEAM_SUCCESS,
  DENY_TO_TEAM_SUCCESS,
  REMOVE_FROM_TEAM_SUCCESS,
  GIVE_VICE_CAPTAIN_SUCCESS,
  REMOVE_VICE_CAPTAIN_SUCCESS,
  JOIN_TEAM_SUCCESS,
  LOAD_USERS,
  LOAD_STAGE_MATCHES,
  LOAD_USER,
} from './constants'
import {
  startTournamentLoading,
  finishTournamentLoading,
  setTournamentData,
  startTeamLoading,
  finishTeamLoading,
  setTeamDetails,
  startTeamsLoading,
  finishTeamsLoading,
  startTeamStagesLoading,
  finishTeamStagesLoading,
  setTeamStages,
  setTeamsData,
  joinTeamError,
  joinTeamSuccess,
  approveToTeamError,
  approveToTeamSuccess,
  denyToTeamError,
  denyToTeamSuccess,
  removeFromTeamError,
  removeFromTeamSuccess,
  giveViceCaptainError,
  giveViceCaptainSuccess,
  removeViceCaptainError,
  removeViceCaptainSuccess,
  loadTeamDetails,
  resetUsers,
  addUsers,
  setUsersCount,
  startLoadingUsers,
  finishLoadingUsers,
  loadUsers,
  resetStageMatches,
  startLoadingStageMatches,
  setStageMatchesCount,
  addStageMatches,
  finishLoadingStageMatches,
  startLoadingUser,
  setUser,
  finishLoadingUser,
  leaveTeamSuccess,
  leaveTeamError,
  cancelTeamRequestSuccess,
} from './actions'
import type {
  TournamentData,
  TeamDetails,
  TeamsById,
  TournamentDataType,
  TeamStagesData,
  StageMatch,
  UsersData,
  StageMatchesData,
} from './types'
import * as errors from './errors'
import {
  getTeamDetails,
  getTeamDetailsModerator,
  getUsersData,
  hasMoreStageMatches,
  getStageMatchesData,
} from './selectors'

type Inject = {
  putError: Function,
  selectTimezoneOffset: Function,
  loadRewards: Function,
  handlerEnhancer: Function,
  selectChampId: Function,
}

const adaptMatch = offset => ({matcheDate, matcheTime, ...other}): StageMatch => {
  const date = moment.utc(`${matcheDate} ${matcheTime}`, 'DD-MM-YYYY HH:mm').utcOffset(offset)

  return {...other, matcheDate: date, matcheTime: date}
}

function* loadTournamentTable({selectChampId}: Inject) {
  yield put(startTournamentLoading())

  const champId = yield select(selectChampId)
  const {data} = yield call(api.get, `/championship/${champId}/tournaments/`)

  if (data) {
    const tournamentData: TournamentData = {champGroups: data.championshipGroups, playoffGroups: data.playOff}

    yield put(setTournamentData(tournamentData))
  }

  yield put(finishTournamentLoading())
}

function* loadTournamentTop({selectChampId}: Inject) {
  yield put(startTournamentLoading())

  const champId = yield select(selectChampId)
  const {data} = yield call(api.get, `/championship/${champId}/top/`)

  if (data) {
    const tournamentData: TournamentData = {champGroups: data}

    yield put(setTournamentData(tournamentData))
  }

  yield put(finishTournamentLoading())
}

function* loadTournamentDataHandler(inj: Inject, {payload}: {payload: TournamentDataType}) {
  const handlers: {[type: TournamentDataType]: Function} = {
    table: loadTournamentTable,
    top: loadTournamentTop,
  }
  const handler = handlers[payload]

  if (handler) yield call(handler, inj)
}

function* loadTeamDetailsHandler({payload: teamId}) {
  yield put(startTeamLoading())

  const {data}: {data: TeamDetails} = yield call(api.get, `/teams/detail/${teamId}/`)

  if (data) {
    yield put(setTeamDetails({...data, moderators: data.moderators.sort(({captain}) => (captain ? -1 : 0))}))
  }

  yield put(finishTeamLoading())
}

function* joinTeamHandler({payload: {teamId, privateTeam}}) {
  const {data, status} = yield call(api.post, '/request/to/team/', {group: teamId})

  if (status === 201) {
    yield put(joinTeamSuccess(teamId))
    if (privateTeam === false) {
      yield putLoading(loadTeamDetails(teamId))
    }
  } else if (status === 400) {
    yield put(joinTeamError(teamId, first(data.group)))
  }
}

function* cancelTeamRequestHandler({payload: {requestId}}) {
  const {status} = yield call(api.delete, `/user/remove/request/team/${requestId}/`)

  if (status === 204) {
    yield put(cancelTeamRequestSuccess(requestId))
  }
}

function* leaveTeamHandler({payload: {teamId}}) {
  const {data, status} = yield call(api.get, `/leave/team/${teamId}/`)

  if (status === 200) {
    yield put(leaveTeamSuccess(teamId))
  } else if (status === 404) {
    yield put(leaveTeamError(teamId, data.message))
  }
}

function* approveToTeamHandler({payload: {requestId}}) {
  const {data, status} = yield call(api.get, `/request/approved/${requestId}/`)

  if (status === 200) {
    yield put(approveToTeamSuccess(requestId))
  } else if (status === 404) {
    yield put(approveToTeamError(requestId, data.message))
  }
}

function* denyToTeamHandler({payload: {requestId}}) {
  const {data, status} = yield call(api.delete, `/request/removed/${requestId}/`)

  if (status === 204) {
    yield put(denyToTeamSuccess(requestId))
  } else if (status === 404) {
    yield put(denyToTeamError(requestId, data.detail))
  }
}

function* removeFromTeamHandler({payload: {teamId, userId}}) {
  const {data, status} = yield call(api.get, `/teams/${teamId}/user/${userId}/removed/`)

  if (status === 200) {
    yield put(removeFromTeamSuccess(teamId, userId))
  } else if (status === 404) {
    yield put(removeFromTeamError(teamId, userId, data.message))
  }
}

function* giveViceCaptainHandler({payload: {teamId, userId}}) {
  const {data, status} = yield call(api.post, `/give/badge/`, {
    group: teamId,
    user: userId,
    viceCaptain: true,
  })

  if (status === 201) {
    yield put(giveViceCaptainSuccess(teamId, userId))
  } else if (status === 400) {
    yield put(giveViceCaptainError(teamId, userId, get(data, 'user.0') || get(data, 'group.0')))
  }
}

function* removeViceCaptainHandler({payload: {teamId, userId}}) {
  const moderator = yield select(state => getTeamDetailsModerator(state, {userId}))

  const {data, status} = yield call(api.delete, `/remove/badge/${moderator.id}/`)

  if (status === 204) {
    yield put(removeViceCaptainSuccess(teamId, userId))
  } else if (status === 404) {
    yield put(removeViceCaptainError(teamId, userId, data.detail))
  }
}

function* successManagementActionHandler() {
  const details: TeamDetails = yield select(getTeamDetails)

  if (details) {
    yield put(loadTeamDetails(details.id))
    yield put(loadUsers(details.id))
  }
}

function* loadTeamsHandler({selectChampId}: Inject) {
  yield put(startTeamsLoading())

  const champId = yield select(selectChampId)
  const {data} = yield call(api.get, `/championship/${champId}/teams/`)

  if (data) {
    const byId: TeamsById = data.reduce((acc, team) => ({...acc, [team.id]: team}), {})
    const ids: Array<number> = data.map(({id}) => id)

    yield put(setTeamsData({ids, byId}))
  }

  yield put(finishTeamsLoading())
}

function* loadTeamStagesHandler({payload: teamId}) {
  yield put(startTeamStagesLoading())

  const {data} = yield call(api.get, `/top/detail/${teamId}/`)

  if (data) {
    const stagesData: TeamStagesData = data.reduce(
      ({byId, ids}, stage) => ({byId: {...byId, [stage.id]: stage}, ids: ids.concat(stage.id)}),
      {
        byId: {},
        ids: [],
      },
    )

    yield put(setTeamStages(stagesData))
  }

  yield put(finishTeamStagesLoading())
}

function* loadStageMatchesHandler({selectTimezoneOffset}: Inject, {payload: {teamId, stageId, fromStart}}) {
  if (fromStart) yield put(resetStageMatches())
  else if (!(yield select(hasMoreStageMatches))) return

  yield put(startLoadingStageMatches())

  const matchesPages: StageMatchesData = yield select(getStageMatchesData)
  const page = matchesPages.length + 1
  const {data, status} = yield call(api.get, `/top/detail/${teamId}/${stageId}/`, {params: {page}})

  if (status === 200) {
    const offset = yield select(selectTimezoneOffset)
    const {results: matches, count} = data

    yield put(setStageMatchesCount(count))
    yield put(addStageMatches(matches.map(adaptMatch(offset))))
  }

  yield put(finishLoadingStageMatches())
}

function* eventHandler({putError}: Inject, {payload: {message}, type}) {
  yield put(putError(type, message))
}

function* loadUsersHandler({payload: {teamId, fromStart, search = ''}}) {
  if (fromStart) yield put(resetUsers())
  // else if (!(yield select(hasMoreUsers))) return

  yield put(startLoadingUsers())

  const usersData: UsersData = yield select(getUsersData)
  const page = fromStart ? 1 : usersData.pages.length + 1
  const {data} = yield call(api.get, `/teams/detail/${teamId}/user/list/`, {params: {page, full_name: search.trim()}})

  if (data) {
    const {results, count} = data

    yield put(setUsersCount(count))
    yield put(addUsers(results))
  }

  yield put(finishLoadingUsers())
}

function* loadUserHandler({loadRewards}: Inject, {payload: {userId, teamId, search = ''}}) {
  yield put(startLoadingUser())
  yield put(setUser(null))

  const params = {id: userId, full_name: search.trim()}
  const {data, status} = yield call(api.get, `/teams/detail/${teamId}/user/list/`, {params})

  if (status === 200) {
    yield put(loadRewards({id: userId}))
    yield put(setUser(first(data.results)))
  }

  yield put(finishLoadingUser())
}

export default (inj: Inject) =>
  function*(): any {
    yield takeLatest(LOAD_TOURNAMENT_DATA, inj.handlerEnhancer(loadTournamentDataHandler, inj))

    yield takeLatest(LOAD_TEAM_DETAILS, inj.handlerEnhancer(loadTeamDetailsHandler))

    yield takeLatest(LOAD_TEAMS, inj.handlerEnhancer(loadTeamsHandler, inj))

    yield takeLatest(LOAD_TEAM_STAGES, inj.handlerEnhancer(loadTeamStagesHandler))

    yield takeLatest(JOIN_TEAM, joinTeamHandler)

    yield takeLatest(CANCEL_TEAM_REQUEST, cancelTeamRequestHandler)

    yield takeLatest(LEAVE_TEAM, leaveTeamHandler)

    yield takeLatest(APPROVE_TO_TEAM, approveToTeamHandler)

    yield takeLatest(DENY_TO_TEAM, denyToTeamHandler)

    yield takeLatest(REMOVE_FROM_TEAM, removeFromTeamHandler)

    yield takeLatest(GIVE_VICE_CAPTAIN, giveViceCaptainHandler)

    yield takeLatest(REMOVE_VICE_CAPTAIN, removeViceCaptainHandler)

    yield takeLatest(LOAD_USERS, inj.handlerEnhancer(loadUsersHandler))

    yield takeLatest(LOAD_USER, inj.handlerEnhancer(loadUserHandler, inj))

    yield takeLatest(LOAD_STAGE_MATCHES, inj.handlerEnhancer(loadStageMatchesHandler, inj))

    yield takeLatest(
      [
        APPROVE_TO_TEAM_SUCCESS,
        DENY_TO_TEAM_SUCCESS,
        REMOVE_FROM_TEAM_SUCCESS,
        GIVE_VICE_CAPTAIN_SUCCESS,
        REMOVE_VICE_CAPTAIN_SUCCESS,
        JOIN_TEAM_SUCCESS,
      ],
      successManagementActionHandler,
    )

    yield takeEvery(values(errors), eventHandler, inj)
  }
