// @flow

import type {State} from './types'
import {UPDATE_SETTINGS} from './constants'

const initialState: State = {
  timezone: null,
}

export default (state: State = initialState, {payload, type}: any) => {
  switch (type) {
    case UPDATE_SETTINGS:
      return {...state, ...payload}

    default:
      return state
  }
}
