// @flow

import type {User} from './types'
import {
  ADD_USERS,
  LOAD_USERS,
  START_LOADING_USERS,
  FINISH_LOADING_USERS,
  SET_USERS_COUNT,
  RESET_USERS,
  LOAD_USER,
  START_LOADING_USER,
  FINISH_LOADING_USER,
  SET_USER, LOAD_MY_USER, SET_MY_USER,
} from './constants'

export const resetUsers = () => ({type: RESET_USERS})

export const addUsers = (payload: Array<User>) => ({type: ADD_USERS, payload})

export const setUsersCount = (payload: number) => ({type: SET_USERS_COUNT, payload})

export const loadUsers = (fromStart?: boolean = true, search?: {team?: string, name?: string}) => ({
  type: LOAD_USERS,
  payload: {fromStart, search},
})

export const startLoadingUsers = () => ({type: START_LOADING_USERS})

export const finishLoadingUsers = () => ({type: FINISH_LOADING_USERS})

export const loadUser = (userId: number, search?: {team?: number, name?: string}) => ({
  type: LOAD_USER,
  payload: {userId, search},
})

export const startLoadingUser = () => ({type: START_LOADING_USER})

export const finishLoadingUser = () => ({type: FINISH_LOADING_USER})

export const setUser = (payload: User | null) => ({type: SET_USER, payload})

export const loadMyUser = (search?: {team?: number, name?: string}) => ({type: LOAD_MY_USER, payload: {search}})

export const setMyUser = (payload: User | null) => ({type: SET_MY_USER, payload})
