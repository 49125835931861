import Axios from 'axios'
import camelcase from 'camelcase-keys-deep'
import deCamelcase from 'decamelize-keys-deep'
import {BASE_API_URL} from 'config'

const axios = Axios.create({
  baseURL: BASE_API_URL,
  transformRequest: [data => deCamelcase(data), ...Axios.defaults.transformRequest],
  transformResponse: [...Axios.defaults.transformResponse, data => camelcase(data)],
  validateStatus: status => status >= 200 && status <= 300,
})

let token = null
let lang = null
axios.interceptors.request.use(config => {
  if (token) config.headers = {...config.headers, Authorization: `token ${token}`, ['Accept-Language']: lang}

  config.params = deCamelcase(config.params)

  return config
})
axios.interceptors.response.use(
  res => res,
  error => {
    const {response} = error;

    if (response) {
      if (response.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/';
      } else if (!response.status) {
        return {...error, status: NETWORK_ERROR_STATUS}
      }
    }

    return Promise.reject(error)
  },
)

export default axios
export const setToken = val => (token = val)
export const setLang = val => (lang = val)
export const NETWORK_ERROR_STATUS = Symbol('Network error')
