// @flow

import {takeLatest, takeEvery, put, select, take, fork, cancel, all, call, join} from 'redux-saga/effects'
import {push, replace, LOCATION_CHANGE as BASE_LOCATION_CHANGE} from 'connected-react-router'
import first from 'lodash/first'
import {reset, getFormValues} from 'redux-form'

import {FINISH_LOGIN, FINISH_USER_LOGOUT} from 'store/core/profile/constants'
import routes, {fillRoute} from 'store/routes'
import {getMyId, getUser, getUserGroupIdByChamp, getUserInfoByChamp} from 'store/core/profile/selectors'
import {loadScores, loadInfo} from 'store/core/profile/actions'
import {loadUpcoming, loadActual, loadTeamMember, reset as resetData} from 'store/core/events/actions'
import {fetchNotification} from 'store/core/notification/actions'
import {getMatch, isMatchOnLastLoadedPage, hasMoreMatches} from 'store/core/events/selectors'
import {loadUser, loadMyUser, loadUsers} from 'store/core/rating/actions'
import {loadTeamRequests, messagesRead} from 'store/core/incoming/actions'
import {clearFilter} from 'store/ui/actions'
import {
  loadTournamentData,
  loadTeamDetails,
  loadTeams,
  loadTeamStages,
  loadStageMatches,
  loadUsers as loadTeamUsers,
  loadUser as loadTeamUser,
  resetStageMatches,
} from 'store/core/teams/actions'
import {REMOVE_FROM_TEAM_SUCCESS, LEAVE_TEAM_SUCCESS, OPEN_TEAM_USER_ERROR} from 'store/core/teams/constants'
import {getTeamStagesIds} from 'store/core/teams/selectors'
import {getChampId} from 'store/ui/selectors'

import {local, getLocation, getScroll} from './selectors'
import {LOCATION_CHANGE, RELOAD_ROUTE, REMEMBER_SCROLL} from './constants'
import {resetLoadings, decrementLoadings, locationChange, reloadRoute, setScroll, removeScroll} from './actions'
import admin from './admin'
import {putLoading, checkOnceHitRoute, setDocTitle} from './utils'
import {loadRules} from '../core/rules/actions'

const DOC_TITLE_ROUTES = [
  [routes.LOGIN, 'login.header'],
  [routes.PROFILE, 'screen.profile.header'],
  [routes.TEAMS, 'screen.myTeam.header'],
  [routes.COMPLETED, 'screen.completed.header'],
  [routes.UPCOMING, 'screen.upcoming.header'],
  [routes.TOURNAMENT, 'screen.tournament.header'],
  [routes.USER_RATING, 'screen.rating.header'],
  [routes.TOP, 'screen.top.header'],
  [routes.PRIVACY, 'screen.privacy.header'],
  [routes.RULES, 'screen.rules.header'],
]

function* finishLoginHandler({payload: {user, error}}) {
  if (!error) {
    yield put(user.userIsSuperuser ? reloadRoute(routes.ADMIN) : replace(routes.UPCOMING))
  }
}

function* finishLogoutHandler() {
  yield put(push(routes.LOGIN))
}

function* reloadRouteHandler({payload}) {
  const pathname = payload || (yield select(getLocation)).pathname

  yield put(replace(routes.RELOAD))

  yield put(replace(pathname))
}

function* restrictRoutesHandler(inj: Inject, action) {
  const {
    payload: {location},
  } = action
  const user = yield select(getUser)
  const {matchedRoutes} = location

  if (matchedRoutes[routes.RELOAD]) return

  if (!user && !matchedRoutes[routes.LOGIN]) {
    yield put(replace(routes.LOGIN))
  } else if (user && matchedRoutes[routes.LOGIN]) {
    yield put(replace(routes.UPCOMING))
  } else if (user) {
    const champId = yield select(inj.selectChampId)

    if (!champId) yield take(inj.SET_CHAMP_ID)

    const handlers = [
      upcomingEventsHandler,
      endedEventsHandler,
      profileHandler,
      usersRatingHandler,
      incomingHandler,
      tournamentHandler,
      myTeamHandler,
      topHandler,
      rulesHandler,
    ]

    let attachedHandlersCounter = 0
    const onHandlerAttached = function*() {
      if (++attachedHandlersCounter === handlers.length) yield put(decrementLoadings())
    }

    yield put(resetLoadings())
    yield all(handlers.map(handler => fork(handler, action, onHandlerAttached, inj)))
  }
}

function* docTitleHandler({l}: Inject, {payload: {location}}) {
  const {matchedRoutes} = location

  const match = DOC_TITLE_ROUTES.find(([path]) => matchedRoutes[path])

  if (match) {
    const key = match[1]
    const title = yield select(state => l(state, {key}))

    yield call(setDocTitle, title)
  }
}

function* updateUserInfoHandler({payload}) {
  const user = yield select(getUser)

  if (payload.prevLocation) {
    if (user) yield put(loadInfo())

    const {
      location: {matchedRoutes},
      prevLocation: {matchedRoutes: prevMatchedRoutes},
    } = payload

    if (
      !(
        (prevMatchedRoutes[routes.TOURNAMENT_TEAM_USER] && matchedRoutes[routes.TOURNAMENT_TEAM]) ||
        (prevMatchedRoutes[routes.TOURNAMENT_TEAM] && matchedRoutes[routes.TOURNAMENT_TEAM_USER])
      )
    ) {
      yield all(put(clearFilter()), put(clearFilter('TeamScreen')), put(clearFilter('GroupTeam')))
    }
  } else {
    yield putLoading(loadInfo())
  }
}

function* upcomingEventsHandler({payload: {location, prevLocation}}, attached) {
  const {matchedRoutes} = location
  let loadUpcomingTask = null

  if (checkOnceHitRoute({location, prevLocation}, routes.UPCOMING)) {
    const champId = yield select(getChampId)
    loadUpcomingTask = yield putLoading(loadUpcoming())

    yield putLoading(fetchNotification({champId}))
  }

  if (matchedRoutes[routes.UPCOMING_MATCH]) {
    const {id} = matchedRoutes[routes.UPCOMING_MATCH].params

    const matchChecker = function*(loadTask) {
      if (loadTask) yield join(loadTask)

      const match = yield select(state => getMatch(state, {matchId: id}))
      const hasMore = yield select(hasMoreMatches)

      if (match) {
        const onLastPage = yield select(state => isMatchOnLastLoadedPage(state, {matchId: match.id}))

        if (onLastPage && hasMore) yield put(loadUpcoming(false))
      } else {
        if (hasMore) {
          const task = yield putLoading(loadUpcoming(false))

          return yield call(matchChecker, task)
        } else {
          yield put(replace(routes.UPCOMING))
        }
      }
    }

    yield call(matchChecker, loadUpcomingTask)
  }

  yield call(attached)
}

function* endedEventsHandler({payload}, attached) {
  if (checkOnceHitRoute(payload, routes.COMPLETED)) {
    yield putLoading(loadActual({onlyPlayed: true}))
  }

  yield call(attached)
}

function* profileHandler({payload}, attached) {
  if (checkOnceHitRoute(payload, routes.PROFILE)) {
    yield put(loadScores())
  }

  yield call(attached)
}

function* usersRatingHandler({payload}, attached) {
  const {
    location: {matchedRoutes},
  } = payload

  if (checkOnceHitRoute(payload, routes.USER_RATING)) {
    const {filter} = (yield select(getFormValues('user-rating'))) || {}

    yield put(reset('user-rating'))
    yield putLoading(loadUsers())
    yield putLoading(loadTeams())
    yield putLoading(loadMyUser(filter))
  }

  if (matchedRoutes[routes.USER_RATING_USER]) {
    const userId = matchedRoutes[routes.USER_RATING_USER].params.id

    yield putLoading(loadActual({userId, includeStarted: true}))
    yield putLoading(loadUser(userId))
  }

  yield call(attached)
}

function* incomingHandler({payload: {location}}, attached) {
  const champId = yield select(getChampId)
  const loadTeamRequestsTask = yield putLoading(loadTeamRequests(), true)

  if (location.matchedRoutes[routes.NOTIFICATIONS]) {
    yield putLoading(fetchNotification({champId, markAsRead: true}), true)
    yield fork(function*() {
      yield join(loadTeamRequestsTask)
      yield put(messagesRead())
    })
  }

  yield call(attached)
}

function* tournamentHandler({payload}, attached) {
  const {
    location: {matchedRoutes},
  } = payload

  if (matchedRoutes[routes.TOURNAMENT] && matchedRoutes[routes.TOURNAMENT].isExact) {
    yield putLoading(loadTournamentData('table'))
  }

  if (matchedRoutes[routes.TOURNAMENT_TEAM]) {
    yield put(resetData())
  }

  if (checkOnceHitRoute(payload, routes.TOURNAMENT_TEAM)) {
    const teamId = Number(matchedRoutes[routes.TOURNAMENT_TEAM].params.id)
    const myId = yield select(getMyId)
    const champId = yield select(getChampId)
    const myTeamId = yield select(state => getUserGroupIdByChamp(state, {champId}))

    if (teamId === myTeamId) yield putLoading(loadTeamUser(teamId, myId))

    yield all([putLoading(loadTeamDetails(teamId)), putLoading(loadTeamUsers(teamId))])
  }

  if (matchedRoutes[routes.TOURNAMENT_TEAM_USER]) {
    const {userId, id: teamId} = matchedRoutes[routes.TOURNAMENT_TEAM_USER].params

    yield putLoading(loadActual({userId, includeStarted: true}))
    yield putLoading(loadTeamUser(teamId, userId))
  }

  yield call(attached)
}

function* myTeamHandler({payload}, attached, {selectChampId}: Inject) {
  const {
    location: {matchedRoutes},
  } = payload

  let champId = yield select(selectChampId)

  const userInfo = yield select(state => getUserInfoByChamp(state, {champId}))
  const team = userInfo.group

  if (checkOnceHitRoute(payload, routes.TEAMS)) {
    if (team) {
      yield putLoading(loadTeamDetails(team.id))
    } else {
      yield putLoading(loadTeams())
    }
  }

  if (checkOnceHitRoute(payload, routes.TEAMS_MY)) {
    if (team) {
      yield putLoading(loadTeamUsers(team.id))
    } else {
      yield put(replace(routes.TEAMS))
    }

    yield takeLatest(LEAVE_TEAM_SUCCESS, function*() {
      yield putLoading(loadTeams())
    })
  }

  if (matchedRoutes[routes.TEAMS_MY] && matchedRoutes[routes.TEAMS_MY].isExact && team) {
    const myId = yield select(getMyId)

    yield putLoading(loadTeamUser(team.id, myId))
  }

  if (matchedRoutes[routes.TEAMS_MY_MEMBER]) {
    const userId = Number(matchedRoutes[routes.TEAMS_MY_MEMBER].params.id)

    yield put(loadTeamMember(team.id, userId))
    yield putLoading(loadTeamUser(team.id, userId))

    yield fork(function*() {
      const {
        payload: {userId: removedUserId},
      } = yield take(REMOVE_FROM_TEAM_SUCCESS)

      if (removedUserId === userId) yield put(replace(routes.TEAMS_MY))
    })

    yield fork(function*() {
      yield take(OPEN_TEAM_USER_ERROR)
      yield putLoading(loadTeamUsers(team.id))
    })
  }

  yield call(attached)
}

function* topHandler({payload}, attached) {
  const {
    location: {matchedRoutes},
  } = payload

  if (matchedRoutes[routes.TOP] && matchedRoutes[routes.TOP].isExact) {
    yield putLoading(loadTournamentData('top'))
  }

  if (checkOnceHitRoute(payload, routes.TOP_TEAM)) {
    const {team} = matchedRoutes[routes.TOP_TEAM].params

    const loadTeamStagesTask = yield putLoading(loadTeamStages(team), false)

    if (matchedRoutes[routes.TOP_TEAM].isExact) {
      yield join(loadTeamStagesTask)

      const stages = yield select(getTeamStagesIds)

      yield put(resetStageMatches())

      if (stages.length > 0) {
        yield put(replace(fillRoute(routes.TOP_TEAM_STAGE, {team, stage: first(stages)})))
      }
    }
  }

  if (matchedRoutes[routes.TOP_TEAM_STAGE]) {
    const {team, stage} = matchedRoutes[routes.TOP_TEAM_STAGE].params

    yield putLoading(loadStageMatches(team, stage))
  }

  yield call(attached)
}

function* rulesHandler({payload}, attached) {
  const {location} = payload

  if (location.matchedRoutes[routes.RULES]) {
    yield putLoading(loadRules())
  }

  yield call(attached)
}

function* scrollRestorationHandler({payload: {action, location}}) {
  const {pathname, state = {}} = location
  const {isBack} = state
  const scroll = yield select(state => getScroll(state, {pathname}))

  if (action === 'PUSH' && !isBack) yield call(window.scrollTo, 0, 0)
  else if (scroll) yield call(setTimeout, () => window.scrollTo(0, scroll))

  if (scroll) yield put(removeScroll(pathname))
}

function* rememberScrollHandler() {
  const location = yield select(getLocation)

  yield put(setScroll(location.pathname, window.scrollY))
}

function* setChampIdHandler() {
  yield put(reloadRoute())
}

type Inject = {
  selectChampId: Function,
  SET_CHAMP_ID: string,
  l: Function,
}

export default (inj: Inject) => {
  let adminSaga = null
  let userSaga = null

  return function*(): any {
    yield takeLatest(FINISH_USER_LOGOUT, finishLogoutHandler)

    yield takeLatest(FINISH_LOGIN, finishLoginHandler)

    yield takeEvery(RELOAD_ROUTE, reloadRouteHandler)

    yield takeEvery(BASE_LOCATION_CHANGE, scrollRestorationHandler)

    yield takeEvery(REMEMBER_SCROLL, rememberScrollHandler)

    yield takeEvery(BASE_LOCATION_CHANGE, function*() {
      const state = yield select(local)

      if (state.location.matchedRoutes[routes.ADMIN]) {
        if (!adminSaga) adminSaga = yield fork(admin, inj)

        if (userSaga) {
          yield cancel(userSaga)

          userSaga = null
        }
      } else {
        if (!userSaga) {
          userSaga = yield fork(function*() {
            yield takeLatest(LOCATION_CHANGE, restrictRoutesHandler, inj)
            yield takeLatest(LOCATION_CHANGE, docTitleHandler, inj)

            // skip the first time champ setting
            yield take(inj.SET_CHAMP_ID)
            yield takeEvery(inj.SET_CHAMP_ID, setChampIdHandler)
            yield takeLatest(LOCATION_CHANGE, updateUserInfoHandler)
          }, inj)
        }

        if (adminSaga) {
          yield cancel(adminSaga)

          adminSaga = null
        }
      }

      yield put(locationChange(state))
    })
  }
}
