import React, {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom'
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux'
import {Switch, Route} from 'react-router-dom'

import Viewport from 'components/Viewport'
import routes from 'store/routes'
import store, {history} from 'store'

import registerServiceWorker from './registerServiceWorker'

import 'theme/index.css'

const Admin = lazy(() => import('components/admin/routes'))
const App = lazy(() => import('components/App'))
const DApp = lazy(() => import('components/DApp'))

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense fallback={null}>
        <Switch>
          <Route path={routes.ADMIN} component={Admin} />

          <Viewport sm={App} lg={DApp} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)
registerServiceWorker()
