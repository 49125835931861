// @flow

import {takeEvery, call, fork} from 'redux-saga/effects'

import {predictsToggleHandlerFactory} from 'store/lib/events'
import api from 'api'

import {collection} from './index'
import {TOGGLE_PREDICT} from './constants'
import {placePredictError, putMockPredict, removePredict, removePredicts, setPredicts} from './actions'
import {getPredictByKoefType} from './selectors'
import {adaptPredict} from './utils'

type Inject = {
  getKoef: Function,
  handlerEnhancer: Function,
}

export default ({getKoef, handlerEnhancer}: Inject) => {
  return function*(): Generator<*, *, *> {
    yield takeEvery(
      TOGGLE_PREDICT,
      predictsToggleHandlerFactory({
        getKoef,
        putMockPredict: (matchId, koefType, koefId) => putMockPredict(koefType, koefId),
        removePredict: (matchId, koefType) => removePredict(koefType),
        getMyPredict: getPredictByKoefType,
        setMyPredictsForMatch: (matchId, predicts) => setPredicts(predicts),
        removePredicts: (matchId, koefTypes) => removePredicts(koefTypes),
        adaptPredict: predict => predict,
        placeRequest: function*({matchId, koefId}, {payload: {userId}}) {
          return yield call(api.post, '/place/bet/', {
            user: userId,
            match: matchId,
            koefs: koefId,
          })
        },
        removeRequest: function*({predictId}) {
          yield call(api.delete, `/admin/predicts/${predictId}/`)
        },
        placeBetError: placePredictError,
      }),
    )

    yield fork(
      collection.saga({
        handlerEnhancer,
        makeReq: ({user, match}) => ({url: '/admin/predicts/', params: {user, match}}),
        adaptItem: adaptPredict,
      }),
    )
  }
}
