export const RESET_USERS = 'battle-py-frontend/core/rating/RESET_USERS'
export const ADD_USERS = 'battle-py-frontend/core/rating/ADD_USERS'
export const SET_USERS_COUNT = 'battle-py-frontend/core/rating/SET_USERS_COUNT'
export const LOAD_USERS = 'battle-py-frontend/core/rating/LOAD_USERS'
export const START_LOADING_USERS = 'battle-py-frontend/core/rating/START_LOADING_USERS'
export const FINISH_LOADING_USERS = 'battle-py-frontend/core/rating/FINISH_LOADING_USERS'

export const LOAD_USER = 'battle-py-frontend/core/rating/LOAD_USER'
export const START_LOADING_USER = 'battle-py-frontend/core/rating/START_LOADING_USER'
export const FINISH_LOADING_USER = 'battle-py-frontend/core/rating/FINISH_LOADING_USER'
export const SET_USER = 'battle-py-frontend/core/rating/SET_USER'

export const LOAD_MY_USER = 'battle-py-frontend/core/rating/LOAD_MY_USER'
export const SET_MY_USER = 'battle-py-frontend/core/rating/SET_MY_USER'
