// @flow

import api from 'api'

import {
  START_FETCH_USERS_RATING_REPORT,
  FINISH_FETCH_USERS_RATING_REPORT,
  START_FETCH_GROUPS_REPORT,
  FINISH_FETCH_GROUPS_REPORT,
  START_FETCH_STARS_REPORT,
  FINISH_FETCH_STARS_REPORT,
  START_FETCH_MATCHES_GROUPS_REPORT,
  FINISH_FETCH_MATCHES_GROUPS_REPORT,
  START_FETCH_MATCH_TEAMS_REPORT,
  FINISH_FETCH_MATCH_TEAMS_REPORT,
  START_FETCH_TOP250_WINNERS_REPORT,
  FINISH_FETCH_TOP250_WINNERS_REPORT,
  START_FETCH_TEAM_CONTRIBUTION_REPORT,
  FINISH_FETCH_TEAM_CONTRIBUTION_REPORT,
} from './constants'
import type {
  UsersRating,
  GroupsRating,
  StarsReport,
  MatchTeamsReport,
  ChampMatchDetails,
  WinnersTop250Report,
  TeamContributionReport,
} from './types'

export function startFetchUsersRatingReport() {
  return {
    type: START_FETCH_USERS_RATING_REPORT,
  }
}

export function finishFetchUsersRatingReport(payload: Array<UsersRating> | null) {
  return {
    type: FINISH_FETCH_USERS_RATING_REPORT,
    payload,
  }
}

export function fetchUserRatingReport() {
  return async (dispatch: Function) => {
    dispatch(startFetchUsersRatingReport())

    const {data, status} = await api.get('admin/table/info/users/')

    if (status === 200) dispatch(finishFetchUsersRatingReport(data))
    else dispatch(finishFetchUsersRatingReport(null))
  }
}

export function startFetchGroupsReport() {
  return {
    type: START_FETCH_GROUPS_REPORT,
  }
}

export function finishGroupsReport(payload: Array<GroupsRating> | null) {
  return {
    type: FINISH_FETCH_GROUPS_REPORT,
    payload,
  }
}

export function fetchGroupReport(params?: {championship?: number, stage?: number}) {
  return async (dispatch: Function) => {
    dispatch(startFetchGroupsReport())

    const {data, status} = await api.get('admin/table/info/group/', {params})

    if (status === 200) dispatch(finishGroupsReport(data))
    else dispatch(finishGroupsReport(null))
  }
}

export function startFetchStarsReport() {
  return {
    type: START_FETCH_STARS_REPORT,
  }
}

export function finishFetchStarsReport(payload: StarsReport | null) {
  return {
    type: FINISH_FETCH_STARS_REPORT,
    payload,
  }
}

export function startFetchTop250WinnersReport() {
  return {
    type: START_FETCH_TOP250_WINNERS_REPORT,
  }
}

export function finishFetchTop250WinnersReport(payload: WinnersTop250Report | null) {
  return {
    type: FINISH_FETCH_TOP250_WINNERS_REPORT,
    payload,
  }
}

export function fetchTop250WinnersReport() {
  return async (dispatch: Function) => {
    dispatch(startFetchTop250WinnersReport())

    const {data, status} = await api.get('admin/table/info/users/')
    const byId = data.reduce((acc, champ) => ({...acc, [champ.id]: champ}), {})

    if (status === 200) dispatch(finishFetchTop250WinnersReport({byId}))
    else dispatch(finishFetchTop250WinnersReport(null))
  }
}

export function startFetchTeamContributionReport() {
  return {
    type: START_FETCH_TEAM_CONTRIBUTION_REPORT,
  }
}

export function finishFetchTeamContributionReport(payload: TeamContributionReport | null) {
  return {
    type: FINISH_FETCH_TEAM_CONTRIBUTION_REPORT,
    payload,
  }
}

export function fetchTeamContributionReport() {
  return async (dispatch: Function) => {
    dispatch(startFetchTeamContributionReport())

    const {data, status} = await api.get('admin/table/info/group/users/')
    const byId = data.reduce((acc, champ) => ({...acc, [champ.id]: champ}), {})

    if (status === 200) dispatch(finishFetchTeamContributionReport({byId}))
    else dispatch(finishFetchTeamContributionReport(null))
  }
}

export function fetchStarsReport() {
  return async (dispatch: Function) => {
    dispatch(startFetchStarsReport())

    const {data, status} = await api.get('admin/table/info/stars/')
    const filteredStarsReport = data.filter(champ => champ.users.length > 0)
    const byId = filteredStarsReport.reduce((acc, champ) => ({...acc, [champ.id]: champ}), {})

    if (status === 200) dispatch(finishFetchStarsReport({byId}))
    else dispatch(finishFetchStarsReport(null))
  }
}

export function startFetchMatchesGroupsReport() {
  return {
    type: START_FETCH_MATCHES_GROUPS_REPORT,
  }
}

export function finishFetchMatchesGroupsReport(payload: MatchTeamsReport | null) {
  return {
    type: FINISH_FETCH_MATCHES_GROUPS_REPORT,
    payload,
  }
}

export function fetchMatchesGroupsReport() {
  return async (dispatch: Function) => {
    dispatch(startFetchMatchesGroupsReport())

    const {data, status} = await api.get('admin/table/info/')
    const champsById = data.reduce((acc, champ) => ({...acc, [champ.id]: champ}), {})

    if (status === 200) dispatch(finishFetchMatchesGroupsReport({champsById}))
    else dispatch(finishFetchMatchesGroupsReport(null))
  }
}

export function startFetchMatchTeamsReport() {
  return {
    type: START_FETCH_MATCH_TEAMS_REPORT,
  }
}

export function finishFetchMatchTeamsReport(payload: ChampMatchDetails | null) {
  return {
    type: FINISH_FETCH_MATCH_TEAMS_REPORT,
    payload,
  }
}

export function fetchMatchTeamsReport(matchId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchMatchTeamsReport())

    const {data, status} = await api.get(`/admin/table/match/${matchId}/`)
    if (status === 200) dispatch(finishFetchMatchTeamsReport(data))
    else dispatch(finishFetchMatchTeamsReport(null))
  }
}
