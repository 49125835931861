// @flow

import api from 'api'

import {START_FETCH_GROUP, FINISH_FETCH_GROUP, START_FETCH_GROUPS, FINISH_FETCH_GROUPS} from './constants'
import type {Group} from './types'

function startFetchGroup(groupId: number) {
  return {type: START_FETCH_GROUP, payload: {groupId}}
}

function finishFetchGroup(groupId: number, data?: Group) {
  return {type: FINISH_FETCH_GROUP, payload: {groupId, data}}
}

export function fetchGroup(groupId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchGroup(groupId))

    const {data, status} = await api.get(`/admin/groups/${groupId}/`)

    if (status === 200) {
      dispatch(finishFetchGroup(groupId, data))
    } else {
      dispatch(finishFetchGroup(groupId))

      return data
    }
  }
}

function startFetchGroups() {
  return {type: START_FETCH_GROUPS}
}

function finishFetchGroups(data?: Array<Group>) {
  return {type: FINISH_FETCH_GROUPS, payload: {data}}
}

let lastFetchGroupsParams = void 0
export function fetchGroups(params?: {championship: number, status: 0 | 1 | 2}) {
  return async (dispatch: Function) => {
    lastFetchGroupsParams = params

    dispatch(startFetchGroups())

    const {data, status} = await api.get('/admin/groups/', {params})

    if (status === 200) {
      dispatch(finishFetchGroups(data))
    } else {
      dispatch(finishFetchGroups())

      return data
    }
  }
}

export function deleteGroup(groupId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/groups/${groupId}/`)

    if (status === 204) {
      dispatch(fetchGroups(lastFetchGroupsParams))
    } else {
      return data
    }
  }
}

export function editGroup(groupId: number, values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/groups/${groupId}/`, values)

    if (status === 200) {
      dispatch(fetchGroups(lastFetchGroupsParams))
    } else {
      return data
    }
  }
}

export function createGroup(values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/groups/', values)

    if (status === 201) {
      dispatch(fetchGroups(lastFetchGroupsParams))
    } else {
      return data
    }
  }
}
