import get from 'lodash/get'
import keys from 'lodash/keys'

export const localeProvider = (content, preKey) => (key, replacer) => {
  let line = null

  if (preKey) {
    if (Array.isArray(preKey)) {
      for (let pk of preKey) {
        line = get(content, `${pk}.${key}`)

        if (line) break
      }
    } else {
      line = get(content, `${preKey}.${key}`)
    }
  } else {
    line = get(content, key)
  }

  line = line || ''

  return replacer ? keys(replacer).reduce((acc, key) => acc.replace(`{{${key}}}`, replacer[key]), line) : line
}
