import {put, fork} from 'redux-saga/effects'
import mapValues from 'lodash/mapValues'

import {CHAMP_PARAM, PARAM_VAL_NONE} from 'store/routes'

import {incrementLoadings, decrementLoadings} from './actions'

export const checkOnceHitRoute = ({location, prevLocation}, route) => {
  const {matchedRoutes} = location
  const {matchedRoutes: prevMatchedRoutes} = prevLocation || {}

  if (matchedRoutes[route]) {
    return (
      !prevMatchedRoutes ||
      !prevMatchedRoutes[route] ||
      matchedRoutes[route].params[CHAMP_PARAM] !== prevMatchedRoutes[route].params[CHAMP_PARAM]
    )
  } else {
    return false
  }
}

export const checkLeaveRoute = ({location, prevLocation}, route) => {
  const {matchedRoutes} = location

  return !matchedRoutes[route] && prevLocation && prevLocation.matchedRoutes[route]
}

export const putLoading = (action, silence = false) => {
  return fork(function*() {
    let resolve
    let reject
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })

    if (!silence) {
      yield put(incrementLoadings())

      yield fork(function*() {
        yield promise

        yield put(decrementLoadings())
      })
    }

    if (typeof action === 'function') {
      yield put(async (...args) => {
        const errors = await action(...args)

        if (errors) reject(errors)
        else resolve()
      })
    } else {
      yield put({...action, meta: {...action.meta, resolve, reject}})
    }

    yield promise
  })
}

export const setDocTitle = title => (window.document.title = title)

export const adaptParams = params => mapValues(params, val => (val === PARAM_VAL_NONE ? null : val))
