// @flow

import api from 'api'
import type {Reward} from './types'
import {FINISH_FETCH_REWARD, FINISH_FETCH_REWARDS, START_FETCH_REWARD, START_FETCH_REWARDS} from './constants'

function startFetchReward(rewardId: number) {
  return {type: START_FETCH_REWARD, payload: {rewardId}}
}

function finishFetchReward(rewardId: number, data?: Reward) {
  return {type: FINISH_FETCH_REWARD, payload: {rewardId, data}}
}

export function fetchReward(rewardId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchReward(rewardId))

    const {data, status} = await api.get(`/admin/rewards/${rewardId}/`)

    if (status === 200) {
      dispatch(finishFetchReward(rewardId, data))
    } else {
      dispatch(finishFetchReward(rewardId))

      return data
    }
  }
}

function startFetchRewards() {
  return {type: START_FETCH_REWARDS}
}

function finishFetchRewards(data?: Array<Reward>) {
  return {type: FINISH_FETCH_REWARDS, payload: {data}}
}

export function fetchRewards() {
  return async (dispatch: Function) => {
    dispatch(startFetchRewards())
    const {data, status} = await api.get('/admin/rewards/')

    if (status === 200) {
      dispatch(finishFetchRewards(data))
    } else {
      dispatch(finishFetchRewards())
      return data
    }
  }
}

export function fetchRewardsForUser(params?: {championship?: number, user?: number}) {
  return async (dispatch: Function) => {
    dispatch(startFetchRewards())
    const {data, status} = await api.get('/admin/rewards/users/', {params})

    if (status === 200) {
      dispatch(finishFetchRewards(data))
    } else {
      dispatch(finishFetchRewards())
      return data
    }
  }
}

export function deleteReward(rewardId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/rewards/${rewardId}/`)

    if (status === 204) {
      dispatch(fetchRewards())
    } else {
      return data
    }
  }
}

export function editReward(rewardId: number, values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/rewards/${rewardId}/`, values)

    if (status === 200) {
      dispatch(fetchRewards())
    } else {
      return data
    }
  }
}

export function createReward(values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/rewards/', values)

    if (status === 201) {
      dispatch(fetchRewards())
    } else {
      return data
    }
  }
}

export function createUserReward(values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/rewards/users/', values)

    if (status === 201) {
      dispatch(fetchRewardsForUser({user: values.user, championship: values.championship}))
    } else {
      return data
    }
  }
}

export function deleteUserReward(userRewardId: number, user: number, championship: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/rewards/users/${userRewardId}/`)

    if (status === 204) {
      dispatch(fetchRewardsForUser({user, championship}))
    } else {
      return data
    }
  }
}
