// @flow

import {call, cancel, fork, join, put, select} from 'redux-saga/effects'
import values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import type {Moment} from 'moment'

import {NETWORK_ERROR_STATUS} from 'api'

const arrToDic = arr => arr.map(type => `${type}`).reduce((acc, type) => ({...acc, [type]: type}), {})

export const MAIN_KOEFS = {
  FIRST: 10,
  DRAW: 11,
  SECOND: 12,
}

export const SUB_MAIN_KOEFS = {
  FIRST: 1,
  DRAW: 2,
  SECOND: 3,
  OTHER_SCORE: 4,
}

export const ADDITIONAL_KOEFS = {
  YELLOW_CARDS: 5,
  CORNERS: 6,
  TOTAL_SCORE: 7,
  TOTAL_FIRST_HALF: 8,
  BOTH: 9,
  FIRST_GOAL_FIRST_HALF: 14,
  GOALS_FIRST_SECOND_HALF: 15,
  LAST_GOAL: 16,
  TOTAL_MATCH: 17,
  GOALS_SECOND_HALF: 19,
  WINNER_FIRST_HALF: 20,
  WINNER_SECOND_HALF: 21,
  TOTAL_FOULS: 22,
  TOTAL_OFFSIDES: 23,
  GOAL_BEFORE_YELLOW_CARD: 24,
  GOAL_FROM_75_TO_90: 25,
  TOTAL_SHOTS_AT_GOAL: 26,
  GOALS_BOTH_HALF: 27,
  FIRST_GOAL: 28,
  FIRST_GOAL_SECOND_HALF: 29,
}

export const HANDICAP_KOEF = 18

export const MAIN_KOEF_HANDICAP_RELATIONSHIP = {
  [MAIN_KOEFS.FIRST]: 1,
  [MAIN_KOEFS.SECOND]: 2,
}

export const KOEF_TYPES_ARR = {
  MAIN: values(MAIN_KOEFS),
  SUB_MAIN: values(SUB_MAIN_KOEFS),
  ADDITIONAL: values(ADDITIONAL_KOEFS),
  HANDICAP: [HANDICAP_KOEF],
}

export const KOEF_TYPES_DIC = mapValues(KOEF_TYPES_ARR, arrToDic)

export type Koef = {
  id: number,
  koefTitle: string,
  match: number,
  score: null | string,
  total: null | string,
  value: number,
  koefTypes: number,
  getKoefTypesDisplay: string,
  handicapType: 0 | 1 | 2,
  handicapCount: number,
  win: boolean,
  totalUnderKoef: boolean,
  totalEquallyKoef: boolean,
  totalOverKoef: boolean,
  totalYes: boolean,
  totalNo: boolean,
}

export type HandicapListsByKoefType = {
  [koefType: number]: Array<Koef> | null,
}

export type Status = 0 | 1 | 2

export type Predict = {
  id: number,
  match: number,
  koefs: number,
  koefsTypeId: number,
  koefsIdType: number,
  koefsType: string,
  koefsValue: number,
  koefsTitle: string,
  koefsScore: string | null,
  user: number,
  userFullName: string,
  status: Status,
  getStatusDisplay: string,
  predictDate: Moment,
  predictTime: Moment,
  matchDate: Moment,
  matchTime: Moment,
}

export type MockPredict = {koefs: number}

export type KoefListsByKoefType = {
  [$PropertyType<Koef, 'koefTypes'>]: Array<Koef>,
}

export type KoefsById = {
  [$PropertyType<Koef, 'id'>]: Koef,
}

export type PredictsByKoefType = {
  [$PropertyType<Koef, 'koefTypes'>]: Predict | MockPredict,
}

const DEPENDED_TO_MAIN_KOEF_TYPES_ARR = [
  ...KOEF_TYPES_ARR.MAIN,
  ...KOEF_TYPES_ARR.HANDICAP,
  SUB_MAIN_KOEFS.FIRST,
  SUB_MAIN_KOEFS.DRAW,
  SUB_MAIN_KOEFS.SECOND,
]
const DEPENDED_TO_SCORE_KOEF_TYPES_ARR = KOEF_TYPES_ARR.SUB_MAIN

class ToggleTasks {
  tasks = {}

  put = (matchId: number, koefType: number, task: any) => {
    this.tasks = {
      ...this.tasks,
      [ToggleTasks._genKey(matchId, koefType)]: task,
    }

    return this.tasks
  }

  get = (matchId: number, koefType: number) => {
    return this.tasks[ToggleTasks._genKey(matchId, koefType)]
  }

  getList = (matchId: number, koefTypes: Array<number>) => {
    const tasks = koefTypes.reduce((acc, koefType) => {
      const task = this.get(matchId, koefType)

      return task ? [...acc, task] : acc
    }, [])

    return tasks.length > 0 ? tasks : null
  }

  remove = (matchId: number, koefType: number) => {
    const task = this.get(matchId, koefType)

    if (task) {
      const {[ToggleTasks._genKey(matchId, koefType)]: removedTask, ...restTasks} = this.tasks

      this.tasks = restTasks
    }

    return this.tasks
  }

  removeList = (matchId: number, koefTypes: Array<number>) => {
    return koefTypes.reduce((_, type) => this.remove(matchId, type), null)
  }

  static _genKey(matchId: number, koefType: number) {
    return `${matchId}.${koefType}`
  }
}

export const predictsToggleHandlerFactory = (options: {
  putMockPredict: (matchId: number, koefTypes: number, koefId: number) => Object,
  placeBetError: (koefId: number, message: string) => Object,
  removePredict: (matchId: number, koefType: number) => Object,
  setMyPredictsForMatch: (matchId: number, predicts: Object) => Object,
  getMyPredict: (state: Object, options: {matchId: number, koefType: number}) => ?Object,
  removePredicts: (matchId: number, types: Array<number>) => Object,
  getKoef: (state: Object, options: {koefId: number}) => Object,
  adaptPredict: (predict: Object) => Object,
  placeRequest: (
    options: {matchId: number, koefId: number},
    action: Object,
  ) => Generator<*, {data: any, status: number}, *>,
  removeRequest: (options: {predictId: number}, action: Object) => any,
}) => {
  const {
    putMockPredict,
    placeBetError,
    removePredict,
    setMyPredictsForMatch,
    getMyPredict,
    removePredicts,
    getKoef,
    adaptPredict,
    placeRequest,
    removeRequest,
  } = options
  const tasks = new ToggleTasks()

  function* placeBetSaga({match: matchId, koefTypes, id: koefId}, _, action) {
    yield put(putMockPredict(matchId, koefTypes, koefId))

    const {data, status} = yield call(placeRequest, {matchId, koefId}, action)

    if (status !== 201) {
      if (status === 400) yield put(placeBetError(koefId, data.message))
      else if (status === NETWORK_ERROR_STATUS) yield put(placeBetError(koefId, 'Network error'))

      return yield put(removePredict(matchId, koefTypes))
    }

    let myPredicts = {}
    for (let predict of data) {
      const adaptedPredict = yield call(adaptPredict, predict)

      myPredicts = {...myPredicts, [predict.koefsTypeId]: adaptedPredict}
    }

    yield put(setMyPredictsForMatch(matchId, myPredicts))
  }

  function* removeBetSaga({match: matchId, koefTypes: koefType}, placeBetTask, action) {
    if (placeBetTask) {
      yield put(removePredict(matchId, koefType))
      yield join(placeBetTask)
    }

    const predict = yield select(state => getMyPredict(state, {matchId, koefType}))

    if (predict) {
      yield put(removePredict(matchId, koefType))
      yield call(removeRequest, {predictId: predict.id}, action)
    }
  }

  function* removeRelatedPredicts(matchId, relatedTypes) {
    const relatedTasks = yield call(tasks.getList, matchId, relatedTypes)

    yield put(removePredicts(matchId, relatedTypes))

    if (relatedTasks) {
      yield cancel(...relatedTasks)
      yield call(tasks.removeList, matchId, relatedTypes)
    }
  }

  return function*(action: {payload: {koefId: number}}): any {
    const {
      payload: {koefId},
    } = action
    const koef = yield select(state => getKoef(state, {koefId}))
    const {match: matchId, koefTypes: koefType} = koef
    const predict = yield select(state => getMyPredict(state, {matchId, koefType}))
    const noPredict = !predict || predict.koefs !== koefId

    let relatedTypesToRemove = null
    if (KOEF_TYPES_DIC.MAIN[koefType] || koefType === HANDICAP_KOEF) {
      relatedTypesToRemove = DEPENDED_TO_MAIN_KOEF_TYPES_ARR.filter(type => type !== koefType)
    } else if (KOEF_TYPES_DIC.SUB_MAIN[koefType]) {
      relatedTypesToRemove = DEPENDED_TO_SCORE_KOEF_TYPES_ARR.filter(type => type !== koefType)
    }
    if (relatedTypesToRemove) yield call(removeRelatedPredicts, matchId, relatedTypesToRemove)

    const oldTask = yield call(tasks.get, matchId, koefType)
    if (oldTask && noPredict) {
      yield cancel(oldTask)
      yield call(tasks.remove, matchId, koefType)
    }

    const saga = noPredict ? placeBetSaga : removeBetSaga
    const task = yield fork(saga, koef, oldTask, action)

    yield call(tasks.put, matchId, koefType, task)

    yield join(task)

    yield call(tasks.remove, matchId, koefType)
  }
}

export const spreadHandicapListByKoefType = (
  koefsIdsByType: {[koefType: number]: Array<number>},
  koefs: {[koefId: number]: any},
) => {
  const addHandicapForKoefType = (acc, koefType, handicap) => ({
    ...acc,
    [koefType]: [...(acc[koefType] || []), handicap],
  })

  const handicapsByKoefType = KOEF_TYPES_ARR.HANDICAP.map(type => koefsIdsByType[type])
    .reduce((acc, ids = []) => [...acc, ...ids], [])
    .reduce(
      (acc, id) => {
        const handicap = koefs[id]
        const {handicapType} = handicap

        if (handicapType === MAIN_KOEF_HANDICAP_RELATIONSHIP[MAIN_KOEFS.FIRST])
          return addHandicapForKoefType(acc, MAIN_KOEFS.FIRST, handicap)
        else if (handicapType === MAIN_KOEF_HANDICAP_RELATIONSHIP[MAIN_KOEFS.SECOND])
          return addHandicapForKoefType(acc, MAIN_KOEFS.SECOND, handicap)

        return acc
      },
      {[MAIN_KOEFS.FIRST]: null, [MAIN_KOEFS.SECOND]: null},
    )

  return mapValues(
    handicapsByKoefType,
    handicaps => handicaps && handicaps.sort((a, b) => (a.handicapCount > b.handicapCount ? -1 : 1)),
  )
}
