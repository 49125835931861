// @flow

import {combineReducers} from 'redux'

import {START_FETCH_NOTIFICATION, FINISH_FETCH_NOTIFICATION} from './constants'
import type {State, NotificationState} from './types'

const initialState: State = {
  notification: {
    data: [],
    loading: false,
  },
}

const notification = (state: NotificationState = initialState.notification, {payload, type}: any) => {
  switch (type) {
    case START_FETCH_NOTIFICATION: {
      return {...state, loading: true, data: initialState.notification.data}
    }

    case FINISH_FETCH_NOTIFICATION: {
      return {...state, data: payload, loading: false}
    }

    default:
      return state
  }
}

export default combineReducers({notification})
