export const getAllDeepValues = o => {
  let values = []

  for (let key in o) {
    const value = o[key]

    if (typeof value === 'object') values = [...values, ...getAllDeepValues(value)]
    else values = [...values, value]
  }

  return values
}
