// @flow

import {combineReducers} from 'redux'

import type {State} from './types'
import {
  ADD_USERS,
  START_LOADING_USERS,
  FINISH_LOADING_USERS,
  SET_USERS_COUNT,
  RESET_USERS,
  START_LOADING_USER,
  FINISH_LOADING_USER,
  SET_USER,
  LOAD_MY_USER,
  SET_MY_USER,
} from './constants'

const initialState: State = {
  users: {
    data: {
      pages: [],
      paths: {},
    },
    count: null,
    loading: true,
  },
  user: {
    data: null,
    loading: true,
  },
  myUser: {
    data: null,
    loading: true,
  },
}

const users = (state = initialState.users, {payload, type}) => {
  switch (type) {
    case ADD_USERS: {
      const {pages, paths} = state.data

      return {
        ...state,
        data: {
          pages: [...pages, payload],
          paths: {...paths, ...payload.reduce((acc, {id}, i) => ({...acc, [id]: `${pages.length}.${i}`}), {})},
        },
      }
    }

    case START_LOADING_USERS:
      return {...state, loading: true}

    case FINISH_LOADING_USERS:
      return {...state, loading: false}

    case SET_USERS_COUNT:
      return {...state, count: payload}

    case RESET_USERS:
      return initialState.users

    default:
      return state
  }
}

const user = (state = initialState.user, {payload, type}) => {
  switch (type) {
    case START_LOADING_USER: {
      return {...state, loading: true}
    }

    case FINISH_LOADING_USER: {
      return {...state, loading: false}
    }

    case SET_USER: {
      return {...state, data: payload}
    }

    default:
      return state
  }
}

const myUser = (state = initialState.myUser, {payload, type}) => {
  switch (type) {
    case LOAD_MY_USER:
      return {...state, loading: true}

    case SET_MY_USER:
      return {...state, loading: false, data: payload}

    default:
      return state
  }
}

export default combineReducers({users, user, myUser})
