// @flow

import {collection} from './index'

type Inject = {
  handlerEnhancer: Function,
}

export default ({handlerEnhancer}: Inject) =>
  collection.saga({handlerEnhancer, makeReq: ({teamId}) => ({url: `/admin/teams/${teamId}/request/`})})
