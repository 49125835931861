// @flow

import React from 'react'
import {connect} from 'react-redux'
import memoize from 'fast-memoize'

import {getErrorsList} from './selectors'
import {delError, delErrors} from './actions'
import type {UIError} from './types'

export const withUIErrors = (errorsList: Array<$PropertyType<UIError, 'code'>>) => (WrappedComponent: any) => {
  const errorsDic = errorsList.reduce((acc, code) => ({...acc, [code]: true}), {})
  const memCloseError = memoize((dispatch, code) => () => dispatch(delError(code)))

  type Props = {
    uiError: UIError,
    dispatch: Function,
  }

  class Wrapper extends React.Component<Props> {
    constructor(props) {
      super(props)

      const {uiError, dispatch} = props

      if (uiError) dispatch(delErrors(errorsList))
    }

    componentWillUnmount() {
      const {uiError, dispatch} = this.props

      if (uiError) dispatch(delErrors(errorsList))
    }

    render() {
      const {dispatch, uiError} = this.props

      const props = {
        ...this.props,
        uiCloseError: uiError && memCloseError(dispatch, uiError.code),
      }

      return <WrappedComponent {...props} />
    }
  }

  const mapStateToProps = state => {
    const error = getErrorsList(state).find(({code}) => errorsDic[code])

    if (error) {
      return {
        uiError: error,
      }
    }

    return {}
  }

  return connect(mapStateToProps)(Wrapper)
}
