// @flow

import {combineReducers} from 'redux'

import {
  LOGOUT,
  START_LOGIN,
  FINISH_LOGIN,
  SET_USER,
  START_LOAD_INFO,
  FINISH_LOAD_INFO,
  SET_INFO,
  START_LOAD_SCORES,
  FINISH_LOAD_SCORES,
  SET_SCORES_DATA,
  SET_USER_OLIMP_LOGIN,
  RESET_AUTH_ERROR,
  USER_LOGOUT,
} from './constants'
import type {State, InfoState, AuthState, ScoresState} from './types'

const initialState: State = {
  auth: {
    user: null,
    error: null,
    loading: false,
  },
  info: {
    data: null,
    loading: true,
  },
  scores: {
    data: null,
    loading: true,
  },
  settings: {
    timezone: null,
  },
}

const auth = (state: AuthState = initialState.auth, {payload, type}) => {
  switch (type) {
    case LOGOUT:
      return initialState

    case USER_LOGOUT:
      return initialState

    case START_LOGIN:
      return {...state, loading: true, error: null}

    case FINISH_LOGIN:
      return {...state, loading: false, error: payload.error}

    case SET_USER:
      return {...state, user: payload}

    case RESET_AUTH_ERROR:
      return {...state, error: initialState.auth.error}

    default:
      return state
  }
}

const info = (state: InfoState = initialState.info, {payload, type}) => {
  switch (type) {
    case START_LOAD_INFO:
      return {...state, loading: true}

    case FINISH_LOAD_INFO:
      return {...state, loading: false}

    case SET_INFO:
      return {...state, data: payload}

    case SET_USER_OLIMP_LOGIN:
      return {...state, data: {...state.data, userOlimpLogin: payload}}

    default:
      return state
  }
}

const scores = (state: ScoresState = initialState.scores, {payload, type}) => {
  switch (type) {
    case START_LOAD_SCORES:
      return {...state, loading: true}

    case FINISH_LOAD_SCORES:
      return {...state, loading: false}

    case SET_SCORES_DATA:
      return {...state, data: payload}

    default:
      return state
  }
}

export default combineReducers({auth, info, scores})
