// @flow

import isNil from 'lodash/isNil'

import type {PredictsByKoefType, KoefsById, Koef} from 'store/lib/events'

import type {MatchesState, MyPredictsState, KoefsTypesState, ResultsState} from './types'
import {
  START_LOADING,
  FINISH_LOADING,
  LOAD_UPCOMING,
  LOAD_ACTUAL,
  LOAD_TEAM_MEMBER,
  TOGGLE_BET,
  SET_MY_PREDICTS_FOR_MATCH,
  PUT_MOCK_PREDICT,
  REMOVE_PREDICT,
  REMOVE_PREDICTS,
  ADD_DATA,
  PLACE_BET_ERROR,
  RESET,
  SET_COUNT,
  LOAD_USER_DATA,
} from './constants'

export const startLoading = () => ({type: START_LOADING})

export const finishLoading = () => ({type: FINISH_LOADING})

export const addData = (payload: {|
  results: ResultsState,
  matches: MatchesState,
  myPredicts: MyPredictsState,
  koefs: KoefsById,
  koefsTypes: KoefsTypesState,
|}) => ({type: ADD_DATA, payload})

export const setMyPredictsForMatch = (matchId: number, predicts: PredictsByKoefType) => ({
  type: SET_MY_PREDICTS_FOR_MATCH,
  payload: {matchId, predicts},
})

export const putMockPredict = (matchId: number, type: $PropertyType<Koef, 'koefTypes'>, koefId: number) => ({
  type: PUT_MOCK_PREDICT,
  payload: {matchId, type, koefId},
})

export const removePredict = (matchId: number, type: $PropertyType<Koef, 'koefTypes'>) => ({
  type: REMOVE_PREDICT,
  payload: {matchId, type},
})

export const removePredicts = (matchId: number, types: Array<$PropertyType<Koef, 'koefTypes'>>) => ({
  type: REMOVE_PREDICTS,
  payload: {matchId, types},
})

export const loadUpcoming = (fromStart?: boolean = true) => ({type: LOAD_UPCOMING, payload: {fromStart}})

export const loadActual = (payload: {
  userId?: number,
  fromStart?: boolean,
  onlyPlayed?: boolean,
  includeStarted?: boolean,
}) => ({
  type: LOAD_ACTUAL,
  payload: {...payload, fromStart: isNil(payload.fromStart) ? true : payload.fromStart},
})

export const setCount = (payload: number) => ({type: SET_COUNT, payload})

export const reset = () => ({type: RESET})

export const loadTeamMember = (teamId: number, userId: number) => ({type: LOAD_TEAM_MEMBER, payload: {teamId, userId}})

export const toggleBet = (koefId: number) => ({type: TOGGLE_BET, payload: {koefId}})

export const placeBetError = (koefId: number, message: string) => ({type: PLACE_BET_ERROR, payload: {koefId, message}})

export const LoadUserData = (payload: {userId: number, matchId: number}) => ({type: LOAD_USER_DATA, payload})
