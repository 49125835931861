// @flow

import {combineReducers} from 'redux'

import Pagination from 'store/lib/pagination'

import type {State, SingleState} from './types'
import {START_FETCH_TEAM, FINISH_FETCH_TEAM} from './constants'

const initialState: State = {
  single: {
    data: null,
    loading: false,
  },
  teamUsers: {
    data: null,
    loading: false,
  },
}

const single = (state: SingleState = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_TEAM: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_TEAM: {
      return {...state, loading: false, data: payload.data || initialState.single.data}
    }

    default:
      return state
  }
}

export const collection = new Pagination('battle-py-frontend/admin/teams', 'admin.teams.collection')

export const collectionWithRate = new Pagination('battle-py-frontend/admin/teams', 'admin.teams.collectionWithRate')

export const teamUsers = new Pagination('battle-py-frontend/admin/teams/teamUsers', 'admin.teams.teamUsers')

export default combineReducers({
  collection: collection.reducer,
  single,
  teamUsers: teamUsers.reducer,
  collectionWithRate: collectionWithRate,
})
