import {combineReducers} from 'redux'

import {START_FETCH_RULE, FINISH_FETCH_RULE} from './constants'

const initialState = {
  single: {
    data: null,
    loading: false,
  },
}

const single = (state = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_RULE:
      return {...state, loading: true}

    case FINISH_FETCH_RULE:
      return {...state, loading: false, data: payload}

    default:
      return state
  }
}

export default combineReducers({single})
