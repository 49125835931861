// @flow

import api from 'api'

import {collection, collectionWithRate, teamUsers} from './index'
import {START_FETCH_TEAM, FINISH_FETCH_TEAM} from './constants'
import type {Team} from './types'

function startFetchTeam(teamId: number) {
  return {type: START_FETCH_TEAM, payload: {teamId}}
}

function finishFetchTeam(teamId: number, data?: Team) {
  return {type: FINISH_FETCH_TEAM, payload: {teamId, data}}
}

let lastFetchTeamsParams = void 0

export function fetchContributionUser(params) {
  lastFetchTeamsParams = params
  return teamUsers.actions.load(lastFetchTeamsParams)
}

export function fetchTeam(teamId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchTeam(teamId))

    const {data, status} = await api.get(`/admin/teams/${teamId}/`)

    if (status === 200) {
      dispatch(finishFetchTeam(teamId, data))
    } else {
      dispatch(finishFetchTeam(teamId))

      return data
    }
  }
}

export function fetchTeams(params?: {
  groupId?: number,
  status?: 0 | 1 | 2,
  championship?: number,
  lost?: boolean,
  user?: number,
}) {
  lastFetchTeamsParams = params

  return collection.actions.load(params)
}

export function fetchTeamsWithRate(championshipId: number) {
  return collectionWithRate.actions.load({championship_id: championshipId})
}

export function editTeam(teamId: number, values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/teams/${teamId}/`, values)

    if (status === 200) {
      dispatch(fetchTeams(lastFetchTeamsParams))
    } else {
      return data
    }
  }
}

export function createTeam(values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/teams/', values)

    if (status === 201) {
      dispatch(fetchTeams(lastFetchTeamsParams))
    } else {
      return data
    }
  }
}

export function deleteTeam(teamId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/teams/${teamId}/`)

    if (status === 204) {
      dispatch(fetchTeams(lastFetchTeamsParams))
    } else {
      return data
    }
  }
}
