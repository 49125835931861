// @flow

import type {Stack} from 'immutable'

import {
  ADD_MESSAGE,
  CONNECT,
  CONNECT_FAILURE,
  CONNECT_SUCCESS,
  DISCONNECT,
  SEND_MESSAGE,
  SET_MESSAGES,
} from './constants'
import type {Message} from './types'

export const connect = (groupId: number) => ({type: CONNECT, payload: {groupId}})

export const connectSuccess = () => ({type: CONNECT_SUCCESS})

export const disconnect = () => ({type: DISCONNECT})

export const connectFailure = () => ({type: CONNECT_FAILURE})

export const setMessages = (messages: Array<Message> | Stack<Message>) => ({type: SET_MESSAGES, payload: {messages}})

export const addMessage = (message: Message) => ({type: ADD_MESSAGE, payload: {message}})

export const sendMessage = (message: string) => ({type: SEND_MESSAGE, payload: {message}})
