// @flow

import last from 'lodash/last'

import api from 'api'

import type {Rule} from './types'
import {START_FETCH_RULE, FINISH_FETCH_RULE} from './constants'

function startFetchRule() {
  return {
    type: START_FETCH_RULE,
  }
}

function finishFetchRule(payload?) {
  return {
    type: FINISH_FETCH_RULE,
    payload,
  }
}

export function fetchRule(championship: number, lang: string) {
  return async (dispatch: Function) => {
    dispatch(startFetchRule())

    const {data, status} = await api.get('/admin/rules/', {params: {championship, lang}})

    const rule = last(data)

    dispatch(finishFetchRule(status === 200 && rule ? {...rule, text: JSON.parse(rule.text)} : null))
  }
}

export function uploadRule(rule: Rule) {
  return async () => {
    const adaptedRule = {...rule, text: JSON.stringify(rule.text)}
    const uploadPromise =
      typeof adaptedRule.id !== 'number'
        ? api.post('/admin/rules/', adaptedRule)
        : api.put(`/admin/rules/${adaptedRule.id}/`, adaptedRule)

    const {data, status} = await uploadPromise

    if (![200, 201].includes(status)) return data
  }
}
