// @flow

import isNil from 'lodash/isNil'

import {collection} from './index'

type Inject = {
  handlerEnhancer: Function,
}

export default (inj: Inject) =>
  collection.saga({
    handlerEnhancer: inj.handlerEnhancer,
    makeReq: ({team}) => ({url: isNil(team) ? '/admin/users/' : `/admin/teams/${team}/users/`}),
  })
