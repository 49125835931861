// @flow

import {eventChannel, buffers, delay} from 'redux-saga'
import {takeLatest, takeEvery, take, call, fork, put, race, select} from 'redux-saga/effects'
import camelcase from 'camelcase-keys-deep'
import moment from 'moment'

import {CONNECT, CONNECT_FAILURE, DISCONNECT, SEND_MESSAGE} from './constants'
import {addMessage, connect, connectFailure, connectSuccess, setMessages} from './actions'

type Inject = {
  selectToken: Object => string,
}

const adaptMessage = ({timestamp, ...message}) => ({...message, date: moment.utc(Number(timestamp) * 1000).local()})

const socketEventChannel = (socket, event) =>
  eventChannel(emit => {
    const cb = event => emit(event)

    socket.addEventListener(event, cb)

    return () => socket.removeEventListeners(event, cb)
  }, buffers.expanding(1))

const takeOnce = (channel, saga, ...args) =>
  fork(function*() {
    const event = yield take(channel)

    yield call(saga, ...args, event)
  })

const getSocketURL = ({token, groupId}) =>
  `ws${window.location.protocol === 'https:' ? 's' : ''}://${window.location.host}/ws/chat/${groupId}/?token=${token}`

function* connectHandler({selectToken}: Inject, {payload: {groupId}}) {
  const {WebSocket} = window

  if (!WebSocket) return

  const token = yield select(selectToken)
  const ws = new WebSocket(getSocketURL({token, groupId}))

  const openChannel = socketEventChannel(ws, 'open')
  const messageChannel = socketEventChannel(ws, 'message')
  const errorChannel = socketEventChannel(ws, 'error')
  const closeChannel = socketEventChannel(ws, 'close')

  yield takeOnce(openChannel, function*() {
    yield put(connectSuccess())
  })

  yield takeOnce(errorChannel, function*() {
    yield put(connectFailure())
  })

  yield takeOnce(closeChannel, function*() {
    yield put(connectFailure())
  })

  yield takeEvery(messageChannel, function*(event) {
    const data = camelcase(JSON.parse(event.data))

    if (Array.isArray(data)) {
      yield put(setMessages(data.map(adaptMessage)))
    } else {
      yield put(addMessage(adaptMessage(data)))
    }
  })

  yield takeEvery(SEND_MESSAGE, function*({payload}) {
    yield call([ws, ws.send], JSON.stringify(payload))
  })

  try {
    const [connectFailure] = yield race([take(CONNECT_FAILURE), take(DISCONNECT)])

    if (connectFailure) {
      yield delay(5000)
      yield put(connect(groupId))
    }
  } finally {
    if ([WebSocket.OPEN, WebSocket.CONNECTING].includes(ws.readyState)) yield call([ws, ws.close])
  }
}

export default (inj: Inject) =>
  function*(): Generator<*, *, *> {
    yield takeLatest(CONNECT, connectHandler, inj)
  }
