// @flow

import {combineReducers} from 'redux'

import type {State} from './types'
import {
  START_FETCH_STAGES,
  FINISH_FETCH_STAGES,
  START_FETCH_STAGE,
  FINISH_FETCH_STAGE,
  SET_ACTIVE_STAGE,
} from './constants'

const initialState: State = {
  collection: {
    data: {
      byId: {},
      idsByChampId: {},
      idsByStageId: {},
    },
    loading: false,
  },
  single: {
    data: null,
    loading: false,
  },
  activeStage: null,
}

const collection = (state = initialState.collection, {payload, type}) => {
  switch (type) {
    case START_FETCH_STAGES: {
      return {...state, loading: true}
    }

    case FINISH_FETCH_STAGES: {
      return {...state, loading: false, data: payload || initialState.collection.data}
    }

    default:
      return state
  }
}

const single = (state = initialState.single, {payload, type}) => {
  switch (type) {
    case START_FETCH_STAGE: {
      return {...state, loading: true, data: initialState.single.data}
    }

    case FINISH_FETCH_STAGE: {
      return {...state, loading: false, data: payload.data || initialState.single.data}
    }

    default:
      return state
  }
}

const activeStage = (state = initialState.activeStage, {payload, type}) => {
  switch (type) {
    case SET_ACTIVE_STAGE: {
      return payload
    }

    default:
      return state
  }
}

export default combineReducers({collection, single, activeStage})
