// @flow

import {
  LOAD_TOURNAMENT_DATA,
  START_TOURNAMENT_LOADING,
  FINISH_TOURNAMENT_LOADING,
  SET_TOURNAMENT_DATA,
  LOAD_TEAM_DETAILS,
  START_TEAM_LOADING,
  FINISH_TEAM_LOADING,
  SET_TEAM_DETAILS,
  LOAD_TEAMS,
  START_TEAMS_LOADING,
  FINISH_TEAMS_LOADING,
  SET_TEAMS_DATA,
  LOAD_TEAM_STAGES,
  START_TEAM_STAGES_LOADING,
  FINISH_TEAM_STAGES_LOADING,
  SET_TEAM_STAGES,
  JOIN_TEAM,
  JOIN_TEAM_SUCCESS,
  JOIN_TEAM_ERROR,
  LEAVE_TEAM,
  LEAVE_TEAM_SUCCESS,
  LEAVE_TEAM_ERROR,
  CANCEL_TEAM_REQUEST,
  CANCEL_TEAM_REQUEST_SUCCESS,
  OPEN_TEAM_USER_ERROR,
  APPROVE_TO_TEAM,
  APPROVE_TO_TEAM_ERROR,
  APPROVE_TO_TEAM_SUCCESS,
  DENY_TO_TEAM,
  DENY_TO_TEAM_ERROR,
  DENY_TO_TEAM_SUCCESS,
  REMOVE_FROM_TEAM,
  REMOVE_FROM_TEAM_ERROR,
  REMOVE_FROM_TEAM_SUCCESS,
  GIVE_VICE_CAPTAIN,
  GIVE_VICE_CAPTAIN_ERROR,
  GIVE_VICE_CAPTAIN_SUCCESS,
  REMOVE_VICE_CAPTAIN,
  REMOVE_VICE_CAPTAIN_ERROR,
  REMOVE_VICE_CAPTAIN_SUCCESS,
  LOAD_USERS,
  START_LOADING_USERS,
  FINISH_LOADING_USERS,
  ADD_USERS,
  SET_USERS_COUNT,
  RESET_USERS,
  LOAD_STAGE_MATCHES,
  START_LOADING_STAGE_MATCHES,
  FINISH_LOADING_STAGE_MATCHES,
  SET_STAGE_MATCHES_COUNT,
  ADD_STAGE_MATCHES,
  RESET_STAGE_MATCHES,
  FINISH_LOADING_USER,
  LOAD_USER,
  SET_USER,
  START_LOADING_USER,
} from './constants'
import type {
  TournamentData,
  TeamDetails,
  TeamsData,
  TournamentDataType,
  TeamStagesData,
  TeamUser,
  StageMatch,
} from './types'

export const loadTournamentData = (payload?: TournamentDataType = 'table') => ({type: LOAD_TOURNAMENT_DATA, payload})

export const startTournamentLoading = () => ({type: START_TOURNAMENT_LOADING})

export const finishTournamentLoading = () => ({type: FINISH_TOURNAMENT_LOADING})

export const setTournamentData = (payload: TournamentData) => ({type: SET_TOURNAMENT_DATA, payload})

export const loadTeamDetails = (teamId: number) => ({type: LOAD_TEAM_DETAILS, payload: teamId})

export const startTeamLoading = () => ({type: START_TEAM_LOADING})

export const finishTeamLoading = () => ({type: FINISH_TEAM_LOADING})

export const setTeamDetails = (payload: TeamDetails | null) => ({type: SET_TEAM_DETAILS, payload})

export const loadTeams = () => ({type: LOAD_TEAMS})

export const startTeamsLoading = () => ({type: START_TEAMS_LOADING})

export const finishTeamsLoading = () => ({type: FINISH_TEAMS_LOADING})

export const setTeamsData = (payload: TeamsData) => ({type: SET_TEAMS_DATA, payload})

export const loadTeamStages = (teamId: number) => ({type: LOAD_TEAM_STAGES, payload: teamId})

export const startTeamStagesLoading = () => ({type: START_TEAM_STAGES_LOADING})

export const finishTeamStagesLoading = () => ({type: FINISH_TEAM_STAGES_LOADING})

export const setTeamStages = (payload: TeamStagesData) => ({type: SET_TEAM_STAGES, payload})

export const joinTeam = (teamId: number, privateTeam?: boolean) => ({type: JOIN_TEAM, payload: {teamId, privateTeam}})

export const leaveTeam = (teamId: number) => ({type: LEAVE_TEAM, payload: {teamId}})

export const leaveTeamSuccess = (teamId: number) => ({
  type: LEAVE_TEAM_SUCCESS,
  payload: {teamId},
})

export const leaveTeamError = (teamId: number, message: string) => ({
  type: LEAVE_TEAM_ERROR,
  payload: {teamId, message},
})

export const cancelTeamRequest = (requestId: number) => ({type: CANCEL_TEAM_REQUEST, payload: {requestId}})

export const cancelTeamRequestSuccess = (requestId: number) => ({
  type: CANCEL_TEAM_REQUEST_SUCCESS,
  payload: {requestId},
})

export const joinTeamSuccess = (teamId: number) => ({type: JOIN_TEAM_SUCCESS, payload: {teamId}})

export const joinTeamError = (teamId: number, message: string) => ({type: JOIN_TEAM_ERROR, payload: {teamId, message}})

export const openTeamUserDetailsError = (teamId: number, userId: number, message: string) => ({
  type: OPEN_TEAM_USER_ERROR,
  payload: {teamId, userId, message},
})

export const approveToTeam = (requestId: number) => ({type: APPROVE_TO_TEAM, payload: {requestId}})

export const approveToTeamSuccess = (requestId: number) => ({
  type: APPROVE_TO_TEAM_SUCCESS,
  payload: {requestId},
})

export const approveToTeamError = (requestId: number, message: string) => ({
  type: APPROVE_TO_TEAM_ERROR,
  payload: {requestId, message},
})

export const denyToTeam = (requestId: number) => ({
  type: DENY_TO_TEAM,
  payload: {requestId},
})

export const denyToTeamSuccess = (requestId: number) => ({
  type: DENY_TO_TEAM_SUCCESS,
  payload: {requestId},
})

export const denyToTeamError = (requestId: number, message: string) => ({
  type: DENY_TO_TEAM_ERROR,
  payload: {requestId, message},
})

export const removeFromTeam = (teamId: number, userId: number) => ({type: REMOVE_FROM_TEAM, payload: {teamId, userId}})

export const removeFromTeamSuccess = (teamId: number, userId: number) => ({
  type: REMOVE_FROM_TEAM_SUCCESS,
  payload: {teamId, userId},
})

export const removeFromTeamError = (teamId: number, userId: number, message: string) => ({
  type: REMOVE_FROM_TEAM_ERROR,
  payload: {teamId, userId, message},
})

export const giveViceCaptain = (teamId: number, userId: number) => ({
  type: GIVE_VICE_CAPTAIN,
  payload: {teamId, userId},
})

export const giveViceCaptainError = (teamId: number, userId: number, message: string) => ({
  type: GIVE_VICE_CAPTAIN_ERROR,
  payload: {teamId, userId, message},
})

export const giveViceCaptainSuccess = (teamId: number, userId: number) => ({
  type: GIVE_VICE_CAPTAIN_SUCCESS,
  payload: {teamId, userId},
})

export const removeViceCaptain = (userId: number) => ({
  type: REMOVE_VICE_CAPTAIN,
  payload: {userId},
})

export const removeViceCaptainError = (teamId: number, userId: number, message: string) => ({
  type: REMOVE_VICE_CAPTAIN_ERROR,
  payload: {teamId, userId, message},
})

export const removeViceCaptainSuccess = (teamId: number, userId: number) => ({
  type: REMOVE_VICE_CAPTAIN_SUCCESS,
  payload: {teamId, userId},
})

export const loadUsers = (teamId: number, fromStart?: boolean = true, search?: string) => ({
  type: LOAD_USERS,
  payload: {teamId, fromStart, search},
})

export const startLoadingUsers = () => ({type: START_LOADING_USERS})

export const finishLoadingUsers = () => ({type: FINISH_LOADING_USERS})

export const addUsers = (payload: Array<TeamUser>) => ({type: ADD_USERS, payload})

export const setUsersCount = (payload: number) => ({type: SET_USERS_COUNT, payload})

export const resetUsers = () => ({type: RESET_USERS})

export const loadUser = (teamId: number, userId: number, search?: string) => ({
  type: LOAD_USER,
  payload: {teamId, userId, search},
})

export const startLoadingUser = () => ({type: START_LOADING_USER})

export const finishLoadingUser = () => ({type: FINISH_LOADING_USER})

export const setUser = (payload: TeamUser | null) => ({type: SET_USER, payload})

export const loadStageMatches = (teamId: number, stageId: number, fromStart?: boolean = true) => ({
  type: LOAD_STAGE_MATCHES,
  payload: {teamId, stageId, fromStart},
})

export const startLoadingStageMatches = () => ({type: START_LOADING_STAGE_MATCHES})

export const finishLoadingStageMatches = () => ({type: FINISH_LOADING_STAGE_MATCHES})

export const addStageMatches = (payload: Array<StageMatch>) => ({type: ADD_STAGE_MATCHES, payload})

export const setStageMatchesCount = (payload: number) => ({type: SET_STAGE_MATCHES_COUNT, payload})

export const resetStageMatches = () => ({type: RESET_STAGE_MATCHES})
