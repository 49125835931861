export const LOAD_TOURNAMENT_DATA = 'battle-py-frontend/core/teams/LOAD_TOURNAMENT_DATA'
export const START_TOURNAMENT_LOADING = 'battle-py-frontend/core/teams/START_TOURNAMENT_LOADING'
export const FINISH_TOURNAMENT_LOADING = 'battle-py-frontend/core/teams/FINISH_TOURNAMENT_LOADING'
export const SET_TOURNAMENT_DATA = 'battle-py-frontend/core/teams/SET_TOURNAMENT_DATA'

export const LOAD_TEAM_DETAILS = 'battle-py-frontend/core/teams/LOAD_TEAM_DETAILS'
export const START_TEAM_LOADING = 'battle-py-frontend/core/teams/START_TEAM_LOADING'
export const FINISH_TEAM_LOADING = 'battle-py-frontend/core/teams/FINISH_TEAM_LOADING'
export const SET_TEAM_DETAILS = 'battle-py-frontend/core/teams/SET_TEAM_DETAILS'

export const LOAD_TEAMS = 'battle-py-frontend/core/teams/LOAD_TEAMS'
export const START_TEAMS_LOADING = 'battle-py-frontend/core/teams/START_TEAMS_LOADING'
export const FINISH_TEAMS_LOADING = 'battle-py-frontend/core/teams/FINISH_TEAMS_LOADING'
export const SET_TEAMS_DATA = 'battle-py-frontend/core/teams/SET_TEAMS_DATA'

export const LOAD_TEAM_STAGES = 'battle-py-frontend/core/teams/LOAD_TEAM_STAGES'
export const START_TEAM_STAGES_LOADING = 'battle-py-frontend/core/teams/START_TEAM_STAGES_LOADING'
export const FINISH_TEAM_STAGES_LOADING = 'battle-py-frontend/core/teams/FINISH_TEAM_STAGES_LOADING'
export const SET_TEAM_STAGES = 'battle-py-frontend/core/teams/SET_TEAM_STAGES'

export const JOIN_TEAM = 'battle-py-frontend/core/teams/JOIN_TEAM'
export const JOIN_TEAM_SUCCESS = 'battle-py-frontend/core/teams/JOIN_TEAM_SUCCESS'
export const JOIN_TEAM_ERROR = 'battle-py-frontend/core/teams/JOIN_TEAM_ERROR'

export const LEAVE_TEAM = 'battle-py-frontend/core/teams/LEAVE_TEAM'
export const LEAVE_TEAM_SUCCESS = 'battle-py-frontend/core/teams/LEAVE_TEAM_SUCCESS'
export const LEAVE_TEAM_ERROR = 'battle-py-frontend/core/teams/LEAVE_TEAM_ERROR'

export const CANCEL_TEAM_REQUEST = 'battle-py-frontend/core/teams/CANCEL_TEAM_REQUEST'
export const CANCEL_TEAM_REQUEST_SUCCESS = 'battle-py-frontend/core/teams/CANCEL_TEAM_REQUEST_SUCCESS'

export const OPEN_TEAM_USER_ERROR = 'battle-py-frontend/core/teams/OPEN_TEAM_USER_ERROR'

export const APPROVE_TO_TEAM = 'battle-py-frontend/core/teams/APPROVE_TO_TEAM'
export const APPROVE_TO_TEAM_SUCCESS = 'battle-py-frontend/core/teams/APPROVE_TO_TEAM_SUCCESS'
export const APPROVE_TO_TEAM_ERROR = 'battle-py-frontend/core/teams/APPROVE_TO_TEAM_ERROR'

export const DENY_TO_TEAM = 'battle-py-frontend/core/teams/DENY_TO_TEAM'
export const DENY_TO_TEAM_SUCCESS = 'battle-py-frontend/core/teams/DENY_TO_TEAM_SUCCESS'
export const DENY_TO_TEAM_ERROR = 'battle-py-frontend/core/teams/DENY_TO_TEAM_ERROR'

export const REMOVE_FROM_TEAM = 'battle-py-frontend/core/teams/REMOVE_FROM_TEAM'
export const REMOVE_FROM_TEAM_SUCCESS = 'battle-py-frontend/core/teams/REMOVE_FROM_TEAM_SUCCESS'
export const REMOVE_FROM_TEAM_ERROR = 'battle-py-frontend/core/teams/REMOVE_FROM_TEAM_ERROR'

export const GIVE_VICE_CAPTAIN = 'battle-py-frontend/core/teams/GIVE_VICE_CAPTAIN'
export const GIVE_VICE_CAPTAIN_SUCCESS = 'battle-py-frontend/core/teams/GIVE_VICE_CAPTAIN_SUCCESS'
export const GIVE_VICE_CAPTAIN_ERROR = 'battle-py-frontend/core/teams/GIVE_VICE_CAPTAIN_ERROR'

export const REMOVE_VICE_CAPTAIN = 'battle-py-frontend/core/teams/REMOVE_VICE_CAPTAIN'
export const REMOVE_VICE_CAPTAIN_ERROR = 'battle-py-frontend/core/teams/REMOVE_VICE_CAPTAIN_ERROR'
export const REMOVE_VICE_CAPTAIN_SUCCESS = 'battle-py-frontend/core/teams/REMOVE_VICE_CAPTAIN_SUCCESS'

export const LOAD_USERS = 'battle-py-frontend/core/teams/LOAD_USERS'
export const START_LOADING_USERS = 'battle-py-frontend/core/teams/START_LOADING_USERS'
export const FINISH_LOADING_USERS = 'battle-py-frontend/core/teams/FINISH_LOADING_USERS'
export const ADD_USERS = 'battle-py-frontend/core/teams/ADD_USERS'
export const SET_USERS_COUNT = 'battle-py-frontend/core/teams/SET_USERS_COUNT'
export const RESET_USERS = 'battle-py-frontend/core/teams/RESET_USERS'

export const LOAD_USER = 'battle-py-frontend/core/teams/LOAD_USER'
export const START_LOADING_USER = 'battle-py-frontend/core/teams/START_LOADING_USER'
export const FINISH_LOADING_USER = 'battle-py-frontend/core/teams/FINISH_LOADING_USER'
export const SET_USER = 'battle-py-frontend/core/teams/SET_USER'

export const LOAD_STAGE_MATCHES = 'battle-py-frontend/core/teams/LOAD_STAGE_MATCHES'
export const START_LOADING_STAGE_MATCHES = 'battle-py-frontend/core/teams/START_LOADING_STAGE_MATCHES'
export const FINISH_LOADING_STAGE_MATCHES = 'battle-py-frontend/core/teams/FINISH_LOADING_STAGE_MATCHES'
export const SET_STAGE_MATCHES_COUNT = 'battle-py-frontend/core/teams/SET_STAGE_MATCHES_COUNT'
export const ADD_STAGE_MATCHES = 'battle-py-frontend/core/teams/ADD_STAGE_MATCHES'
export const RESET_STAGE_MATCHES = 'battle-py-frontend/core/teams/RESET_STAGE_MATCHES'
