// @flow

import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import moment from 'moment'
import orderBy from 'lodash/orderBy'

import api from 'api'

import {
  START_FETCH_STAGES,
  FINISH_FETCH_STAGES,
  START_FETCH_STAGE,
  FINISH_FETCH_STAGE,
  SET_ACTIVE_STAGE,
} from './constants'
import type {CollectionData, Stage} from './types'

export const startFetchStages = () => ({type: START_FETCH_STAGES})

export const finishFetchStages = (payload: ?CollectionData) => ({type: FINISH_FETCH_STAGES, payload})

let lastFetchChampionshipParams = void 0
export const fetchStages = (championship: ?number) => {
  return async (dispatch: Function) => {
    lastFetchChampionshipParams = championship
    dispatch(startFetchStages())
    const {data, status} = await api.get(`/admin/stages/`, {
      params: {
        championship,
      },
    })
    if (status === 200) {
      const idsByChampId = mapValues(groupBy(data, 'championship'), stages => stages.map(({id}) => id))
      const idsByStageId = mapValues(groupBy(data, 'id'), stages => stages.map(({id}) => id))
      const sortedDate = orderBy(data, o => new moment(o.date), ['desc'])
      const byId = sortedDate.reduce((acc, stage) => ({...acc, [stage.id]: stage}), {})

      dispatch(finishFetchStages({idsByChampId, byId, idsByStageId}))
    } else {
      dispatch(finishFetchStages())

      return data
    }
  }
}

export function startFetchStage(stageId: number) {
  return {type: START_FETCH_STAGE, payload: {stageId}}
}

export const setActiveStage = (stageId: number) => {
  return {type: SET_ACTIVE_STAGE, payload: stageId}
}

export function finishFetchSaga(stageId: number, data?: Stage) {
  return {type: FINISH_FETCH_STAGE, payload: {stageId, data}}
}

export function fetchStage(stageId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchStage(stageId))

    const {data, status} = await api.get(`/admin/stages/${stageId}/`)

    if (status === 200) {
      dispatch(finishFetchSaga(stageId, data))
    } else {
      dispatch(finishFetchSaga(stageId))

      return data
    }
  }
}

export const deleteStage = (id: number) => {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/stages/${id}/`)

    if (status === 204) {
      dispatch(fetchStages(lastFetchChampionshipParams))
    } else {
      return data
    }
  }
}

export const editStage = (stageId: number, values: Object) => {
  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/stages/${stageId}/`, values)

    if (status === 200) {
      dispatch(fetchStages(lastFetchChampionshipParams))
    } else {
      return data
    }
  }
}

export const createStage = (values: Object) => {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/stages/', values)

    if (status === 201) {
      dispatch(fetchStages())
    } else {
      return data
    }
  }
}

export const downloadChampionshipStageReport = (championshipId: number, stageId: number) => {
  api({
    url: `/admin/championship_stage_users_report/${championshipId}/${stageId}`,
    method: 'GET',
  }).then(({data}) => {
    // eslint-disable-next-line no-undef
    const downloadUrl = window.URL.createObjectURL(new Blob([[data]], {contentType: 'text/csv'}))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `championship_${championshipId}_stage_${stageId}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  })
}
