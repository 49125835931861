// @flow

import api from 'api'

import type {Predict, PredictsByKoefType} from 'store/lib/events'

import {collection} from './index'
import {
  START_FETCH_PREDICT,
  FINISH_FETCH_PREDICT,
  TOGGLE_PREDICT,
  REMOVE_PREDICT,
  PUT_MOCK_PREDICT,
  REMOVE_PREDICTS,
  SET_PREDICTS,
  PLACE_PREDICT_ERROR,
  START_FETCH_TOGGLE_PREDICTS,
  FINISH_FETCH_TOGGLE_PREDICTS,
} from './constants'
import {adaptPredict, dateTimeUtcToLocal} from './utils'

function startFetchPredict(predictId: number) {
  return {type: START_FETCH_PREDICT, payload: {predictId}}
}

function finishFetchPredict(predictId: number, data?: Predict) {
  return {type: FINISH_FETCH_PREDICT, payload: {predictId, data}}
}

export function fetchPredict(predictId: number) {
  return async (dispatch: Function) => {
    dispatch(startFetchPredict(predictId))

    const {data, status} = await api.get(`/admin/predicts/${predictId}/`)
    if (status === 200) {
      dispatch(finishFetchPredict(predictId, {...data, date: dateTimeUtcToLocal(data.predictDate, data.predictTime)}))
    } else {
      dispatch(finishFetchPredict(predictId))

      return data
    }
  }
}

let lastFetchPredictsParams = void 0
export const fetchPredicts = (params?: {match?: number, user?: number}) => {
  lastFetchPredictsParams = params

  return collection.actions.load(params)
}

export function startFetchTogglePredicts(params: {match: number, user: number}) {
  return {type: START_FETCH_TOGGLE_PREDICTS, payload: {params}}
}

export function finishFetchTogglePredicts(params: {match: number, user: number}, data?: PredictsByKoefType) {
  return {type: FINISH_FETCH_TOGGLE_PREDICTS, payload: {params, data}}
}

export function fetchTogglePredicts(params: {match: number, user: number}) {
  return async (dispatch: Function) => {
    dispatch(startFetchTogglePredicts(params))

    const {data, status} = await api.get(`/admin/predicts/`, {params: {...params, limit: 9999}})

    if (status === 200) {
      const adaptedPredicts = data.results.map(adaptPredict)

      dispatch(
        finishFetchTogglePredicts(
          params,
          adaptedPredicts.reduce((acc, predict: Predict) => ({...acc, [predict.koefsIdType]: predict}), {}),
        ),
      )
    } else {
      dispatch(finishFetchTogglePredicts(params))

      return data
    }
  }
}

export function editPredict(predictId: number, {date: _, ...values}: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.put(`/admin/predicts/${predictId}/`, values)

    if (status === 200) {
      dispatch(fetchPredicts(lastFetchPredictsParams))
    } else {
      return data
    }
  }
}

export function createPredict(values: Object) {
  return async (dispatch: Function) => {
    const {data, status} = await api.post('/admin/predicts/', values)

    if (status === 201) {
      dispatch(fetchPredicts(lastFetchPredictsParams))
    } else {
      return data
    }
  }
}

export function deletePredict(predictId: number) {
  return async (dispatch: Function) => {
    const {data, status} = await api.delete(`/admin/predicts/${predictId}/`)

    if (status === 204) {
      dispatch(fetchPredicts(lastFetchPredictsParams))
    } else {
      return data
    }
  }
}

export const togglePredict = (koefId: number, userId: number) => ({type: TOGGLE_PREDICT, payload: {koefId, userId}})

export const removePredict = (koefType: number) => ({type: REMOVE_PREDICT, payload: koefType})

export const removePredicts = (koefTypes: Array<number>) => ({type: REMOVE_PREDICTS, payload: koefTypes})

export const putMockPredict = (koefType: number, koefId: number) => ({
  type: PUT_MOCK_PREDICT,
  payload: {koefType, koefId},
})

export const setPredicts = (predicts: PredictsByKoefType) => ({type: SET_PREDICTS, payload: predicts})

export const placePredictError = (koefId: number, message?: string) => ({
  type: PLACE_PREDICT_ERROR,
  payload: {koefId, message},
})
