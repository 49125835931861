// @flow

import type {TeamRequestsData} from './types'
import {
  SET_TEAM_REQUESTS,
  START_LOADING_TEAM_REQUESTS,
  FINISH_LOADING_TEAM_REQUESTS,
  LOAD_TEAM_REQUESTS,
  MESSAGES_READ,
} from './constants'

export const loadTeamRequests = () => ({type: LOAD_TEAM_REQUESTS})

export const setTeamRequests = (payload: TeamRequestsData) => ({type: SET_TEAM_REQUESTS, payload})

export const startLoadingTeamRequests = () => ({type: START_LOADING_TEAM_REQUESTS})

export const finishLoadingTeamRequests = () => ({type: FINISH_LOADING_TEAM_REQUESTS})

export const messagesRead = () => ({type: MESSAGES_READ})
