// @flow

import api from 'api'

import {START_FETCH_NOTIFICATION, FINISH_FETCH_NOTIFICATION} from './constants'
import type {Notification} from './types'

const LS_NOTIFY_ID_KEY = 'core/notification'

export function startFetchNotification() {
  return {
    type: START_FETCH_NOTIFICATION,
  }
}

export function finishFetchNotification(payload?: Notification) {
  return {
    type: FINISH_FETCH_NOTIFICATION,
    payload,
  }
}

export function fetchNotification(params: {champId: number, markAsRead?: boolean}) {
  return async (dispatch: Function) => {
    dispatch(startFetchNotification())
    const {champId, markAsRead} = params
    const {data} = await api.get(`/championship/${champId}/notification/`)

    if (markAsRead) {
      localStorage.setItem(LS_NOTIFY_ID_KEY, JSON.stringify(data.map(item => item.id)))
    }

    dispatch(finishFetchNotification(data))
  }
}
