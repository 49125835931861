// @flow

import {combineReducers} from 'redux'
import isUndefined from 'lodash/isUndefined'

import {
  PUT_ERROR,
  DEL_ERROR,
  SET_CHAMP_ID,
  RESET_ERRORS,
  DEL_ERRORS,
  SET_VIEWPORT,
  TOGGLE_CHAT,
  SET_FILTER,
  CLEAR_FILTER,
} from './constants'
import {withUIErrors} from './hoc'
import type {State} from './types'

export {withUIErrors}

const initialState: State = {
  champ: {
    id: null,
  },
  errors: {},
  viewport: {
    sm: false,
    lg: false,
  },
  chat: {
    opened: false,
  },
  filter: {
    default: '',
  },
}

const champ = (state = initialState.champ, {payload, type}) => {
  switch (type) {
    case SET_CHAMP_ID:
      return {...state, id: payload}

    default:
      return state
  }
}

const errors = (state = initialState.errors, {payload, type}) => {
  switch (type) {
    case PUT_ERROR:
      return {...state, [payload.code]: {code: payload.code, msg: payload.msg}}

    case DEL_ERROR: {
      const {[payload]: omit, ...next} = state

      return next
    }

    case DEL_ERRORS: {
      return payload.reduce((s, code) => {
        const {[code]: omit, ...next} = s

        return next
      }, state)
    }

    case RESET_ERRORS:
      return initialState.errors

    default:
      return state
  }
}

const viewport = (state = initialState.viewport, {payload, type}) => {
  switch (type) {
    case SET_VIEWPORT: {
      return {...payload}
    }

    default:
      return state
  }
}

const chat = (state = initialState.chat, {payload, type}) => {
  switch (type) {
    case TOGGLE_CHAT:
      return {...state, opened: isUndefined(payload.opened) ? !state.opened : payload.opened}

    default:
      return state
  }
}

const filter = (state = initialState.filter, {payload, type}) => {
  switch (type) {
    case SET_FILTER: {
      const {key = 'default', filter} = payload
      return {...state, [key]: filter}
    }

    case CLEAR_FILTER: {
      const {key = 'default'} = payload
      return {...state, [key]: ''}
    }

    default:
      return state
  }
}

export default combineReducers({champ, errors, viewport, chat, filter})
