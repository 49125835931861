// @flow

import {LOAD_RULES, SET_RULES, START_LOADING_RULES, FINISH_LOADING_RULES} from './constants'

export const loadRules = () => ({type: LOAD_RULES})

export const startLoadingRules = () => ({type: START_LOADING_RULES})

export const finishLoadingRules = () => ({type: FINISH_LOADING_RULES})

export const setRules = (payload: Object | null) => ({type: SET_RULES, payload})
