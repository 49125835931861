import {combineReducers} from 'redux'

import profile from './profile'
import events from './events'
import rating from './rating'
import rewards from './rewards'
import incoming from './incoming'
import teams from './teams'
import rules from './rules'
import notification from './notification'
import chat from './chat'

export default combineReducers({profile, events, rating, rewards, incoming, teams, rules, notification, chat})
