// @flow

import type {Predict, PredictsByKoefType, MockPredict} from 'store/lib/events'

import {collection} from './index'
import type {State, SingleState, ToggleState} from './types'

const local = (state: any): State => state.admin.predicts

const getSingle = (state: any): SingleState => local(state).single

const getToggle = (state: any): ToggleState => local(state).toggle

export const getTogglePredicts = (state: any): PredictsByKoefType => getToggle(state).data

export const getPredictsList = collection.selectors.getItems

export const isPredictsListLoading = collection.selectors.isLoading

export const getPredictsPagesCount = collection.selectors.getPagesCount

export const getSinglePredict = (state: any): Predict | null => getSingle(state).data

export const isSinglePredictLoading = (state: any): boolean => getSingle(state).loading

export const getPredictByKoefType = (state: any, {koefType}: {koefType: number}): ?Predict | ?MockPredict =>
  getTogglePredicts(state)[koefType]
