export const START_FETCH_USERS_RATING_REPORT = 'battle-py-frontend/reports/START_FETCH_USERS_RATING_REPORT'
export const FINISH_FETCH_USERS_RATING_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_USERS_RATING_REPORT'
export const START_FETCH_GROUPS_REPORT = 'battle-py-frontend/reports/START_FETCH_GROUPS_REPORT'
export const FINISH_FETCH_GROUPS_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_GROUPS_REPORT'
export const START_FETCH_STARS_REPORT = 'battle-py-frontend/reports/START_FETCH_STARS_REPORT'
export const FINISH_FETCH_STARS_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_STARS_REPORT'
export const START_FETCH_MATCHES_GROUPS_REPORT = 'battle-py-frontend/reports/START_FETCH_MATCHES_GROUPS_REPORT'
export const FINISH_FETCH_MATCHES_GROUPS_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_MATCHES_GROUPS_REPORT'
export const START_FETCH_MATCH_TEAMS_REPORT = 'battle-py-frontend/reports/START_FETCH_MATCH_TEAMS_REPORT'
export const FINISH_FETCH_MATCH_TEAMS_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_MATCH_TEAMS_REPORT'
export const START_FETCH_TOP250_WINNERS_REPORT = 'battle-py-frontend/reports/START_FETCH_TOP250_WINNERS_REPORT'
export const FINISH_FETCH_TOP250_WINNERS_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_TOP250_WINNERS_REPORT'
export const START_FETCH_TEAM_CONTRIBUTION_REPORT = 'battle-py-frontend/reports/START_FETCH_TEAM_CONTRIBUTION_REPORT'
export const FINISH_FETCH_TEAM_CONTRIBUTION_REPORT = 'battle-py-frontend/reports/FINISH_FETCH_TEAM_CONTRIBUTION_REPORT'
