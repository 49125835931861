export const local = state => state.router

export const getLocation = state => local(state).location

export const getPrevLocation = state => local(state).prevLocation

export const isRouteDeep = state => getLocation(state).pathname.split('/').length > 2

export const isLoading = state => !!local(state).loadings

export const getScroll = (state, {pathname}) => local(state).scroll[pathname]
