// @flow

import {createSelector} from 'reselect'

import type {State, CollectionState, Playoff} from './types'

const local = (state: any): State => state.admin.playoff

const getList = (state: any): CollectionState => local(state).collection

export const getPlayoffList = (state: any): Array<Playoff> => getList(state).data

export const getPlayoffById = createSelector(
  getPlayoffList,
  (playoffs: Array<Playoff>) => playoffs.reduce((acc, playoff) => ({...acc, [playoff.id]: playoff}), {}),
)

export const getPlayoff = (state: any, {playoffId}: {playoffId: number}): ?Playoff => getPlayoffById(state)[playoffId]
