// @flow

import {createSelector} from 'reselect'
import get from 'lodash/get'
import values from 'lodash/values'

import type {
  State,
  AuthState,
  InfoState,
  InfoData,
  UserInfo,
  GetChampionship,
  ScoresData,
  ScoresState,
  Group,
  GetChampionships,
} from './types'

const local = (state: any): State => state.core.profile

const getAuth = (state: any): AuthState => local(state).auth

const getInfo = (state: any): InfoState => local(state).info

const getScores = (state: any): ScoresState => local(state).scores

export const getInfoData = (state: any): InfoData | null => getInfo(state).data

export const getScoresData = (state: any): ScoresData | null => getScores(state).data

export const getUser = (state: any) => getAuth(state).user

export const getAuthToken = (state: any): ?string => get(getUser(state), 'authToken', null)

export const getAuthError = (state: any) => getAuth(state).error

export const getMyId = (state: any) => {
  const info = getInfoData(state)

  return info && info.id
}

export const getUserInfoByChamp = createSelector(
  getInfoData,
  (_, {champId}) => champId,
  (info: InfoData, champId: number): UserInfo | null => {
    if (!info) return null

    const champ = get(info, `getChampionships.${champId}`)
    const group = get(champ, 'group.0')
    const point = get(champ, 'userPoint')
    const rating = get(champ, 'rating')
    const usersCount = get(champ, 'usersCount')
    const userRequest = get(champ, 'userRequest')
    const championshipStart = get(champ, 'championshipStart')

    return {
      id: info.id,
      point: point || 0,
      rating: rating,
      usersCount: usersCount,
      group,
      name: info.fullName,
      userPicture: info.userPicture || '',
      userRequest: userRequest,
      championshipStart: championshipStart,
      userPhoto: info.userPhoto || '',
      isDisabled: info.isDisabled,
    }
  },
)

export const getUserGroupIdByChamp = (state: any, params: {champId: number}): number | null =>
  get(getUserGroupByChamp(state, params), 'id', null)

export const getUserGroupByChamp = (state: any, {champId}: {champId: number}): ?Group => {
  const info: UserInfo | null = getUserInfoByChamp(state, {champId})

  return info && info.group
}

export const getChampionships = (state: any): GetChampionships | null => {
  const data = getInfoData(state)

  return data && data.getChampionships
}

export const getChampionshipsList = createSelector(
  getChampionships,
  (champs: GetChampionships): Array<GetChampionship> => values(champs),
)

export const getChampionship = (state: any, {champId}: {champId: number}): GetChampionship | null =>
  get(getInfoData(state), `getChampionships.${champId}`, null)

export const isInfoLoading = (state: any) => getInfo(state).loading

export const isScoresLoading = (state: any) => getScores(state).loading
