// @flow

import {fork} from 'redux-saga/effects'
import {collection, teamUsers} from './index'

type Inject = {
  handlerEnhancer: Function,
}

export default ({handlerEnhancer}: Inject) => {
  return function*(): Generator<*, *, *> {
    yield fork(
      collection.saga({
        handlerEnhancer,
        makeReq: ({groupId, status, championship, lost, user}) => ({
          url: '/admin/teams/',
          params: {status, championship, lost, users: user, group_teams: groupId},
        }),
      }),
    )

    yield fork(
      teamUsers.saga({
        handlerEnhancer,
        makeReq: ({groupId, status, teamId, lost, user}) => ({
          url: `/admin/table/info/teams/${teamId}/`,
          params: {status, lost, users: user, group_teams: groupId},
        }),
      }),
    )
  }
}
