// @flow

import React from 'react'

import cm from 'cm'

type Props = {
  lg: Function,
  sm: Function,
}

type State = {
  current: 'lg' | 'sm' | null,
}

class Viewport extends React.Component<Props, State> {
  lgMatch = null

  state = {
    current: null,
  }

  componentDidMount() {
    this.lgMatch = window.matchMedia(cm.viewportLg)
    this.lgMatch.addListener(this.matchLg)

    // $FlowFixMe
    this.matchLg(this.lgMatch)
  }

  componentWillUnmount() {
    this.lgMatch.removeListener(this.matchLg)
  }

  render() {
    const {current} = this.state

    return current && React.createElement(this.props[current])
  }

  matchLg = ({matches}: MediaQueryListEvent) => this.setState({current: matches ? 'lg' : 'sm'})
}

export default Viewport
