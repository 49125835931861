// @flow

import {takeEvery, call, put} from 'redux-saga/effects'

import {UPDATE_SETTINGS} from './constants'
import {updateSettings} from './actions'

const LS_KEY = 'settings'

function* saveInLS(key, data) {
  yield call([localStorage, localStorage.setItem], key, JSON.stringify(data))
}

function* getFromLS(key) {
  const str = yield call([localStorage, localStorage.getItem], key)

  return str && JSON.parse(str)
}

function* restoreSettings() {
  const settings = yield call(getFromLS, LS_KEY)

  if (settings) {
    yield put(updateSettings(settings))
  }
}

function* updateSettingsHandler({payload}) {
  yield call(saveInLS, LS_KEY, payload)
}

export default function*(): Generator<*, *, *> {
  yield takeEvery(UPDATE_SETTINGS, updateSettingsHandler)

  yield call(restoreSettings)
}
