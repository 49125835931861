// @flow

import {combineReducers} from 'redux'

import type {State} from './types'
import {
  START_TOURNAMENT_LOADING,
  FINISH_TOURNAMENT_LOADING,
  SET_TOURNAMENT_DATA,
  START_TEAM_LOADING,
  FINISH_TEAM_LOADING,
  SET_TEAM_DETAILS,
  START_TEAMS_LOADING,
  FINISH_TEAMS_LOADING,
  SET_TEAMS_DATA,
  START_TEAM_STAGES_LOADING,
  FINISH_TEAM_STAGES_LOADING,
  SET_TEAM_STAGES,
  START_LOADING_USERS,
  FINISH_LOADING_USERS,
  ADD_USERS,
  SET_USERS_COUNT,
  RESET_USERS,
  START_LOADING_STAGE_MATCHES,
  FINISH_LOADING_STAGE_MATCHES,
  SET_STAGE_MATCHES_COUNT,
  ADD_STAGE_MATCHES,
  RESET_STAGE_MATCHES,
  FINISH_LOADING_USER,
  SET_USER,
  START_LOADING_USER,
} from './constants'

const initialState: State = {
  tournament: {
    data: {
      champGroups: [],
    },
    loading: false,
  },
  teamDetails: {
    data: null,
    loading: false,
  },
  teams: {
    data: {
      ids: [],
      byId: {},
    },
    loading: false,
  },
  teamStages: {
    data: {
      ids: [],
      byId: {},
      meta: {},
    },
    loading: false,
  },
  users: {
    data: {
      pages: [],
      paths: {},
    },
    count: 0,
    loading: false,
  },
  stageMatches: {
    data: [],
    count: 0,
    loading: false,
  },
  user: {
    data: null,
    loading: false,
  },
}

const tournament = (state = initialState.tournament, {payload, type}: any) => {
  switch (type) {
    case START_TOURNAMENT_LOADING:
      return {...state, loading: true}

    case FINISH_TOURNAMENT_LOADING:
      return {...state, loading: false}

    case SET_TOURNAMENT_DATA:
      return {...state, data: payload}

    default:
      return state
  }
}

const teamDetails = (state = initialState.teamDetails, {payload, type}: any) => {
  switch (type) {
    case START_TEAM_LOADING:
      return {...state, loading: true}

    case FINISH_TEAM_LOADING:
      return {...state, loading: false}

    case SET_TEAM_DETAILS:
      return {...state, data: payload}

    default:
      return state
  }
}

const teams = (state = initialState.teams, {payload, type}: any) => {
  switch (type) {
    case START_TEAMS_LOADING:
      return {...state, loading: true}

    case FINISH_TEAMS_LOADING:
      return {...state, loading: false}

    case SET_TEAMS_DATA:
      return {...state, data: payload}

    default:
      return state
  }
}

const teamStages = (state = initialState.teamStages, {payload, type}: any) => {
  switch (type) {
    case START_TEAM_STAGES_LOADING:
      return {...state, loading: true}

    case FINISH_TEAM_STAGES_LOADING:
      return {...state, loading: false}

    case SET_TEAM_STAGES:
      return {...state, data: payload}

    default:
      return state
  }
}

const users = (state = initialState.users, {payload, type}) => {
  switch (type) {
    case START_LOADING_USERS:
      return {...state, loading: true}

    case FINISH_LOADING_USERS:
      return {...state, loading: false}

    case ADD_USERS: {
      const {pages, paths} = state.data

      return {
        ...state,
        data: {
          pages: [...pages, payload],
          paths: {...paths, ...payload.reduce((acc, {id}, i) => ({...acc, [id]: `${pages.length}.${i}`}), {})},
        },
      }
    }

    case SET_USERS_COUNT:
      return {...state, count: payload}

    case RESET_USERS:
      return initialState.users

    default:
      return state
  }
}

const stageMatches = (state = initialState.stageMatches, {payload, type}) => {
  switch (type) {
    case RESET_STAGE_MATCHES:
      return initialState.stageMatches

    case START_LOADING_STAGE_MATCHES:
      return {...state, loading: true}

    case FINISH_LOADING_STAGE_MATCHES:
      return {...state, loading: false}

    case SET_STAGE_MATCHES_COUNT:
      return {...state, count: payload}

    case ADD_STAGE_MATCHES:
      return {...state, data: [...state.data, payload]}

    default:
      return state
  }
}

const user = (state = initialState.user, {payload, type}) => {
  switch (type) {
    case START_LOADING_USER: {
      return {...state, loading: true}
    }

    case FINISH_LOADING_USER: {
      return {...state, loading: false}
    }

    case SET_USER: {
      return {...state, data: payload}
    }

    default:
      return state
  }
}

export default combineReducers({tournament, teamDetails, teams, teamStages, users, stageMatches, user})
