// @flow

import {createSelector} from 'reselect'
import mapValues from 'lodash/mapValues'
import keys from 'lodash/keys'
import get from 'lodash/get'

import type {State, CollectionState, CollectionData, Stage, SingleState} from './types'

const local = (state: any): State => state.admin.stages

const getCollection = (state: any): CollectionState => local(state).collection

const getCollectionData = (state: any): CollectionData => getCollection(state).data

const getStagesListsByChamp = createSelector(
  getCollectionData,
  (data: CollectionData): {[champId: number]: Array<Stage>} =>
    mapValues(data.idsByChampId, ids => ids.map(id => data.byId[id])),
)

const getStagesListsByStage = createSelector(
  getCollectionData,
  (data: CollectionData): {[champId: number]: Array<Stage>} =>
    mapValues(data.idsByStageId, ids => ids.map(id => data.byId[id])),
)

const getSingle = (state: any): SingleState => local(state).single

export const isStagesLoading = (state: any): boolean => getCollection(state).loading

export const getStagesList = createSelector(
  getStagesListsByChamp,
  byChamp => keys(byChamp).reduce((acc, champId) => [...acc, ...byChamp[champId]], []),
)

const getRawStagesList = createSelector(
  getStagesListsByStage,
  byStage => keys(byStage).reduce((acc, stageId) => [...acc, ...byStage[stageId]], []),
)

export const getSortedStagesList = (state: any) => getRawStagesList(state).reverse()

export const getStagesListForChamp = (state: any, {champId}: {champId: number}) =>
  get(getStagesListsByChamp(state), champId, [])

export const getStage = (state: any, {stageId}: {stageId: number}) => getCollectionData(state).byId[stageId]

export const getActiveStage = (state: any) => get(local(state), 'activeStage', null)

export const getSingleStage = (state: any): Stage | null => getSingle(state).data

export const isSingleStageLoading = (state: any): boolean => getSingle(state).loading
