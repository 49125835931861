// @flow

import {take, takeEvery, takeLatest, select, put, call, fork} from 'redux-saga/effects'
import {eventChannel, buffers} from 'redux-saga'

import {FINISH_LOAD_INFO, SET_INFO} from 'store/core/profile/constants'
import {getChampionshipsList, getChampionships, getUserGroupIdByChamp} from 'store/core/profile/selectors'
import {connect, disconnect} from 'store/core/chat/actions'
import cm from 'cm'

import {setChampId, setViewport} from './actions'
import {SET_CHAMP_ID} from './constants'
import {getChampId} from './selectors'
import {userLogout} from '../core/profile/actions'

const LS_CHAMP_ID_KEY = 'ui/champId'

const createViewportEventChannel = () =>
  eventChannel(emit => {
    const checkMatch = ({matches}) => emit({lg: matches, sm: !matches})

    const match = window.matchMedia(cm.viewportLg)
    match.addListener(checkMatch)

    checkMatch(match)

    return () => match.removeListener(checkMatch)
  }, buffers.expanding())

function* setFirstChampId() {
  const [firstChamp] = yield select(getChampionshipsList)

  if (firstChamp) {
    yield put(setChampId(firstChamp.id))
  }
}

function* storeChampIdHandler({payload: id}) {
  yield call([localStorage, localStorage.setItem], LS_CHAMP_ID_KEY, String(id))
}

function* restoreChampId() {
  const str = yield call([localStorage, localStorage.getItem], LS_CHAMP_ID_KEY)
  const id = str && Number(str)

  if (typeof id !== 'number') return yield call(setFirstChampId)

  const champs = yield select(getChampionships)
  if (champs[id]) {
    yield put(setChampId(id))
  } else {
    yield call(setFirstChampId)
  }
}

function* viewportHandler(viewport) {
  yield put(setViewport(viewport))
}

function* chatConnectionSaga() {
  let lastGroupId = null

  yield takeLatest([SET_CHAMP_ID, SET_INFO], function* checkChatConnection() {
    const champId = yield select(getChampId)
    const groupId: ?number = yield select(state => getUserGroupIdByChamp(state, {champId}))

    if (lastGroupId === groupId) return

    if (groupId) {
      yield put(connect(groupId))

      lastGroupId = groupId
    } else {
      yield put(disconnect())

      lastGroupId = null
    }
  })
}

function* setUserInfoHandler({payload: info}) {
  if (info.isDisabled) yield put(userLogout())
}

export default function*(): Generator<*, *, *> {
  yield takeEvery(createViewportEventChannel(), viewportHandler)
  yield takeEvery(SET_CHAMP_ID, storeChampIdHandler)
  yield takeLatest(SET_INFO, setUserInfoHandler)

  yield fork(chatConnectionSaga)

  yield take(FINISH_LOAD_INFO)
  yield call(restoreChampId)
}
