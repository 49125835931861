export const SET_CHAMP_ID = 'battle-py-frontend/ui/SET_CHAMP_ID'
export const PUT_ERROR = 'battle-py-frontend/ui/PUT_ERROR'
export const DEL_ERROR = 'battle-py-frontend/ui/DEL_ERROR'
export const DEL_ERRORS = 'battle-py-frontend/ui/DEL_ERRORS'
export const RESET_ERRORS = 'battle-py-frontend/ui/RESET_ERRORS'
export const SET_VIEWPORT = 'battle-py-frontend/ui/SET_VIEWPORT'
export const SET_FILTER = 'battle-py-frontend/ui/SET_FILTER'
export const CLEAR_FILTER = 'battle-py-frontend/ui/CLEAR_FILTER'

export const TOGGLE_CHAT = 'battle-py-frontend/ui/TOGGLE_CHAT'
